import React from 'react'
import styled from 'styled-components'

type HeaderProps = {
  title: string,
  description?: string,
}

const PersonalInfoTermHeader = ({ title, description }: HeaderProps) => {
  return (
    <TitleWrapper>
      <ItemTitleText>{title}</ItemTitleText>
      {
        description && <div className='description'>{description}</div>
      }
    </TitleWrapper>
  )
}

const ItemTitleText = styled.div`
  font-weight: 700;
  font-size: 1.25rem;
  color: var(--gray900);
  letter-spacing: -0.0125rem;
`

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  
  & > .description {
    flex-grow: 1;
    font-size: 1rem;
    text-align: start;
    color: var(--gray700);
  }

  @media only screen and (max-width: 1023px) {
    flex-wrap: wrap;
  }
`

export default PersonalInfoTermHeader

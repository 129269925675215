import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit'
import { hideDialog, showDialog } from '../slices/dialogSlice'
import { logout } from '../slices/authSlice'
import { logoutAdmin } from '../slices/adminAccountSlice'

export const ResultStatus = {
  'OK': 200,
  'Created': 201,
  'BadRequest': 400,
  'Unauthorized': 401,
  'Forbidden': 403,
  'NotFound': 404,
  'NotAcceptable': 406,
  'InternalServerError': 500,
} as const
export type ResultStatus = typeof ResultStatus[keyof typeof ResultStatus]


export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error
}

export const globalErrorHandler: Middleware = ({ dispatch }) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (action.payload.status === ResultStatus.Forbidden) {
      const tokenExpirationInSeconds = sessionStorage.getItem("session_auth") ? JSON.parse(sessionStorage.getItem("session_auth")!).time : 0
      const tokenExpirationInMillis = tokenExpirationInSeconds * 1000;
      const currentMillis = new Date().getTime();
      dispatch(
        showDialog({
          title: tokenExpirationInMillis === 0 ? '로그 아웃' : tokenExpirationInMillis - currentMillis < 0 ? '세션 만료' : '로그 아웃',
          titleHighlight: tokenExpirationInMillis === 0 ? false : tokenExpirationInMillis - currentMillis < 0,
          body: '초기 화면으로 돌아갑니다.',
          confirm: {
            text: 'confirm',
            onClick: () => {
              location.replace('/')
              dispatch(logout())
              dispatch(logoutAdmin())
              dispatch(hideDialog())
            }
          },
          bgClick: false
        })
      )
      return
    }
    if (!Object.keys(action.payload).includes('data') || !Object.keys(action.payload.data).includes('error')) {
      window.location.href = `/error/${action.payload.status}`
      return
    }
  }
  return next(action)
}

import React, { useEffect } from 'react'
import { usePostMemberMutation } from '../../../../controllers/services/api/member'
import { SubmitHandler, useForm } from 'react-hook-form'
import { formOptions } from '../../../../utils/utils'
import AccountAddDialog, {
  Empty,
  Input,
  InvalidText
} from '../../../components/AccountAddDialog'

type AddAdminDialogProps = {
  open: boolean,
  onClose: () => void,
}

interface FormInput {
  id: string;
  email: string;
  pw: string;
}

const AddAdminDialog = ({ open, onClose }: AddAdminDialogProps) => {
  const { register, handleSubmit, formState: { errors } } = useForm<FormInput>()
  const [signUp, { isSuccess, isError, isLoading }] = usePostMemberMutation()

  useEffect(() => {
    if (isSuccess) {
      onClose()
    }

    if (isError) {
      alert('admin_auth_add_failed'.localized())
    }
  }, [isSuccess, isError, isLoading])

  const onClickDone: SubmitHandler<FormInput> = (fieldData) => {
    signUp({
      email: fieldData.email,
      password: fieldData.pw,
      role: 'ADMIN',
      username: fieldData.id,
    })
  }

  return (
    <AccountAddDialog
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit(onClickDone)}
      title={'admin_auth_add_title'.localized()}
    >
      <>
        <Input
          type='text'
          placeholder={'input_id'.localized()}
          {...register('id', formOptions({ required: true }))}
        />
        {errors.id ? <InvalidText>{errors.id.message}</InvalidText> : <Empty/>}
        <Input
          type='email'
          placeholder={'input_email'.localized()}
          {...register('email', formOptions({ required: true }))}
        />
        {errors.email ? <InvalidText>{errors.email.message}</InvalidText> : <Empty/>}
        <Input
          type='password'
          placeholder={'input_password'.localized()}
          {...register('pw', formOptions({ required: true }))}
        />
      </>
    </AccountAddDialog>
  )
}

export default AddAdminDialog

import React from 'react'
import styled from 'styled-components'

type BoxTitleProps = {
  hasSiteNameLabel: boolean,
  title: null | string,
  children?: React.ReactNode
}

const ContentsBoxTitle = ({hasSiteNameLabel, title, children}: BoxTitleProps) => {
  return (
    <BoxTitleWrapper>
      { hasSiteNameLabel && <SiteName>{'site_name_tag'.localized()}</SiteName> }
      <BoxTitle>{title}</BoxTitle>
      { children && (
        <TitleButtonWrapper>
          { children }
        </TitleButtonWrapper>)
      }
    </BoxTitleWrapper>
  )
}

const BoxTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: start;
    gap: 0.5rem;
  }
`

const SiteName = styled.div`
  padding: 0.563rem 1.5rem;
  margin-right: 1rem;
  border: 1px solid var(--gray300);
  border-radius: 50px;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 100%;
  letter-spacing: -0.01em;

  color: var(--black);
`

const BoxTitle = styled.div`
  flex-grow: 1;
  text-align: left;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 100%;
  letter-spacing: -0.01em;
`

const TitleButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`

ContentsBoxTitle.defaultProps = {
  hasSiteNameLabel: false,
}

export default ContentsBoxTitle

import React, { useEffect, useRef } from 'react'

const useDidMountEffect = (callback: React.EffectCallback, deps: React.DependencyList) => {
  const isMountingRef = useRef(false);

  useEffect(() => {
    isMountingRef.current = true;
  }, []);

  useEffect(() => {
    if (!isMountingRef.current) {
      return callback();
    } else {
      isMountingRef.current = false;
    }
  }, deps);
}

export default useDidMountEffect

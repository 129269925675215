import React, { useEffect, useState } from 'react'
import { BackgroundContainer } from '../../../components/BackgroundContainer'
import { ContentsWrapper } from '../../../components/ContentsWrapper'
import BodyTitleContainer from '../../../components/BodyTitleContainer'
import { ContentsBox } from '../../../components/ContentsBox'
import { Divider } from '../../../components/Divider'
import ContainerInfoBox from '../../../components/ContainerInfoBox'
import ContentsBoxTitle from '../../../components/ContentsBoxTitle'
import BoxContentsWrapper from '../../../components/BoxContentsWrapper'
import { useGetManagersQuery } from '../../../../controllers/services/api/member'
import styled from 'styled-components'
import ActionButton from '../../../components/ActionButton'
import { useAppDispatch } from '../../../../store/hooks'
import { hideDialog, showDialog } from '../../../../controllers/slices/dialogSlice'
import SiteSelector from '../../../components/SiteSelector'
import { useActiveAccountMutation } from '../../../../controllers/services/api/auth'
import { hideLoading, showLoading } from '../../../../controllers/slices/loadingSlice'
import AddManagerDialog from './AddManagerDialog'

const AccountContainer = () => {
  console.log('AccountContainer')
  const { data, isSuccess } = useGetManagersQuery()
  const [fetchActiveAccount, activeAccountState] = useActiveAccountMutation()
  const dispatch = useAppDispatch()
  const [accounts, setAccounts] = useState<null | User[]>(null)
  const [currentSite, setCurrentSite] = useState<Site>()
  const [dialog, setDialog] = useState(false)

  useEffect(() => {
    activeAccountState.isLoading ? dispatch(showLoading()) : dispatch(hideLoading())

    if (activeAccountState.isSuccess) {
      dispatch(showDialog({
          title: 'notice',
          body: 'admin_account_activated',
          confirm: {
            text: 'confirm',
            onClick: () => dispatch(hideDialog())
          },
        })
      )
    }

    if (activeAccountState.isError) {
      dispatch(showDialog({
          title: 'Error',
          titleHighlight: true,
          body: 'admin_account_retry',
          confirm: {
            text: 'confirm',
            onClick: () => dispatch(hideDialog())
          },
        })
      )
    }
  }, [activeAccountState.isLoading, activeAccountState.isSuccess, activeAccountState.isError])

  useEffect(() => {
    if (isSuccess && currentSite) {
      const filteredAccount = data.filter((account) => currentSite.siteSno === account.siteSno)
      setAccounts(filteredAccount.length > 0 ? filteredAccount : null)
    } else {
      setAccounts(null)
    }
  }, [currentSite])

  const onChangeSite = (currentSite?: Site) => {
    if (currentSite) {
      setCurrentSite(currentSite)
    }
  }

  const handleUnlockButton = (member: User) => {
    fetchActiveAccount({ userId: member.userId })
  }

  const handleOpenDialog = () => {
    if (currentSite) {
      setDialog(true)
    } else {
      dispatch(showDialog({
        title: 'notice',
        body: 'admin_account_select_site',
        confirm: {
          text: 'confirm',
          onClick: () => dispatch(hideDialog()),
        },
      }))
    }
  }

  const handleCloseDialog = () => {
    setDialog(false)
  }

  return (
    <BackgroundContainer>
      <ContentsWrapper>
        <BodyTitleContainer title={'admin_account_management'.localized()}/>
        <BoxWrapper padding='1.25rem 2rem'>
          <SelectorWrapper>
            <SiteSelector currentSite={currentSite} onChangeSite={onChangeSite}/>
          </SelectorWrapper>
          <ActionButton onClick={handleOpenDialog} value={'admin_account_add'.localized()} padding='0.75rem 1.875rem' bgColor='white' disabled={false}/>
        </BoxWrapper>
        { currentSite && (
          accounts?.map((account) => (
            <ContentsBox key={account.userId} padding='1.875rem 2rem'>
              <AccountHeader>
                <ContentsBoxTitle hasSiteNameLabel title={currentSite.name}>
                  <ActionButton onClick={() => handleUnlockButton(account)} value={'admin_account_unlock'.localized()} margin='-0.25rem 0' padding='0.75rem 1.875rem' bgColor='white' disabled={false}/>
                </ContentsBoxTitle>
              </AccountHeader>
              <Divider margin='1.5rem 0'/>
              <BoxContentsWrapper gap='2.063rem'>
                <ContainerInfoBox label={'admin_account_id'.localized()} content={account.username} titleWidth='7.5rem'/>
              </BoxContentsWrapper>
            </ContentsBox>
          )) ?? <ContentsBox padding='1.875rem 2rem'>{'admin_account_empty'.localized()}</ContentsBox>
        )}
      </ContentsWrapper>
      <AddManagerDialog open={dialog} onClose={handleCloseDialog} site={currentSite} />
    </BackgroundContainer>
  )
}

const AccountHeader = styled.div`
  width: 100%;
  display: flex;
`

const SelectorWrapper = styled.div`
  width: 100%;
  max-width: 21rem;
`

const BoxWrapper = styled(ContentsBox)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;

  @media only screen and (max-width: 1023px) {
    flex-direction: column;
  }
`

export default AccountContainer

import React, {useEffect} from 'react'
import { hideDialog, showDialog } from '../../../../controllers/slices/dialogSlice'
import { useAppDispatch } from '../../../../store/hooks'
import { useResetPasswordMutation } from '../../../../controllers/services/api/auth'
import { useParamSiteSno } from '../../../../hooks/useParamSiteSno'
import { useHistory, useParams } from 'react-router-dom'
import { routePath } from '../../../../route'
import { hideLoading, showLoading } from '../../../../controllers/slices/loadingSlice'
import LoginCardWrapper from '../../../components/LoginCardWrapper'
import styled from 'styled-components'
import { formOptions } from '../../../../utils/utils'
import ButtonGroup from '../../../components/ButtonGroup'
import ActionButton from '../../../components/ActionButton'
import { SubmitHandler, useForm } from 'react-hook-form'

interface FormInput {
  email: string
}

type RouteState = {
  user: string,
  siteSno: string,
}

const SearchPassContainer = () => {
  console.log('SearchPassContainer')
  const { siteSno } = useParamSiteSno()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const params = useParams<RouteState>()
  const [ searchPass, getSearchPass ] = useResetPasswordMutation()

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormInput>({
    mode: 'onChange',
  })

  const handleClickSubmit: SubmitHandler<FormInput> = async (fieldData) => {
    searchPass({
      email: fieldData.email, siteSno: siteSno
    })
  }

  const onRouteResetPw = (email: string, siteSno: string) => {
    history.push(routePath.changePassword(encodeURI(email), siteSno), { replace: true, backPath: routePath.register(params.siteSno) })
  }

  const handleClickCancel = () => {
    history.push(routePath.register(params.siteSno))
  }

  useEffect(() => {
    getSearchPass.isLoading ? dispatch(showLoading()) : dispatch(hideLoading())

    if(getSearchPass.isSuccess){
      dispatch(
        showDialog({
          title: 'find_password',
          body: 'send_password',
          confirm: {
            text: 'confirm',
            onClick: () => {
              dispatch(hideDialog())
              onRouteResetPw(getValues('email'), siteSno)
            }
          }
        })
      )
    } else if(getSearchPass.isError){
      dispatch(
        showDialog({
          title: 'find_password',
          body: 'not_exist_email',
          confirm: {
            text: 'confirm',
            onClick: () => dispatch(hideDialog()),
          },
        })
      )
    }
  }, [getSearchPass.isSuccess, getSearchPass.isError, getSearchPass.isLoading])

  return (
    <LoginCardWrapper>
      <Title>{'find_password'.localized()}</Title>
      <Description>{'find_password_detail'.localized()}</Description>
      <InputWrapper>
        <Input
          placeholder={'input_email'.localized()}
          {...register(
            'email',
            formOptions({
              required: true,
              maxLength: 50,
              typePattern: ['alpha', 'numeric', 'specialChar']
            })
          )}
        />
        {errors.email && <InvalidText>{errors.email.message}</InvalidText>}
      </InputWrapper>
      <ButtonGroup
        maxWidth="25rem"
        width="100%"
        padding="1.75rem 0 0"
        justifyContent="center"
      >
        <ActionButton
          onClick={handleClickCancel}
          value={'cancel'.localized()}
          width="100%"
          padding="1rem"
        />
        <ActionButton
          onClick={handleSubmit(handleClickSubmit)}
          value={'find_password'.localized()}
          width="100%"
          padding="1rem"
        />
      </ButtonGroup>
    </LoginCardWrapper>
  )
}

const Title = styled.div`
  font-weight: 700;
  font-size: 1.875rem;
  padding-bottom: 1.25rem;
`

const Description = styled.div`
  font-size: 1rem;
  color: var(--gray700);
  padding-bottom: 3.5rem;
`

const InputWrapper = styled.div`
  max-width: 25rem;
  width: 100%;
  position: relative;
  padding-bottom: 1rem;
`

const Input = styled.input`
  width: 100%;
  height: 3.125rem;
  box-sizing: border-box;
  font-size: 1rem;
  text-overflow: ellipsis;
  color: var(--gray700);

  padding: 0 0.875rem;
  border: 1px solid var(--gray300);
  border-radius: 4px;
  
  &::placeholder {
    color: var(--hint);
  }

  &:focus {
    outline: none;
    border: 1px solid var(--focus-border);
  }
`

const InvalidText = styled.div`
  font-size: 0.75rem;
  position: absolute;
  bottom: 0;
  color: var(--warning);
  white-space: nowrap;
`

export default SearchPassContainer

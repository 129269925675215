import React from 'react'
import styled from 'styled-components'
import TermHeader from './TermHeader'

type TermProps = {
  title: string,
  subTitle?: string,
  termContent: string,
  isChecked: boolean,
  onChangeCheck: () => void
}

const Term = ({ title, subTitle, termContent, isChecked, onChangeCheck}: TermProps) => {
  return (
    <TermWrapper>
      <TermHeader
        title={title}
        description={subTitle}
        isChecked={isChecked}
        onChangeCheck={onChangeCheck}
        checkBoxLabel={'terms_agree'.localized()}
      />
      <DetailTermContainer>{termContent}</DetailTermContainer>
    </TermWrapper>
  )
}

const TermWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 2.25rem;
`

const DetailTermContainer = styled.div`
  height: 13rem;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.08);
  border-radius: 0.5rem;
  text-align: left;
  padding: 1.5rem 1.75rem;
  box-sizing: border-box;
  overflow-y: scroll;
  color: var(--gray700);
  white-space: pre-wrap;
  
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #d9d9d9;
  }
`

export default Term

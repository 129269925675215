import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { AUTH_API, baseQueryReauth } from '../config'
import { getDataForm } from '../../../utils/utils'
import { logout, setCredentials } from '../../slices/authSlice'
import { BaseQueryMeta } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import {
  loginRequest,
  loginResponse,
  logoutRequest,
  logoutResponse,
  resetPasswordRequest,
  resetPasswordResponse
} from './types/auth'
import { messageBody, userIdBody } from './types/common'

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQueryReauth,
  endpoints: (builder) => ({
    login: builder.mutation<loginResponse, loginRequest>({
      query: (request) => ({
        url: AUTH_API.LOGIN,
        method: 'POST',
        body: getDataForm(request),
      }),
      transformResponse: ((response, meta: BaseQueryMeta<any>) => {
        return {
          ...response as {userId: string},
          accessToken: meta?.response?.headers?.get('Authorization')
        }
      }),
      async onQueryStarted(id, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setCredentials(data))
        } catch (e) {
          console.log(e)
        }
      }
    }),
    resetPassword: builder.mutation<resetPasswordResponse, resetPasswordRequest>({
      query: (request) => ({
        url: AUTH_API.FIND_PASSWORD,
        method: 'POST',
        body: getDataForm(request)
      })
    }),
    logout: builder.mutation<logoutResponse, logoutRequest>({
      query: (request) => ({
        url: AUTH_API.LOGOUT,
        method: 'POST',
        body: getDataForm(request),
      }),
      async onQueryStarted(id, { dispatch }) {
        try {
          dispatch(logout())
        } catch (e) {
          console.log(e)
        }
      }
    }),
    check_ip: builder.query<any, void>({
      query: () => AUTH_API.CHECK_IP
    }),
    activeAccount: builder.mutation<messageBody, userIdBody>({
      query: (request) => ({
        url: `${AUTH_API.ACTIVE_ACCOUNT}/${request.userId}`,
        method: 'PUT',
      }),
    })
  }),
})

export const {
  useResetPasswordMutation,
  useLoginMutation,
  useLogoutMutation,
  useCheck_ipQuery,
  useActiveAccountMutation,
} = authApi

import React from 'react'
import styled from 'styled-components'
import { Divider } from './Divider'
import { LineGray } from '../../assets/images'
import { routePath } from '../../route'
import { useHistory } from 'react-router-dom'
import { useParamSiteSno } from '../../hooks/useParamSiteSno'

const MainFooter = () => {
  const history = useHistory()
  const { siteSno } = useParamSiteSno()

    const navigateToUseTerms = () => {
    history.push(routePath.useTerms(siteSno), { replace: true })
  }

  const navigateToPersonalInfo = () => {
    history.push(routePath.personalInfo(siteSno), { replace: true })
  }

  return (
    <div style={{width: '100%', maxWidth: '75rem', margin: '1rem auto 0'}}>
      <Divider margin='0'/>
      <FooterButtonGroup>
        <FooterContainer onClick={navigateToUseTerms}>
          {'terms_object'.localized()}
          <FooterIcon src={LineGray}></FooterIcon>
        </FooterContainer>
        <FooterContainer style={{fontWeight: 'bold' }} onClick={navigateToPersonalInfo}>
          {'main_personal_information'.localized()}
        </FooterContainer>
      </FooterButtonGroup>
    </div>
  )
}

const FooterButtonGroup = styled.div`
  display: flex;
  width: 100%;
  margin-top: 0.5rem;
`

const FooterContainer = styled.div`
  font-size: 0.875rem;
  cursor: pointer;
  display: flex;
  order: 2;
  margin-top: 0.5rem;
  &:hover {
    font-weight: bold;
    text-decoration: underline;
  }
`

const FooterIcon = styled.img`
  width: 1rem;
  height: 0.875rem;
  padding: 0.25rem;
`
export default MainFooter
import React, { useEffect, useRef, useState } from 'react'
import { Storage } from '../libs/StorageService'

const useForm = (type: string | null = null, initValue = { checkBox: false, name: '', id: '', password: '' }) => {
  const [checkBox, setCheckBox] = useState(initValue.checkBox)
  const [inputs, setInputs] = useState({
    id: initValue.id,
    name: initValue.name,
    password: initValue.password,
  })
  const { id, name, password } = inputs
  const rememberKey = type + '-saved-id'
  const currentCheck = useRef(checkBox)
  const currentId = useRef(id)

  useEffect(() => {
    if (type) {
      if (Storage.localStorage(rememberKey)) {
        setValue('id', Storage.localStorage(rememberKey)!)
        onChangeCheckBox()
      }
      return () => {
        if (currentCheck.current) {
          Storage.localStorage(rememberKey, currentId.current)
        } else {
          Storage.localStorage(rememberKey, '')
        }
      }
    }
  }, [])

  useEffect(() => {
    currentCheck.current = checkBox
  }, [checkBox])

  useEffect(() => {
    currentId.current = id
  }, [id])

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target
    setInputs({
      ...inputs,
      [name]: value
    })
  }

  const onChangeCheckBox = () => {
    setCheckBox(!checkBox)
  }

  const setValue = (element: 'checkBox' | 'name' | 'id' | 'password', value: boolean | string) => {
    setInputs({
      ...inputs,
      [element]: value
    })
  }

  return {
    checkBox,
    setCheckBox,
    name,
    id,
    password,
    onChangeCheckBox,
    onChangeInput,
    setValue,
  }
}

export default useForm;

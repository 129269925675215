import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { ContentsWrapper } from '../../components/ContentsWrapper'
import ButtonGroup from '../../components/ButtonGroup'
import ActionButton from '../../components/ActionButton'
import { BackgroundContainer } from '../../components/BackgroundContainer'
import Disclaimer from '../../components/Disclaimer'
import { useGetAllTermsQuery, useGetCurrentTermQuery } from '../../../controllers/services/api/term'
import {hideLoading, showLoading} from "../../../controllers/slices/loadingSlice";
import {useDispatch} from "react-redux";
import ContainerInfoBox from '../../components/ContainerInfoBox'
import MainFooter from '../../components/MainFooter'
import { useParamSiteSno } from '../../../hooks/useParamSiteSno'
import { routePath } from '../../../route'
import { formatDateString } from '../../../utils/utils'

const MainUseTermsContainer = () => {
  console.log('MainUseTermsContainer')
  const history = useHistory()
  const dispatch = useDispatch()
  const { siteSno } = useParamSiteSno()
  const onClick = () => {
    history.push(routePath.main(siteSno), { replace: true })
  }

  const [termsStartDate, setTermsStartDate] = useState<string>('')
  const [termsStartDates, setTermsStartDates] = useState<string[]>([''])
  const [terms, setTerms] = useState<Terms>({
    disclaimer: '',
    effectiveDate: '',
    termsOfConsent: '',
    termsOfManagerConsent: '',
    termsOfManagerPrivacyPolicy: '',
    termsOfPrivacyPolicy: '',
    termsOfUse: '',
    termsId: '',
  })

  const getNotice = useGetCurrentTermQuery()
  const getAllNotice = useGetAllTermsQuery()
  const [termsListNumber, setTermsListNumber] = useState<string[]>([''])

  const onChangeStartDate = (value: string) => {
    setTermsStartDate(value)
  }

  useEffect(() => {
    getNotice.isLoading ? dispatch(showLoading()) : dispatch(hideLoading())

    if (getNotice.isSuccess) {
      if (getNotice.data) {
        setTerms(getNotice.data)
        setTermsStartDate(formatDateString(getNotice.data.effectiveDate))
      }
    }
  }, [getNotice.isLoading, getNotice.isSuccess])

  useEffect(() => {
    if(getAllNotice.isSuccess){
      const effectiveDateList = getAllNotice.data.map((terms) => formatDateString(terms.effectiveDate))
      const termOfUseList = getAllNotice.data.map((terms) => terms.termsOfUse)
      setTermsStartDates(effectiveDateList)
      setTermsListNumber(termOfUseList)
    }
  }, [getAllNotice.isSuccess])



  return (
    <BackgroundContainer headerHeight='0rem'>
      <ContentsWrapper gap='1.5rem' paddingTop='1.875rem'>
        <Disclaimer/>
        <>
          <TitleContainer>
            <TermsTitle>{'terms_object'.localized()}</TermsTitle>
            <ContainerInfoBox
              label={''.localized()}
              type="dropdown"
              dropdown={{
                value: termsStartDate ? termsStartDate === termsStartDates[0] ? 'term_start_date_now'.localized() + termsStartDate :
                  'term_start_date_past'.localized() + termsStartDate : 'term_start_date_now'.localized() + termsStartDates[0],
                options: termsStartDates,
                onClick: onChangeStartDate
              }}
              maxWidth='15rem'
            />
          </TitleContainer>
          <DetailContainer>
            { getAllNotice.isSuccess ? termsListNumber[termsStartDates.indexOf(termsStartDate, 0)] ? termsListNumber[termsStartDates.indexOf(termsStartDate, 0)]
              : 'terms_no_data'.localized() : terms.termsOfUse ? terms.termsOfUse : 'terms_no_data'.localized()}
          </DetailContainer>
          <ButtonGroup padding='0'>
            <ActionButton onClick={onClick} value={'confirm'.localized()} padding='0.75rem' margin='0.5rem 0 0 0' bgColor='white' width='6.5rem'/>
          </ButtonGroup>
        </>
      </ContentsWrapper>
      <MainFooter />
    </BackgroundContainer>
  )
}

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 1023px) {
    flex-direction: column-reverse;
  }
`

const TermsTitle = styled.div`
  width: 100%;
  color: var(--gray900);
  font-weight: 700;
  font-size: 1.75rem;
  text-align: left;
  padding-top: 1rem;
  padding-right: 35%;

  @media only screen and (max-width: 1023px) {
    padding-right: 0;
    margin-top: 1rem;
  }
`

const DetailContainer = styled.div`
  height: 35.6rem;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.08);
  border-radius: 0.5rem;
  text-align: left;
  padding: 1.5rem 1.75rem;
  box-sizing: border-box;
  overflow-y: scroll;
  color: var(--gray700);
  white-space: pre-wrap;
  
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #d9d9d9;
  }
`

export default MainUseTermsContainer

import styled from 'styled-components'

interface ContentsWrapper {
  paddingTop?: string,
  gap?: string
}

export const ContentsWrapper = styled.div<ContentsWrapper>`
  margin: 0 auto;
  padding: ${(props) => (props.paddingTop || '1rem')} 2.5rem 1.5rem;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 75rem;
  gap: ${(props) => (props.gap || '0.75rem')};

  @media only screen and (max-width: 1023px) {
    padding: 1.25rem 1rem;
  }
`

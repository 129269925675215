import React, { useState } from 'react'
import DatePicker, { DatePickerType } from './datePicker/DatePicker'
import styled from 'styled-components'
import { validateDateRange } from '../../utils/utils'
import useDidMountEffect from '../../hooks/useDidMountEffect'

export type RangePickerProps = {
  start: DatePickerType,
  end: DatePickerType,
  disablePast?: boolean,
}

const RangePickerContainer = ({ start, end, disablePast = false }: RangePickerProps) => {
  const [error, setError] = useState(false)

  useDidMountEffect(() => {
    setError(!validateDateRange(start.value, end.value, disablePast))
  }, [start.value, end.value])

  return (
    <RootContainer>
      <DatePicker value={start.value} onChangeDate={start.onChangeDate} error={error}/>
      <PickerDivider/>
      <DatePicker value={end.value} onChangeDate={end.onChangeDate} error={error}/>
      { error &&
        <ErrorMsg>{'period_invalid'.localized()}</ErrorMsg>
      }
    </RootContainer>
  )
}

const ErrorMsg = styled.div`
  position: absolute;
  top: 42px;
  font-size: 0.75rem;
  color: var(--warning);
`

const RootContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  min-width: 0;
  width: 100%;
  gap: 0.813rem;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: stretch;
  }
`

const PickerDivider = styled.div`
  width: 6px;
  height: 1px;
  flex-shrink: 0;
  background: var(--gray500);
  margin: 0 auto;
`

export default RangePickerContainer

import React, { useEffect } from 'react'
import { RouterPageView } from './../../route'
import { useHistory, useLocation } from 'react-router-dom'
import { useAppDispatch } from '../../store/hooks'
import { hideDialog } from '../../controllers/slices/dialogSlice'
import { hideLoading } from '../../controllers/slices/loadingSlice'

const RouterContainer = () => {
  console.log('RouterContainer')
  const history = useHistory() //최초 접속 경로 수정
  const location = useLocation()
  const dispatch = useAppDispatch()

  if (window.location.pathname === '/index.html' || window.location.pathname === '/entry.php') {
    let entry = sessionStorage.getItem('entry')
    if (entry) {
      sessionStorage.removeItem('entry')
      history.replace(entry)
    } else {
      history.replace('/')
    }
  } else {
    sessionStorage.removeItem('entry')
  }

  useEffect(() => {
    dispatch(hideDialog())
    dispatch(hideLoading())
  }, [location])

  return (
    <RouterPageView />
  )
}

export default RouterContainer

import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryReauth, NOTICE_API } from '../config'
import { getDataForm } from '../../../utils/utils'
import {
  getAllTermsResponse, getTermResponse,
  postTermRequest,
  postTermResponse,
  updateTermRequest,
  updateTermResponse
} from './types/terms'

export const termApi = createApi({
  reducerPath: 'termApi',
  baseQuery: baseQueryReauth,
  endpoints: (builder) => ({
    getAllTerms: builder.query<getAllTermsResponse, void>({
      query: () => NOTICE_API.ROOT,
    }),
    getCurrentTerm: builder.query<getTermResponse, void>({
      query: () => NOTICE_API.ROOT,
      transformResponse: (response: getAllTermsResponse) => {
        return response[0]
      },
    }),
    postTerm: builder.mutation<postTermResponse, postTermRequest>({
      query: (request) => ({
        url: NOTICE_API.ROOT,
        method: 'POST',
        body: getDataForm(request),
      })
    }),
    updateTerm: builder.mutation<updateTermResponse, updateTermRequest>({
      query: (request) => ({
        url: NOTICE_API.ROOT,
        method: 'PUT',
        params: request,
      })
    }),
  }),
})

export const {
  useGetAllTermsQuery,
  useGetCurrentTermQuery,
  usePostTermMutation,
  useUpdateTermMutation,
} = termApi

import { useEffect, useRef, useState } from 'react'
import Styled from './Dropdown.styled'
import { DropDownIcon } from '../../../assets/images'

interface DropdownProps {
  list: string[],
  zIndex?: number,
  value: string,
  onClick: (value: string) => void,
  isValid?: boolean
}

function Dropdown({ list, zIndex, value, onClick, isValid }: DropdownProps) {
  const [active, setActive] = useState(false)
  const dropdownRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleOutsideClose = (e: {target: any}) => {
      if(active && (!dropdownRef.current!.contains(e.target))) setActive(false)
    }
    document.addEventListener('click', handleOutsideClose)
    return () => document.removeEventListener('click', handleOutsideClose)
  }, [active])

  const isPlaceholder = (list: string[], value: string) => {
    return !list.find(element => element === value)
  }

  return (
    <Styled.selectbox active={active} ref={dropdownRef} onClick={() => setActive(!active)} isValid={isValid}>
      <Styled.selectedLabel isPlaceholder={isPlaceholder(list, value)} value={value} type={'button'}>
        {value}
      </Styled.selectedLabel>
      <Styled.moreIcon active={active} src={DropDownIcon}/>
      <Styled.selectOptionList active={active} zIndex={zIndex}>
        {list.length === 0 ? (
          <Styled.optionItem onClick={() => setActive(false)}>-</Styled.optionItem>
        ) : (
          list
            .filter(element => element !== value)
            .map(element => (
              <Styled.optionItem
                key={element}
                onClick={() => {
                  setActive(false)
                  onClick(element)
                }}
              >
                {element}
              </Styled.optionItem>
            ))
        )}
      </Styled.selectOptionList>
    </Styled.selectbox>
  )
}

export default Dropdown

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {useHistory, useLocation} from 'react-router-dom'
import { routePath } from '../../../../route'
import { ContentsWrapper } from '../../../components/ContentsWrapper'
import ActionButton from '../../../components/ActionButton'
import ButtonGroup from '../../../components/ButtonGroup'
import { useAppDispatch } from '../../../../store/hooks'
import { hideDialog, showDialog } from '../../../../controllers/slices/dialogSlice'
import { hideLoading, showLoading } from '../../../../controllers/slices/loadingSlice'
import { useGetSiteQuery, useUpdateSiteMutation } from '../../../../controllers/services/api/site'
import HomeButton from '../../../components/HomeButton'
import Disclaimer from '../../../components/Disclaimer'
import { useParamSiteSno } from '../../../../hooks/useParamSiteSno'
import { useLogoutMutation } from '../../../../controllers/services/api/auth'

type RouteState = {
  userId: string
}
const GuideContainer = () => {
  console.log('GuideContainer')
  const history = useHistory()
  const [guideText, setGuideText]: any = useState('')
  const [placeholderText, setPlaceholderText]: any = useState('')
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { pathname } = useLocation()
  const [brandName, setBrandName] = useState('')
  const { siteSno } = useParamSiteSno()
  const getSite = useGetSiteQuery({ siteSno })
  const [site, setSite] = useState<Site>()
  const [postUpdateSite, newUpdate] = useUpdateSiteMutation()
  const userId = location.state as RouteState
  const [userLogout, userLogoutState] = useLogoutMutation()

  useEffect(() => {
    if (!userId) {
      history.push(routePath.manage(siteSno), { replace: true })
    }
  }, [])

  useEffect(() => {
    if (getSite.isSuccess){
      setBrandName(getSite.data.name)
    }
  }, [getSite.isSuccess])

  useEffect(() => {
    if (getSite.isSuccess) {
      setSite(getSite.data)
    }
  }, [getSite.isSuccess])

  useEffect(() => {
    newUpdate.isLoading ? dispatch(showLoading()) : dispatch(hideLoading())

    if (newUpdate.isSuccess) {
      dispatch(
        showDialog({
          title: 'ad_guide',
          body: 'ad_guide_complete',
          confirm: {
            text: 'confirm',
            onClick: () => {
              dispatch(hideDialog())
              window.location.replace(pathname)
            },
          },
        }))
    } else if (newUpdate.isError){
      dispatch(
        showDialog({
          title: 'ad_guide',
          body: 'ad_guide_failed',
          confirm: {
            text: 'confirm',
            onClick: () => dispatch(hideDialog()),
          },
        }))
    }
  }, [newUpdate.isSuccess, newUpdate.isLoading, newUpdate.isError])

  useEffect(() => {
    if (userLogoutState.isSuccess) {
      history.push(routePath.main(siteSno), { replace: true })
    }
  }, [userLogoutState.isSuccess])

  useEffect(() => {
    setGuideText(site ? getSite.data?.guideline : '')
    setPlaceholderText(site ? ['ad_register_guide_input'.localized(), 'ad_guide_example'.localized()].join('') : '')
  }, [site])

  const onClick = () => {
    history.push(routePath.approve(siteSno), userId)
  }

  const onRenew = () => {
    setGuideText('')
  }

  const onRegister = () => {
    if (guideText.length > 0 && guideText.trim() !== '') {
      guidePosting()
    } else {
      dispatch(
        showDialog({
          title: 'ad_guide',
          body: 'ad_guide_empty',
          confirm: {
            text: 'confirm',
            onClick: () => dispatch(hideDialog()),
          },
        }))
    }
  }

  const guidePosting = () => {
    if (guideText.length > 500) {
      showLengthPopUp()
      return
    }
    postUpdateSite({
      siteSno: siteSno,
      guideline: guideText,
      name: brandName,
      thumbnailImageUrl: getSite?.data?.thumbnailImageUrl ?? ''
    })
  }

  const showLengthPopUp = () => {
    dispatch(
      showDialog({
        title: 'ad_guide',
        body: 'ad_guide_length',
        confirm: {
          text: 'confirm',
          onClick: () => dispatch(hideDialog()),
        },
      }))
  }

  const onLogout = () => {
    userLogout({
      userId : userId.userId
    })
  }

  function onTextChangeHandler(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setGuideText(event.target.value)
  }

  return (
    <div style={{backgroundColor: 'var(--gray50)', minHeight:'100vh'}}>
      <ContentsWrapper gap='0rem' paddingTop='1.875rem'>
        <Disclaimer />
        <HomeButton marginTop={'1.25rem'} logOut={onLogout}/>
        <TitleContainer>
          <TitleText>{brandName} {'manage_office'.localized()}</TitleText>
          <SubTitleNav>
            <div className="isSelectedX" onClick={onClick}>{'ad_agree_reject'.localized()}</div>
            <div className="isSelectedO" >{'ad_guide'.localized()}</div>
          </SubTitleNav>
        </TitleContainer>
        <MainContainer>
          <PostingGuideContainer>
            <div className="postingGuideTitle">{'ad_guide'.localized()}</div>
            <PostingAdContainer>
              <InputBox value={guideText} placeholder={placeholderText} onChange={onTextChangeHandler} />
              <PostingAdBoardGuide>{'ad_guide_notice'.localizedWithBR()}</PostingAdBoardGuide>
            </PostingAdContainer>
          </PostingGuideContainer>
        </MainContainer>
        <ButtonGroup padding='2rem 0 0'>
          <ActionButton onClick={onRenew} value={'renew'.localized()} padding='0.875rem' bgColor='white' width='6rem'/>
          <ActionButton onClick={onRegister} value={'register'.localized()} padding='0.875rem' bgColor='white' width='6rem'/>
        </ButtonGroup>
      </ContentsWrapper>
    </div>
  )
}

const TitleContainer = styled.div`
  width: 100%;
  font-size: 1rem;
  text-align: center;
  padding: 1rem 0 3.25rem;
  display: flex;
  flex-direction: column;
`

const TitleText = styled.div`
  width: 100%;
  font-weight: 700;
  color: var(--gray900);
  font-size: 1.75rem;
  line-height: 100%;
  text-align: center;
`

const SubTitleNav = styled.div`
  display: flex;
  justify-content: center;
  padding: 2.25rem 0 0;
  gap: 2.44rem;
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: -0.01em;
  cursor: pointer;
  .isSelectedO{
    text-decoration-line: underline;
    height: 100%;
  }
  .isSelectedX{
    color: var(--coolgray);
    height: 100%;
  }
`

const MainContainer = styled.div`
  width: calc(100% - 3.5rem);
  height: 100%;
  max-width: 71.375rem;
  margin-top: -1rem;
  background-color: var(--white);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  display: flex;
  padding: 2rem 1.625rem 2rem 2rem;
`

const PostingGuideContainer = styled.div`
  width: 100%;
  font-size: 1rem;
  line-height: 100%;
  color: var(--gray900);
  background-color: var(--white);
  display: flex;

  @media only screen and (max-width: 280px) {
    flex-direction: column;
  }

  .postingGuideTitle {
    padding-top: 0.5rem;
    padding-right: 2.25rem;
    font-weight: 700;
    color: var(--gray900);
    flex: 1;

    @media only screen and (max-width: 280px) {
      padding-right: 0;
      padding-bottom: 2.25rem;
      text-align:center;
    }
  }
`

const PostingAdContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 9;
`

const InputBox = styled.textarea`
  width: 100%;
  max-width: 62.5rem;
  height: 27rem;
  border: 0.0625rem solid var(--gray300);
  border-radius: 0.25rem;
  line-height: 150%;
  letter-spacing: -0.01em;
  text-align: left;
  font-size: 1rem;
  resize: none;
`

const PostingAdBoardGuide = styled.div`
  width: 100%;
  font-size: 0.875rem;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: var(--gray700);
  text-align: left;
`

export default GuideContainer

import React, { ChangeEvent, useEffect, useRef } from 'react'
import { BackgroundContainer } from '../../../components/BackgroundContainer'
import { ContentsWrapper } from '../../../components/ContentsWrapper'
import BodyTitleContainer from '../../../components/BodyTitleContainer'
import ActionButton from '../../../components/ActionButton'
import styled from 'styled-components'
import ResourceItem from './ResourceItem'
import { ContentsBox } from '../../../components/ContentsBox'
import { useResourceState } from '../../../../hooks/useResourceState'

const ResourceContainer = () => {
  console.log('AdminResourceContainer')
  const imgRef = useRef<HTMLInputElement | null>(null)
  const { getRsc, postRsc, deleteRsc, resources } = useResourceState()

  useEffect(() => {
    getRsc()
  }, [])

  const handleClickUpload = () => {
    if (!imgRef.current) {
      return;
    }
    imgRef.current!.click()
  }

  const handleUploadImg = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      postRsc(e.target.files[0])
        .then(() => {
          getRsc()
        })
    }
  }

  const handleClickDelete = (rsc: Resource) => {
    deleteRsc(rsc)
      .unwrap()
      .then(() => {
        getRsc()
      })
  }

  return (
    <BackgroundContainer>
      <ContentsWrapper>
        <ResourceTitleWrapper>
          <BodyTitleContainer title={'admin_resources'.localized()}/>
          <ActionButton onClick={handleClickUpload} value={'button_img_upload'.localized()} padding='0.75rem 1rem' bgColor='white'/>
          <input type='file' accept='image/*' style={{ display: 'none' }} ref={imgRef} onChange={handleUploadImg}/>
        </ResourceTitleWrapper>
        <ResourcesContainer>
          {
            resources && resources.length > 0 ?
            resources.map((rsc, index) => <ResourceItem key={index} rsc={rsc} onDelete={handleClickDelete}/>)
            : <ContentsBox padding='1.875rem 2rem'>{'admin_resources_empty'.localized()}</ContentsBox>
          }
        </ResourcesContainer>
      </ContentsWrapper>
    </BackgroundContainer>
  )
}

const ResourceTitleWrapper = styled.div`
  width: 100%;
  
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ResourcesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
`

export default ResourceContainer

import { useHistory } from 'react-router-dom'
import { useAppDispatch } from '../store/hooks'
import { useCheck_ipQuery } from '../controllers/services/api/auth'
import { useLayoutEffect, useState } from 'react'
import { hideLoading, showLoading } from '../controllers/slices/loadingSlice'

const useAllowIP = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { data, isLoading, isSuccess, isError } = useCheck_ipQuery()
  const [isAllowed, setIsAllowed] = useState(false)

  useLayoutEffect(() => {
    isLoading ? dispatch(showLoading()) : dispatch(hideLoading())

    if (isSuccess) {
      if (data.message === 'Y') {
        setIsAllowed(true)
      } else {
        history.replace('/notfound')
      }
    }

    if (isError) {
      history.replace('/notfound')
    }
  }, [isSuccess, isLoading, isError])

  return { isAllowed }
}

export default useAllowIP

import React, { useState } from 'react'
import Dropdown from '../../../components/dropdown/Dropdown'
import { AccessLevel, adminAuthority } from '../../../../resource/constants'
import styled from 'styled-components'

type AccountRowType = {
  username: string,
  email: null | string,
  authority: string,
  onChangeAuth: (id: string, value: AccessLevel) => void,
}

const Account = ({ username, authority, email, onChangeAuth }: AccountRowType) => {
  const [auth, setAuth] = useState<string>(authority)

  const onChangeAuthority = (value: string) => {
    setAuth(value);

    switch (value) {
      case 'user':
        onChangeAuth(username, 'LEVEL1')
        break
      case 'admin':
      default:
        onChangeAuth(username, 'LEVEL0')
        break
    }
  }

  return (
    <AuthAccountRow>
      <IDCell>{username}</IDCell>
      <EmailCell>{email}</EmailCell>
      <DropdownCell>
        <Dropdown list={adminAuthority} value={auth} onClick={onChangeAuthority} />
      </DropdownCell>
    </AuthAccountRow>
  )
}

const AuthAccountRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.375rem 2.5rem;
  gap: 1rem;
  box-sizing: border-box;
  background: #f0f3f6;
  border-radius: 8px;
  
  text-align: start;
  font-size: 1rem;
  letter-spacing: -0.01em;
  color: var(--gray900);
  
  @media only screen and (max-width: 1023px) {
    flex-direction: column;
    align-items: start;
    padding: 1rem;
  }
`

const IDCell = styled.div`
  max-width: 12.5rem;
  width: 100%;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const EmailCell = styled.div`
  flex-grow: 1;
  max-width: 25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const DropdownCell = styled.div`
  max-width: 25rem;
  width: 100%;
  align-self: center;
`

export default Account

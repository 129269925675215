import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryReauth, RESOURCE_API } from '../config'
import { getDataForm } from '../../../utils/utils'
import {
  deleteFileRequest,
  deleteFileResponse,
  getAllFilesResponse,
  postFileRequest,
  postFileResponse
} from './types/file'

const BUILD_ENV = process.env.REACT_APP_SERVICE_TYPE_2

export const fileApi = createApi({
  reducerPath: 'fileApi',
  baseQuery: baseQueryReauth,
  endpoints: (builder) => ({
    postFile: builder.mutation<postFileResponse, postFileRequest>({
      query: (request) => ({
        url: RESOURCE_API.ROOT,
        method: 'POST',
        body: getDataForm(request),
      })
    }),
    deleteFile: builder.mutation<deleteFileResponse, deleteFileRequest>({
      query: (request) => ({
        url: `${RESOURCE_API.ROOT}/${request.fileId}`,
        method: 'DELETE',
      })
    }),
    getAllFiles: builder.query<getAllFilesResponse, void>({
      query: () => {
        return {
          url: RESOURCE_API.ROOT,
          params: { dirName: BUILD_ENV }
        }
      }
    }),
  })
})

export const {
  usePostFileMutation,
  useDeleteFileMutation,
  useLazyGetAllFilesQuery,
} = fileApi

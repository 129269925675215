import React from 'react'
import { Backdrop, CircularProgress } from '@mui/material'
import { useAppSelector } from '../../store/hooks'

const BackdropLoading = () => {
  const loading = useAppSelector((state) => state.loading)

  return (
    <Backdrop open={loading.show} sx={{ color: 'var(--white)', zIndex: '100' }}>
      <CircularProgress color='inherit'/>
    </Backdrop>
  )
}

export default BackdropLoading

import React, { useEffect, useRef, useState } from 'react'
import Styled from './DatePicker.styled'
import { CalendarIcon } from '../../../assets/images'
import { Dayjs } from 'dayjs'
import { DateCalendar } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { koKR } from '@mui/x-date-pickers/locales/koKR'
import 'dayjs/locale/ko'

export type DatePickerType = {
  value: Dayjs,
  onChangeDate: (value: Dayjs) => void,
}

interface DatePickerProps {
  value: Dayjs
  onChangeDate: (newDate: Dayjs) => void
  error?: boolean
}

const DatePicker = ({ value, onChangeDate, error }: DatePickerProps) => {
  const [calendar, setCalendar] = useState(false)
  const [placement, setPlacement] = useState<'top' | 'bottom'>('bottom')
  const calendarRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (calendar) {
      if (window.innerHeight - calendarRef.current!.getBoundingClientRect().bottom < 400) {
        setPlacement('top')
      } else {
        setPlacement('bottom')
      }
    }

    const handleOutsideClose = (e: {target: any}) => {
      if (calendar && (!calendarRef.current!.contains(e.target))) setCalendar(false);
    }
    document.addEventListener('click', handleOutsideClose)
    return () => document.removeEventListener('click', handleOutsideClose)
  }, [calendar])

  const handleClose = () => {
    setCalendar(!calendar)
  }

  const onChange = (newValue: Dayjs | null) => {
    if (newValue) {
      onChangeDate(newValue)
      if (newValue?.format('MM-DD') !== value?.format('MM-DD')) {
        setCalendar(!calendar)
      }
    }
  }

  return (
    <Styled.selectBox ref={calendarRef} onClick={handleClose} error={error}>
      <Styled.selectedLabel>
        {value?.format('YYYY-MM-DD')}
      </Styled.selectedLabel>
      <Styled.calendarIcon src={CalendarIcon} />
      <Styled.calendarContainer visible={calendar} placement={placement} onClick={ (e) => {e.stopPropagation() }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ko'} localeText={koKR.components.MuiLocalizationProvider.defaultProps.localeText}>
          <DateCalendar value={value} onChange={onChange}/>
        </LocalizationProvider>
      </Styled.calendarContainer>
    </Styled.selectBox>
  )
}

export default DatePicker

import dayjs, { Dayjs } from 'dayjs'
import { ChangeEvent } from 'react'

/**
 * 날짜 기간 검증
 * date2가 date1보다 앞서거나, 두 날짜가 현재 날짜보다 이른 경우 return false
 * 날짜는 선택일 00시00분00초 기준
 * @param date1 - 시작 날짜
 * @param date2 - 종료 날짜
 * @param disablePast - date1 지난날짜 선택가능? default false
 * */
export const validateDateRange = (date1: Dayjs, date2: Dayjs, disablePast: boolean = false) => {
  const now = setTimeToZero(dayjs())
  if (date1 > date2) return false

  if (disablePast) {
    return !(date1 < now || date2 < now)
  } else {
    return date2 >= now
  }
}

/*
날짜값에 포함된 시간을 0으로 초기화
 */
export const setTimeToZero = (value: Dayjs) => {
  return value.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0)
}

/*
formatting unix timestamp to YY.MM.DD
 */
export const formatDateString = (timeStamp: string | undefined) => {
  if (timeStamp) {
    return dayjs.unix(Number(timeStamp)).format('YY.MM.DD')
  }

  return 'unknown'
}

export type patternType = 'any' | 'password' | Array<'alpha' | 'numeric' | 'korean' | 'specialChar'>

type formOptionProps = {
  required: boolean,
  maxLength?: number,
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
  typePattern?: patternType,
}
/**
 * input field 검증 함수
 * @param required 필수 입력 값 여부 설정
 * @param maxLength 입력 최대 길이 설정 default 255
 * @param onChange onChange func
 * @param typePattern 입력 허용 타입 설정 default any;
 **/
export const formOptions = ({ required, maxLength = 255, onChange, typePattern = 'any' }: formOptionProps) => {
  return {
    validate: (value: string) => {
      if (required && !value.trim()) {
        return '필수 입력 항목입니다.'
      } else return true
    },
    pattern: getInputPattern(typePattern, maxLength),
    required: required ? '필수 입력 항목입니다.' : false,
    onChange: onChange,
  }
}

export const getInputPattern = (type: patternType, maxLength?: number) => {
  if (type === 'any') {
    return {
      value: new RegExp(`^.{0,${maxLength}}$`),
      message: `최대 ${maxLength}자까지 입력 가능합니다.`
    }
  } else if (type === 'password') {
    return {
      value: /^(?=.*[a-zA-Z])(?=.*[{}\[\]/?.,;:)*~`!\-_+<>@#$&'"\\(`])(?=.*[0-9])[A-Za-z\d{}\[\]/?.,;:)*~`!\-_+<>@#$%&'"\\(`]{8,20}$/,
      message: `비밀번호는 영문, 숫자, 특수문자 3가지 모두 조합한 8~20자리여야 합니다.`
    }
  } else {
    const regexPattern: Array<{ regex: string, type: string }> = type.map((t) => {
      switch (t) {
        case 'alpha':
          return {
            regex: 'a-zA-Z',
            type: '영문',
          }
        case 'korean':
          return {
            regex: 'ㄱ-ㅎ가-힣',
            type: '한글',
          }
        case 'numeric':
          return {
            regex: '0-9',
            type: '숫자',
          }
        case 'specialChar':
          return {
            regex: '{}\\[\\]\\/\\?.,;:\\)\\*~`!\\-_\\+<>@\\#\\$%&\'"\\(',
            type: '특수문자'
          }
      }
    })

    const regex = new RegExp(`^[`.concat(...regexPattern.map((t) => t.regex)).concat(`${regexPattern.length === 1 ? '' : ' '}`).concat(`]{0,${maxLength}}$`))
    const typeStr = regexPattern.map((t) => t.type)
    return {
      value: regex,
      message: `${typeStr.join(', ')}${typeStr.at(-1) === '영문' ? '으로' : '로'} 최대 ${maxLength}자까지 입력 가능합니다.`
    }
  }
}

export const isEmailPattern = (str: string): boolean => {
  const regex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z가-힣]([-_.]?[0-9a-zA-Z가-힣])*\.[a-zA-Z]{2,3}$/;
  return regex.test(str)
}

export const formatPhoneNumber = (phoneNumber: string): string => {
  const number = phoneNumber.trim().replace(/\D/g, '')

  if (number.startsWith('02')) {
    if (number.length < 6) return number.replace(/(\d{2})(\d)/, '$1-$2')
    if (number.length < 10) return number.replace(/(\d{2})(\d{3})(\d)/, '$1-$2-$3')
    return number.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3')
  }

  if (number.length < 4) return number
  if (number.length < 7) return number.replace(/(\d{3})(\d)/, "$1-$2")
  if (number.startsWith('1') && number.length === 8)
    return number.replace(/(\d{4})(\d{4})/, '$1-$2')
  if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d)/, "$1-$2-$3")
  return number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
}

export const maskingName = (name: string | undefined) => {
  if (!name) return ''

  if (name.length > 2) {
    const originName = name.split('');
    originName.forEach(function(value, i) {
      if (i === 0 || i === originName.length - 1) return;
      originName[i] = '*';
    });
    const joinName = originName.join();
    return joinName.replace(/,/g, '');
  } else {
    return name.replace(/.$/, '*');
  }
}

export const maskingPhone = (phoneNumber: string | undefined) => {
  if (!phoneNumber) return ''

  const tokens = phoneNumber.split('-')

  if (tokens.length > 2) {
    tokens[1] = '*'.repeat(tokens[1].length)
    return tokens.join('-')
  } else {
    return phoneNumber.replace(/...$/, '***')
  }
}

export const getDataForm = (object: any) => Object.keys(object).reduce((formData, key) => {
  formData.append(key, object[key])
  return formData
}, new FormData())

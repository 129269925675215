import React from 'react'
import BoxContentsWrapper from './BoxContentsWrapper'
import ContainerInfoBox from './ContainerInfoBox'
import { formatDateString, maskingName, maskingPhone } from '../../utils/utils'
import styled from 'styled-components'

const AdInfo = ({ ad } : { ad : AdInfo }) => {
  return (
    <>
      <BoxContentsWrapper gap='2rem'>
        <ContainerInfoBox label={'company_name'.localized()} content={ad.storeName} maxColumns={2}/>
        <ContainerInfoBox label={'category'.localized()} content={ad.category} maxColumns={2}/>
        <ContainerInfoBox label={'name'.localized()} content={maskingName(ad.author)} maxColumns={2}/>
        <ContainerInfoBox label={'phone_no'.localized()} content={maskingPhone(ad.storeContactNum)} maxColumns={2}/>
        <ContainerInfoBox label={'boarding_period'.localized()} content={formatDateString(ad.startAt) + ' ~ ' + formatDateString(ad.finishAt)} />
        <ContainerInfoBox label={'address'.localized()} content={ad.storeAddress}/>
        <ContainerInfoBox label={'desc'.localized()} content={ad.description}/>
        <ContainerInfoBox label={'homepage'.localized()} content={ad.storeWebsiteUrl}/>
      </BoxContentsWrapper>
      <ImageWrapper src={ad.bannerImageUrl} />
    </>
  )
}

const ImageWrapper = styled.img`
  max-width: 18.75rem;
  width: 100%;
  height: 10rem;

  @media only screen and (max-width: 1023px) {
    align-self: center;
  }
`

export default AdInfo

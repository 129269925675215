import React from 'react'
import styled from 'styled-components'
import { ContentsWrapper } from '../../../components/ContentsWrapper'

const DisclaimerContainer = () => {
  console.log('DisclaimerContainer')

  return (
    <ContentsWrapper gap='2rem' paddingTop='1.875rem'>
      <BgContainer>
        <Disclaimer />
      </BgContainer>
    </ContentsWrapper>
  )
}

const BgContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background: var(--white);
  box-shadow: 0 0.1875rem 0.3125rem rgba(0, 0, 0, 0.08);
  border-radius: 0.5rem;
`

const Disclaimer = styled.div`
  height: 100%;
  padding-left: 1.875rem;
  padding-top: 1.375rem;
  padding-bottom: 1.375rem;
  font-size: 0.75rem;
  line-height: 150%;
  letter-spacing: -0.01em;
  text-align: left;
`

export default DisclaimerContainer

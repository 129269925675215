import styled from 'styled-components'

const Styled = {
  Background: styled.div`
    height: 100%;
    min-height: 100vh;
    padding: 5rem;
    box-sizing: border-box;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--bg-blue);

    @media only screen and (max-width: 767px) {
      padding: 1rem;
    }
  `,

  RootWrapper: styled.div`
    width: 100%;
    max-width: 75rem;
    margin: 0 auto;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    gap: 2.5rem;

    z-index: 10;

    @media only screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
  `,

  RootTitleContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;

    text-align: start;
    color: var(--white);
    font-weight: 700;

    div:nth-child(1) {
      font-size: 1.5rem;
    }

    div:nth-child(2) {
      font-size: 5rem;
      line-height: 110%;
    }

    div:nth-child(3) {
      padding-top: 1.5rem;
      font-weight: 400;
      font-size: 1.375rem;
      line-height: 150%;
      color: #d3dff2;
    }

    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  `,

  SelectorContainer: styled.div`
    width: 30.625rem;
    min-width: 0;

    display: flex;
    flex-direction: column;

    .helper {
      padding-top: 1rem;
      text-align: end;
      font-size: 1.125rem;
      color: var(--white);
    }

    @media only screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 10rem;
    }
  `,

  SitesListContainer: styled.div`
    width: 100%;
    height: 42.5rem;
    padding: 3.125rem;
    box-sizing: border-box;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: #6d8fc4;
    border-radius: 20px;

    &::-webkit-scrollbar {
      width: 1.5rem;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--gray300);
      border-radius: 16px;
      border: 8px solid #6d8fc4;
    }

    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
    }

    @media only screen and (max-width: 767px) {
      height: auto;
      padding: 1.5rem;
    }
  `,

  SiteItem: styled.div`
    width: 100%;
    min-height: 5.375rem;
    padding: 1.25rem;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    gap: 1rem;

    background: var(--white);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    cursor: pointer;

    img {
      max-width: 3rem;
      max-height: 3rem;
    }

    div {
      text-align: start;
      line-height: 100%;
      font-size: 1.125rem;
      text-overflow: ellipsis;
      word-wrap: break-word;
      flex-grow: 1;
    }

    .icon {
      padding: 0.25rem;
    }
  `,

  BottomImg: styled.img`
    width: 87.5%;
    max-width: 75rem;
    position: absolute;
    bottom: 0;
    user-select: none;
    -webkit-user-drag: none;
  `
}

export default Styled

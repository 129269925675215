import React from 'react'
import './App.css'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { routePath } from './route'
import RouterContainer from './view/container/RouterContainer'
import { LocalizeString } from './resource/string.extension'
import Dialog from './view/components/Dialog'
import BackdropLoading from './view/components/BackdropLoading'
import { hideDialog, showDialog } from './controllers/slices/dialogSlice'
import { useAppDispatch } from './store/hooks'
import packageJson from '../package.json'

function App() {
  console.log('우리 상가 광고 서비스 버전 :'+ packageJson.version)
  LocalizeString('ok')

  const dispatch = useAppDispatch()
  const networkPopup = () => {
    dispatch(
      showDialog({
        title: 'Error',
        titleHighlight: true,
        body: 'network_error',
        confirm: {
          text: 'confirm',
          onClick: () => dispatch(hideDialog()),
        },
      }))
  }

  window.addEventListener('offline', ()=>{ networkPopup() });
  return (
    <div onContextMenu={(e) => e.preventDefault()} className="App">
      <BrowserRouter>
        <Switch>
          <Route path={routePath.root} component={RouterContainer} />
        </Switch>
      </BrowserRouter>
      <Dialog/>
      <BackdropLoading/>
    </div>
  )
}

export default App

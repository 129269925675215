import React from 'react'
import { routePath } from '../../../../route'
import { ContentsBox } from '../../../components/ContentsBox'
import { useHistory } from 'react-router-dom'
import ActionButton from '../../../components/ActionButton'
import styled from 'styled-components'
import AdInfo from '../../../components/AdInfo'

const AdInfoContent = ({ ad } : { ad : AdInfo }) => {
  const history = useHistory()

  const handleEditButton = () => {
    history.push(`${routePath.adEdit}/${ad.adId}`, { replace: true })
  }

  return (
    <ContentsBox padding='1.875rem 2rem'>
      <AdInfoWrapper>
        <AdInfo ad={ad}/>
      </AdInfoWrapper>
      <ButtonWrapper>
        <ActionButton onClick={handleEditButton} value={'button_edit'.localized()} margin='-0.25rem 0' padding='0.75rem 1.875rem' bgColor='white'/>
      </ButtonWrapper>
    </ContentsBox>
  )
}

const AdInfoWrapper = styled.div`
  display: flex;
  @media only screen and (max-width: 1023px) {
    flex-direction: column-reverse;
    align-items: stretch;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
`

export default AdInfoContent

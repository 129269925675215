import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { routePath } from '../../../../route'
import LoginContainer from '../../../components/LoginContainer'
import { useAppDispatch } from "../../../../store/hooks"
import { hideDialog, showDialog } from '../../../../controllers/slices/dialogSlice'
import { useParamSiteSno } from '../../../../hooks/useParamSiteSno'
import { ResultStatus } from '../../../../controllers/services/errorHandler'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

const ManageLoginContainer = () => {
  console.log('ManageLoginContainer')
  const history = useHistory()
  const { siteSno } = useParamSiteSno()
  const dispatch = useAppDispatch()
  const [tryLoginState, setTryLoginState] = useState(1)

  const handleClickCancel = async () => {
    history.push(routePath.main(siteSno), { replace: true })
  }

  const onLoginSucceed = (user: User) => {
    if (user.resetPasswordYn === 'Y' && user.username) {
      onRouteResetPw(user.username, siteSno, user.userId)
    } else if(user.termsApprovedYn === 'N'){
      history.push(routePath.terms(siteSno), { userId: user.userId , userRole: user.role})
    } else{
      history.push(routePath.approve(siteSno), { userId: user.userId })
    }
  }

  const onRouteResetPw = (username: string, siteSno: string, userId: string) => {
    history.push(routePath.changePassword(encodeURI(username), siteSno), { userId, backPath: routePath.manage(siteSno)})
  }

  const onLoginFailed = (error: FetchBaseQueryError) => {
    switch (error.status) {
      case ResultStatus.NotFound:
      case ResultStatus.BadRequest:
        if((error.data as Error).error === '관리사무소 계정이 존재하지 않습니다.'){
          dispatch(
            showDialog({
              title: 'notice',
              body: tryLoginState > 4 ? 'account_lock' :'check_password_detail'.localized(tryLoginState),
              confirm: {
                text: 'confirm',
                onClick: () => dispatch(hideDialog()),
              },
            })
          )
          setTryLoginState(tryLoginState + 1)
          break
        } else {
          dispatch(
            showDialog({
              title: 'notice',
              body: (error.data as Error).error,
              confirm: {
                text: 'confirm',
                onClick: () => dispatch(hideDialog()),
              },
            })
          )
        }
          break
    }
  }

  return (
    <LoginContainer
      target='manager'
      title={'manage_login'.localized()}
      subTitle={'manage_login_detail'.localized()}
      submitText={'login'.localized()}
      styleOptions={{ hasFindBtn: false, hasRememberBtn: true }}
      onCancel={handleClickCancel}
      onSucceed={onLoginSucceed}
      onFailed={onLoginFailed}
    />
  )
}

export default ManageLoginContainer

import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryReauth, CATEGORY_API } from '../config'
import { getAllCategoriesResponse } from './types/category'

export const categoryApi = createApi({
  reducerPath: 'categoryApi',
  baseQuery: baseQueryReauth,
  endpoints: (builder) => ({
    getCategories: builder.query<getAllCategoriesResponse, void>({
      query: () => CATEGORY_API.ROOT
    }),
  }),
})

export const {
  useGetCategoriesQuery,
} = categoryApi

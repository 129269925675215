import React from 'react'
import styled from 'styled-components'

type LoginCardWrapperProps = {
  children: React.ReactNode,
}

const LoginCardWrapper = ({ children }: LoginCardWrapperProps) => {
  return (
    <BgContainer>
      <Wrapper>
        {children}
      </Wrapper>
    </BgContainer>
  )
}

const BgContainer = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--bg-default);
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 37.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  padding: 5rem 0;
  box-sizing: border-box;
  
  background: var(--white);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  color: var(--gray900);

  @media only screen and (max-width: 767px) {
    margin: 0 0.5rem;
    padding: 5rem 2.5rem;
  }
`

export default LoginCardWrapper

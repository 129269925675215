import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'
import { ContentsWrapper } from '../../components/ContentsWrapper'
import ButtonGroup from '../../components/ButtonGroup'
import ActionButton from '../../components/ActionButton'
import { Divider } from '../../components/Divider'
import { BackgroundContainer } from '../../components/BackgroundContainer'
import Disclaimer from '../../components/Disclaimer'
import AdStatusBadge from '../../components/AdStatusBadge'
import { hideLoading, showLoading } from '../../../controllers/slices/loadingSlice'
import { useAppDispatch } from '../../../store/hooks'
import { useNoticePopUp } from '../../../hooks/useNoticePopUp'
import { useGetAdMutation } from '../../../controllers/services/api/ad'
import { routePath } from '../../../route'
import { useParamSiteSno } from '../../../hooks/useParamSiteSno'
import AdInfo from '../../components/AdInfo'

const MainPreviewContainer = () => {
  console.log('MainPreviewContainer')
  const history = useHistory()
  const { adId } = useParams<{ adId: string }>()
  const dispatch = useAppDispatch()
  const { showPopUp } = useNoticePopUp()
  const [ad, setAd] = useState<AdInfo>()
  const [getAdInfo, getAdInfoState] = useGetAdMutation()
  const { siteSno } = useParamSiteSno()

  useEffect(() => {
    getAdInfo({adId : adId})
  }, [])


  useEffect(() => {
    getAdInfoState.isLoading ? dispatch(showLoading()) : dispatch(hideLoading())

    if (getAdInfoState.isSuccess) {
      setAd(getAdInfoState.data)
    }

    if (getAdInfoState.isError) {
      showPopUp('광고를 불러올 수 없습니다. 다시 시도해 주세요.')
    }

  }, [getAdInfoState.isSuccess, getAdInfoState.isLoading, getAdInfoState.isError])

  const onClick = () => {
    history.push(routePath.main(siteSno), { replace: true })
  }

  return (
    <BackgroundContainer headerHeight='0rem'>
      <ContentsWrapper gap='1.5rem' paddingTop='1.875rem'>
        <Disclaimer/>
        { ad &&
          <>
            <TitleContainer>
              <AdStatusBadge startDate={ad.startAt} finishDate={ad.finishAt} isApproval={ad.approvalYn === 'Y'} />
              <TitleText>{ad?.storeName}</TitleText>
            </TitleContainer>
            <Divider margin='0'/>
            <PostingGuideContainer>
              <AdInfo ad={ad} />
            </PostingGuideContainer>
            <ButtonGroup padding='0'>
              <ActionButton onClick={onClick} value={'confirm'.localized()} padding='0.75rem' margin='0.5rem 0 0 0' bgColor='white' width='6.5rem'/>
            </ButtonGroup>
          </>
        }
      </ContentsWrapper>
    </BackgroundContainer>
  )
}

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-top: 1.5rem;
`

const TitleText = styled.div`
  width: 100%;
  color: var(--gray900);
  font-weight: 700;
  font-size: 1.56rem;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const PostingGuideContainer = styled.div`
  width: 100%;
  font-size: 1rem;
  color: var(--gray900);
  display: flex;
  font-weight: 700;
  background: var(--white);
  padding: 2rem 2rem 2.25rem;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  box-sizing: border-box;
  gap: 1rem;

  @media only screen and (max-width: 1023px) {
    flex-direction: column-reverse;
    align-items: stretch;
  }
`

export default MainPreviewContainer

import React from 'react'
import styled, { css } from 'styled-components'

type ButtonProps = {
  bgColor: 'black' | 'white',
  margin: string,
  padding: string,
  width: string,
  height: string,
  value: string,
  onClick: () => void,
  disabled?: boolean,
}

interface ButtonWrapperProps {
  bgColor: 'black' | 'white'
  margin: string
  padding: string
  width: string
  height: string
  disabled: boolean
}

const ActionButton = ({ bgColor, margin, padding, width, height, value, onClick, disabled = false }: ButtonProps) => {
  return (
    <ButtonWrapper
      onClick={onClick}
      bgColor={bgColor}
      margin={margin}
      padding={padding}
      width={width}
      height={height}
      disabled={disabled}
    >{value}
    </ButtonWrapper>
  )
}

const ButtonWrapper = styled.button<ButtonWrapperProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 1rem;
  line-height: 100%;
  
  text-align: center;
  cursor: pointer;
  
  ${(props) => props.bgColor === 'black' ? css`
    background: var(--gray900);
    border: 1px solid var(--gray900);
    color: var(--white);
    
    &:hover {
      background: #2c3238;
    }
  ` : css`
    background: var(--white);
    border: 1px solid var(--gray500);
    color: var(--gray900);
    
    &:hover {
      background: #e6eaee;
    }
  `}
  
  ${(props) => props.disabled && css`
    ${props.bgColor === 'black' ? css`
      background: var(--gray200);
      border: 1px solid var(--gray200);
      color: var(--gray500);
      &:hover {
        background: var(--gray200);
      }
    ` : css`
      border: 1px solid var(--gray300);
      color: var(--gray400);
      &:hover {
        background: var(--white);
      }
    `}
    cursor: auto;
  `}
`

ActionButton.defaultProps = {
  bgColor: 'white',
  margin: 0,
  padding: '0.75rem 2.25rem',
  width: 'auto',
  height: 'auto',
}

export default ActionButton

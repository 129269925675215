import React, { HTMLInputTypeAttribute } from 'react'
import styled from 'styled-components'
import Dropdown from './dropdown/Dropdown'
import DatePicker, { DatePickerType } from './datePicker/DatePicker'
import RangePickerContainer, { RangePickerProps } from './RangePickerContainer'
import { UseFormRegisterReturn } from 'react-hook-form'

type InfoType = 'none' | 'edit' | 'empty' | 'dropdown' | 'datePicker' | 'rangePicker'

type DropdownType = {
  value: string,
  options: string[],
  onClick: (value: string) => void
}

type InfoProps = {
  type: InfoType,
  label: string,
  titleWidth: string,
  content?: string,
  maxColumns: number,
  maxWidth: string,
  dropdown: DropdownType,
  datePicker: DatePickerType,
  rangePicker: RangePickerProps,
  inputType?: HTMLInputTypeAttribute,
  register?: UseFormRegisterReturn,
  errorMessage?: string,
  placeholder?: string,
  required?: boolean,
}

const ContainerInfoBox = ({ label, content, titleWidth, maxColumns, maxWidth, type, dropdown, datePicker, rangePicker, inputType, register, errorMessage, placeholder, required = false }: InfoProps) => {

  const getMaxColumns = (maxColumns: number) => {
    switch (maxColumns) {
      case 1:
        return '100%'
      case 2:
        return '48%'
      case 3:
        return '30%'
      case 4:
        return '24%'
      default:
        return '100%'
    }
  }

  const setComponentByType = (type: InfoType) => {
    switch (type) {
      case 'edit':
        return <InfoContentEditable
          defaultValue={content !== 'null' ? content : ''}
          placeholder={placeholder ?? label.appendPostWord() + 'input_placeholder'.localized()}
          isValid={!errorMessage}
          type={inputType ?? 'text'}
          {...register}
        />
      case 'dropdown':
        return <Dropdown onClick={dropdown.onClick} value={dropdown.value} list={dropdown.options} isValid={!errorMessage}/>
      case 'datePicker':
        return <DatePicker value={datePicker.value} onChangeDate={datePicker.onChangeDate}/>
      case 'rangePicker':
        return <RangePickerContainer start={rangePicker.start} end={rangePicker.end} disablePast={rangePicker.disablePast}/>
      case 'empty':
        return <Empty/>
      case 'none':
      default :
        return <InfoContent>{content}</InfoContent>
    }
  }

  return (
    <InfoWrapper
      width={getMaxColumns(maxColumns)}
      alignCenter={type !== 'none'}
      empty={type === 'empty'}
    >
      <InfoLabel titleWidth={titleWidth}>{`${label}${required ? '*' : ''}`}</InfoLabel>
      <FieldWrapper maxWidth={maxWidth}>
        {setComponentByType(type)}
        {errorMessage && <InvalidText>{errorMessage}</InvalidText>}
      </FieldWrapper>
    </InfoWrapper>
  )
}

const FieldWrapper = styled.div<{ maxWidth: string }>`
  position: relative;
  max-width: ${(props) => (props.maxWidth)};
  width: 100%;
  min-width: 0;

  @media only screen and (max-width: 1023px) {
    max-width: 100%;
  }
`

const InvalidText = styled.div`
  position: absolute;
  top: 40px;
  font-size: 0.75rem;
  color: var(--warning);
  white-space: nowrap;
`

interface InfoWrapperProps {
  width: string,
  alignCenter: boolean,
  empty: boolean,
}

const InfoWrapper = styled.div<InfoWrapperProps>`
  width: ${(props) => (props.width)};
  display: flex;
  align-items: ${(props) => (props.alignCenter ? 'center' : 'start')};

  font-size: 1rem;
  line-height: 100%;
  letter-spacing: -0.01em;
  text-align: left;
  
  @media only screen and (max-width: 1023px) {
    display: ${(props) => (props.empty ? 'none' : 'flex')};
    min-width: 100%;
    flex-direction: column;
    gap: 0.5rem;
  }
`

const InfoLabel = styled.div<{ titleWidth: string }>`
  width: ${(props) => (props.titleWidth)};
  flex-shrink: 0;

  font-weight: 700;
  color: var(--gray900);
  line-height: 150%;

  @media only screen and (max-width: 1023px) {
    align-self: start;
  }
`

const InfoContent = styled.div`
  flex-grow: 1;
  font-weight: 400;
  word-break: break-all;
  color: var(--gray700);
  line-height: 150%;
`

const InfoContentEditable = styled.input<{ isValid: boolean }>`
  flex-grow: 1;
  min-width: 0;
  width: 100%;
  height: 2.5rem;
  box-sizing: border-box;
  font-size: 1rem;
  text-overflow: ellipsis;
  color: var(--gray700);
  padding: 0.75rem 1rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  border-color: ${(props) => (props.isValid === true ? 'var(--gray300)' : '#FF3257')};
  &:focus {
    outline: none;
    border: 1px solid var(--focus-border);
  }

  &::placeholder {
    color: var(--hint);
  }
`

const Empty = styled.div`
  flex-grow: 1;
  min-width: 0;
  width: 100%;
`

ContainerInfoBox.defaultProps = {
  type: 'none',
  titleWidth: '9.375rem',
  maxColumns: 1,
  maxWidth: '100%',
  dropdown: {
    value: '',
    options: [],
  },
  datePicker: {
    value: null,
    onChangeDate: () => {},
  },
  rangePicker: {
    start: {},
    end: {},
  }
}

export default ContainerInfoBox

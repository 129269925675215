import React from 'react';
import { useParams } from 'react-router-dom'
import { BackgroundContainer } from '../components/BackgroundContainer'

const ErrorPage: React.FC = () => {
  const { errorCode } = useParams<{ errorCode: string }>()

  const isFetchBaseQueryError = (resultCode: string) => {
    return resultCode === 'FETCH_ERROR'
      || resultCode === 'PARSING_ERROR'
      || resultCode === 'TIMEOUT_ERROR'
      || resultCode === 'CUSTOM_ERROR'
  }

  return (
    <BackgroundContainer headerHeight="0rem">
      <div style={{paddingTop: '8rem', fontSize: '10rem', fontWeight:'bold'}}>{isFetchBaseQueryError(errorCode) ? '500' : errorCode}</div>
      { isFetchBaseQueryError(errorCode) && <div>{errorCode}</div> }
      {
        errorCode === '404' ?
          <>
            <div style={{fontSize: '2rem', fontWeight:'bold'}}>Page Not Found</div>
            <div>This is not the Page you're looking for.</div>
          </> :
          <>
            <div>지금은 우리 상가 광고 서비스를 사용할 수 없습니다.</div>
            <div>이용에 불편을 드려 죄송합니다. 잠시 후 다시 시도해 주세요.</div>
          </>
      }
    </BackgroundContainer>
  );
};

export default ErrorPage;

import React from 'react'
import {
  Dialog as MuiDialog,
  DialogContentText,
  DialogTitle, StyledEngineProvider
} from '@mui/material'
import styled from 'styled-components'
import ActionButton from './ActionButton'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { hideDialog } from '../../controllers/slices/dialogSlice'
import ButtonGroup from './ButtonGroup'

const Dialog = () => {
  const dialog = useAppSelector((state) => state.dialog)
  const dispatch = useAppDispatch()

  const handleClose = () => {
    if (dialog.bgClick) {
      dispatch(hideDialog())
    }
  }

  return (
    <StyledEngineProvider injectFirst>
      <DialogContainer open={dialog.show} onClose={handleClose} sx={containerSx(dialog.title)}>
        <DialogBox>
          {(() => {
            switch (dialog.templatedBody) {
              case 'multiple_access':
                return <MultiAccessWarning />
              case 'none':
              default:
                return <CustomizedBody title={dialog.title} isTitleHighlight={dialog.titleHighlight} body={dialog.body} detail={dialog.detailText} />
            }
          })()}
          <ButtonGroup justifyContent="center" maxWidth="19.25rem">
            {dialog.cancel.text && <ActionButton onClick={dialog.cancel.onClick} value={dialog.cancel.text.localized()} width="100%" height="3.125rem" bgColor="white" />}
            <ActionButton onClick={dialog.confirm.onClick} value={dialog.confirm.text.localized()} width="100%" height="3.125rem" bgColor="white" />
          </ButtonGroup>
        </DialogBox>
      </DialogContainer>
    </StyledEngineProvider>
  )
}

const containerSx =(title: string)=> 
({'.MuiDialog-container': { maxHeight: title.localized() === 'delete_user_title'.localized() ? '79.625rem' : '100vh' }})

type BodyProps = {
  title?: string,
  isTitleHighlight?: boolean,
  body?: string,
  detail?: string,
}

const CustomizedBody = ({ title, isTitleHighlight = false, body, detail }: BodyProps) => {
  return (
    <>
      { title && <DialogTitleWrapper $highlight={isTitleHighlight}>{title.localized()}</DialogTitleWrapper> }
      { body && <DialogBody>{body.localizedWithBR()}</DialogBody> }
      { detail && <DialogDetail>{detail}</DialogDetail> }
    </>
  )
}

const MultiAccessWarning = () => {
  return (
    <MultiAccessBody>
      <span style={{ fontSize: '1.625rem', fontWeight: '700' }}>알 림</span><br/><br/>
      해당 계정은 이미 <span style={{ fontWeight: '700' }}>접속 중</span>
      입니다. 접속중인 계정을 <br/>로그아웃 하시겠습니까?
    </MultiAccessBody>
  )
}

const DialogContainer = styled(MuiDialog)`
  color: var(--gray700);

  .MuiPaper-root {
    overflow: hidden;
    width:100%;
    max-height: 98vh;
    max-width: 22.5rem;
    padding: 28px 26px;
    box-sizing: border-box;
    font-family: inherit;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
  }
`

const DialogBox = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 770px){
    zoom: 0.8;
  }

`
const DialogTitleWrapper = styled(DialogTitle)<{ $highlight: boolean }>`
  padding: 1.375rem 0 1.25rem;
  font-family: inherit;
  font-weight: 700;
  font-size: 1.625rem;
  color: ${(props) => (props.$highlight ? 'var(--red)' : 'var(--gray900)')};
  
  @media (min-width: 451px) and (max-width: 535px){
    margin-top: -1.5rem;
  }

  @media only screen and (max-width: 450px){
    padding: 0 0 0.5rem;
    margin-top: -1.5rem;
  }
`

const DialogBody = styled(DialogContentText)`
  width: 100%;
  max-width: 17.3125rem;
  padding: 0 1rem 2.25rem;
  text-align: center;
  font-family: inherit;
  font-size: 1rem;
  line-height: 140%;
  letter-spacing: -0.01em;
  color: var(--gray700);


  @media (min-width: 451px) and (max-width: 535px){
    padding: 0 1rem 1.5rem;
  }
  
  @media only screen and (max-width: 450px){
    padding: 0 0 0.5rem;
  }
`

const DialogDetail = styled(DialogContentText)`
  font-family: inherit;
  text-align: center;
  line-height: 140%;
  font-size: 0.875rem;
  letter-spacing: -0.00875rem;
  color: var(--gray700);
  padding: 0 0.8rem 1.75rem;
  margin-top: -1rem;
`

const MultiAccessBody = styled(DialogContentText)`
  font-family: inherit;
  padding: 1.625rem 2.125rem 2.56rem;
  text-align: center;
  font-size: 1.125rem;
  color: var(--gray700);
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.01125rem;
`

export default Dialog

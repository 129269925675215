import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'
import { routePath } from '../../../../route'
import { ContentsWrapper } from '../../../components/ContentsWrapper'
import { useAppDispatch } from '../../../../store/hooks'
import { hideDialog, showDialog } from '../../../../controllers/slices/dialogSlice'
import { useGetAdBySiteMutation } from '../../../../controllers/services/api/ad'
import HomeButton from '../../../components/HomeButton'
import Disclaimer from '../../../components/Disclaimer'
import SearchContainer from '../main/SearchContainer'
import ApproveBoardContainer from './ApproveBoardContainer'
import { useParamSiteSno } from '../../../../hooks/useParamSiteSno'
import { useLogoutMutation } from '../../../../controllers/services/api/auth'
import { useGetSiteQuery } from '../../../../controllers/services/api/site'

type RouteState = {
  userId: string
}
const ApproveContainer = () => {
  console.log('ApproveContainer')
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { pathname, search } = useLocation()
  const location = useLocation()
  const { siteSno } = useParamSiteSno()
  const [ads, setAdData] = useState<AdInfo[]>([])
  const [siteAd, getSiteAd] = useGetAdBySiteMutation()
  const [brandName, setBrandName] = useState('')
  const [filteredAds, setFilteredAds] = useState<AdInfo[]>([])
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const currentPage = Number(searchParams.get('page')) || 1
  const searchKeyword = searchParams.get('keyword') ? decodeURIComponent(encodeURIComponent(searchParams.get('keyword') as string)) : null
  const [userLogout, userLogoutState] = useLogoutMutation()
  const userId = location.state as RouteState
  const getSite = useGetSiteQuery({ siteSno })

  useEffect(() => {
    if (!userId) {
      history.push(routePath.manage(siteSno), { replace: true })
    } else {
      siteAd({ siteSno })
    }
  }, [])

  useEffect(() => {
    if (getSite.isSuccess){
      setBrandName(getSite.data.name)
    }
  }, [getSite.isSuccess])

  useEffect(() => {
    if (searchKeyword) {
      setFilteredAds(ads.filter((ad) => ad.storeName?.includes(searchKeyword) || ad.author?.includes(searchKeyword)))
    }
  }, [ads, searchKeyword])

  useEffect(() => {
    const listen = history.listen(() => {
      if (history.action === 'POP') {
        onLogout()
        history.push(routePath.main(siteSno), { replace: true })
      }
    })

    return () => {
      listen()
    }
  }, [history])


  useEffect(() => {
    if (getSiteAd.isSuccess) {
      setAdData(getSiteAd.data)
    }
    if (getSiteAd.isError) {
      dispatch(
        showDialog({
          title: 'Error',
          titleHighlight: true,
          confirm: {
            text: 'confirm',
            onClick: () => dispatch(hideDialog())
          }
        })
      )
    }
  }, [getSiteAd.isSuccess, getSiteAd.isError])

  useEffect(() => {
    if (userLogoutState.isSuccess) {
      history.push(routePath.main(siteSno), { replace: true })
    }
  }, [userLogoutState.isLoading, userLogoutState.isSuccess])

  const goGuide = () => {
    history.push(routePath.guide(siteSno), userId)
  }

  const onPageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    searchParams.set('page', page.toString())
    history.push(`${pathname}?${searchParams.toString()}`, { replace: true })
  }

  const onLogout = () => {
    userLogout({
      userId : userId.userId
    })
  }

  return (
    <div style={{backgroundColor: 'var(--gray50)', minHeight:'100vh'}}>
      <ContentsWrapper gap='0rem' paddingTop='1.875rem'>
        <Disclaimer />
        <HomeButton marginTop={'1.25rem'} logOut={onLogout}/>
        <TitleContainer>
          <TitleText>{brandName} {'manage_office'.localized()}</TitleText>
          <SubTitleNav>
            <div className="isSelectedO" >{'ad_approve_refuse'.localized()}</div>
            <div className="isSelectedX" onClick={goGuide}>{'ad_guide'.localized()}</div>
          </SubTitleNav>
          <SearchContainerBox>
            <SearchContainer keyword={searchKeyword}/>
          </SearchContainerBox>
        </TitleContainer>
        <AdTable>
          {
            searchKeyword && filteredAds.length === 0 && getSiteAd.isSuccess ? (
              <EmptyContainer>
                <EmptyResult>'{searchKeyword}' 검색 결과가 없습니다.</EmptyResult>
              </EmptyContainer>
            ) : (
              <ApproveBoardContainer
                ads={searchKeyword ? filteredAds : ads}
                currentPage={currentPage}
                onPageChange={onPageChange}
              />
            )
          }
        </AdTable>
      </ContentsWrapper>
    </div>
  )
}

const TitleContainer = styled.div`
  width: 100%;
  font-size: 1rem;
  text-align: center;
  padding: 1rem 0 3.25rem;
  display: flex;
  flex-direction: column;
`

const TitleText = styled.div`
  width: 100%;
  color: var(--gray900);
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 100%;
  text-align: center;
`

const SubTitleNav = styled.div`
  display: flex;
  justify-content: center;
  padding: 2.25rem 0 0;
  gap: 2.44rem;
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: -0.01em;
  cursor: pointer;
  .isSelectedO{
    text-decoration-line: underline;
    height: 100%;
  }
  .isSelectedX{
    color: var(--coolgray);
    height: 100%;
  }
`

const SearchContainerBox = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  @media (min-width: 1013px) and (max-width: 1200px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 1012px) {
    justify-content: flex-start;
    margin-top: 1rem;
    margin-bottom: -1rem;
    margin-left: 0;
  }
`

const AdTable = styled.div`
  max-width: 75rem;
  width: 100%;
  font-size: 1rem;
  line-height: 100%;
  color: var(--gray900);
  text-align: center;
  margin-top: -2rem;
  margin-bottom: 3rem;
`

const EmptyContainer = styled.div`
  max-width: 75rem;
  width: 100%;
  min-height: 17rem;
`

const EmptyResult = styled.div`
  padding : 11.75rem 10rem;
`

export default ApproveContainer

import React, { useEffect } from 'react'
import BoxContentsWrapper from '../../../components/BoxContentsWrapper'
import ContainerInfoBox from '../../../components/ContainerInfoBox'
import ButtonGroup from '../../../components/ButtonGroup'
import ActionButton from '../../../components/ActionButton'
import styled from 'styled-components'
import useRangeCalendar from '../../../../hooks/useRangeCalendar'
import dayjs, { Dayjs } from 'dayjs'
import { hideDialog, showDialog } from '../../../../controllers/slices/dialogSlice'
import { useAppDispatch } from '../../../../store/hooks'
import { useDeleteAdMutation, useUpdateAdMutation } from '../../../../controllers/services/api/ad'
import { validateDateRange } from '../../../../utils/utils'
import { getAdStatus } from '../../../components/AdStatusBadge'

const ApproveContent = ({ ad } : { ad : AdInfo }) => {
  const { startDate, endDate, onChangeStartDate, onChangeEndDate } = useRangeCalendar({
    start: dayjs.unix(Number(ad.startAt)),
    end: dayjs.unix(Number(ad.finishAt))
  })
  const [updateAd, updateAdState] = useUpdateAdMutation()
  const [deleteAd, deleteAdState] = useDeleteAdMutation()
  const dispatch = useAppDispatch()

  const deleteData = () => {
    deleteAd({
      adId: ad.adId
    })
  }
  const onDelete = () => {
    dispatch(
      showDialog({
        title: 'delete_user_title',
        body: 'delete_user',
        cancel:{
          text: 'cancel',
          onClick: () => dispatch(hideDialog()),
        },
        confirm: {
          text: 'confirm',
          onClick: () => deleteData()
        },
      })
    )
  }

  const onRefuse = () => {
    updateAd({
      approvalYn: 'N',
      startAt: startDate?.toISOString(),
      finishAt: startDate?.toISOString(),
      adId: ad.adId,
      type: ad.type,
    })
  }

  const onApprove = () => {
    const validStartDate = startDate.hour(23).minute(59).second(59).millisecond(59)
    if (!validateDateRange(startDate, endDate)) {
      return
    }

    if (validStartDate.isSame(endDate) || startDate.isSame(endDate)) {
      dispatch(
        showDialog({
          title: 'Error',
          titleHighlight: true,
          body: 'approve_same_period'.localized(),
          confirm: {
            text: 'confirm'.localized(),
            onClick: () => dispatch(hideDialog())
          }
        })
      )
    } else {
      updateAd({
        approvalYn: 'Y',
        startAt: startDate?.toISOString(),
        finishAt: endDate?.toISOString(),
        adId: ad.adId,
        type: ad.type,
      })
    }
  }

  useEffect(() => {
    if(updateAdState.isSuccess || deleteAdState.isSuccess){
      location.reload()
    }
  }, [updateAdState.isSuccess, deleteAdState.isSuccess])

  return (
    <div style={{marginTop: '1.5rem', display: 'flex', flexDirection: 'column'}}>
      <PostingGuideContainer>
        <PostingGuideBox>
          <BoxContentsWrapper gap='2rem'>
            <ContainerInfoBox label={'company_name'.localized()} content={ad.storeName} maxColumns={2}/>
            <ContainerInfoBox label={'category'.localized()} content={ad.category} maxColumns={2}/>
            <ContainerInfoBox label={'name'.localized()} content={ad.author} maxColumns={2}/>
            <ContainerInfoBox label={'phone_no'.localized()} content={ad.storeContactNum} maxColumns={2}/>
            <ContainerInfoBox label={'address'.localized()} content={ad.storeAddress}/>
            <ContainerInfoBox label={'desc'.localized()} content={ad.description}/>
            <ContainerInfoBox label={'homepage'.localized()} content={ad.storeWebsiteUrl}/>
            <ContainerInfoBox label={'boarding_period'.localized()} content='' type='rangePicker' maxColumns={2}
              rangePicker={{
                start: {
                  value: startDate,
                  onChangeDate: onChangeStartDate
                },
                end: {
                  value: endDate,
                  onChangeDate: onChangeEndDate
                },
                disablePast: false
              }}/>
          </BoxContentsWrapper>
          <ImageUpload src={ad.bannerImageUrl} />
        </PostingGuideBox>
      <ButtonContainer>
        <div style={{width: '100%',display: 'flex',justifyContent: 'space-between'}}>
          <ActionButton onClick={onDelete} value={'delete'.localized()} padding='0.875rem' bgColor='white' width='6rem'/>
          <ButtonGroup>
            <ActionButton onClick={onRefuse} value={'refuse'.localized()} padding='0.875rem' bgColor='white' width='6rem'/>
            <ActionButton onClick={onApprove}
              value={getAdStatus(ad.startAt, ad.finishAt, ad.approvalYn === 'Y') ==='posting' ? 're_approve'.localized() 
              : getAdStatus(ad.startAt,ad.finishAt,ad.approvalYn === 'Y') === 'expired'? 're_approve'.localized() : 'approve'.localized()}
              padding='0.875rem' bgColor='white' width='6rem'/>
          </ButtonGroup>
        </div>
      </ButtonContainer>
    </PostingGuideContainer>
    </div>
  )
}

const PostingGuideContainer = styled.div`
  min-height: 21.5rem;
  font-size: 1rem;
  color: var(--gray900);
  display: flex;
  flex-direction: column;
  font-weight: 700;
  background: var(--white);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-top: 1rem;

  @media only screen and (max-width: 767px) {
      align-items: stretch;
  }
`

const PostingGuideBox = styled.div`
    display: flex;
    padding: 2rem 2rem 2rem;

    @media only screen and (max-width: 767px) {
        flex-direction: column-reverse;
        align-items: stretch;
    }
`;

const ImageUpload = styled.img`
  width: 18.75rem;
  height: 10rem;

  @media only screen and (max-width: 767px) {
    margin: 0 auto 1rem auto;
    max-width: 18.75rem;
    width: 100%;
    height: 7.75rem;
  }
`

const ButtonContainer = styled.div`
  max-width: 71rem;
  min-height: 2.5rem;
  display: flex;
  padding: 0 2rem 2rem 2rem;
  text-align: center;
  color: var(--gray900);
`

export default ApproveContent

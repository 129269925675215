import React, { useState } from 'react'
import { Skeleton } from '@mui/material'
import ActionButton from '../../../components/ActionButton'
import styled from 'styled-components'
import { ContentsBox } from '../../../components/ContentsBox'

type ItemProps = {
  rsc: Resource,
  onDelete: (rsc: Resource) => void,
}

const ResourceItem = ({ rsc, onDelete }: ItemProps) => {
  const [loading, setLoading] = useState(true);

  return (
    <ItemBox padding='0'>
      <ResourceImg src={rsc.url} onLoad={() => setLoading(false)} style={{ display: loading ? 'none' : 'block'}}/>
      <Skeleton variant='rectangular' animation='wave' height={200} style={{ width: '100%', display: loading ? 'block' : 'none' }}/>
      <ResourceBody>
        <ResourceTitle>
          {rsc.url}
        </ResourceTitle>
        <ActionButton onClick={() => onDelete(rsc)} value={'delete'.localized()} padding='0.75rem 1.875rem' bgColor='white'/>
      </ResourceBody>
    </ItemBox>
  )
}

const ItemBox = styled(ContentsBox)`
  width: 32%;

  @media only screen and (max-width: 767px) {
    min-width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1279px) {
    min-width: 48%;
  }
`

const ResourceImg = styled.img`
  width: 100%;
  object-fit:cover;

  border-radius: 8px 8px 0 0;
`

const ResourceTitle = styled.div`
  word-break: break-all;
`

const ResourceBody = styled.div`
  width: 100%;
  padding: 1.5rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.01em;
`

export default ResourceItem

import imageCompression from 'browser-image-compression'

export default async function reSize(file: File): Promise<File> {
  const imageFile = file
  const options = {
    maxSizeMB: 0.5, // 허용하는 최대 사이즈 500KB 지정
    maxWidthOrHeight: 300,
    useWebWorker: true // webworker 사용 여부
  }
  const compressedImg = await imageCompression(imageFile, options)
  return new File([compressedImg], compressedImg.name, { type: file.type })
}

import React from 'react'
import styled from 'styled-components'

type CheckboxProps = {
  checked: boolean,
  onChangeCheckBox: () => void
}

const Checkbox = ({ checked, onChangeCheckBox }: CheckboxProps) => (
  <CheckboxContainer>
    <HiddenCheckbox checked={checked} onChange={onChangeCheckBox}/>
    <StyledCheckbox checked={checked}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
)

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 3px;
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div<{ checked: boolean }>`
  display: inline-block;
  width: 1.125rem;
  height: 1.125rem;
  box-sizing: border-box;
  background: ${props => (props.checked ? 'var(--lg-red)' : 'var(--white)')};
  border-radius: 3px;
  border: 1px solid ${props => (props.checked ? 'var(--lg-red)' : 'var(--gray600)')};
  transition: all 150ms;

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`

export default Checkbox

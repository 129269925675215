import { createSlice } from '@reduxjs/toolkit'

type StateType = {
  id: string,
  pw: string,
  token: string,
}

const initialState: StateType = {
  id: '',
  pw: '',
  token: '',
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccount: (state, action) => {
      const { id, pw } = action.payload
      state.id = id
      state.pw = pw
    },
    setCredentials: (state, action) => {
      const { accessToken } = action.payload
      state.token = accessToken
    },
    logout: (state) => {
      console.log('### Logout!')
      state.token = ''
      state.id = ''
      state.pw = ''
    }
  }
})

export const { setAccount, setCredentials, logout } = authSlice.actions

export default authSlice.reducer

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  password: '',
  userId: '',
}

export const adminAccountSlice = createSlice({
  name: 'adminAccount',
  initialState,
  reducers: {
    setAdmin: (state, action) => {
      return { ...state, ...action.payload }
    },
    logoutAdmin: () => {
      sessionStorage.removeItem("admin_auth")
    }
  }
})

export const { setAdmin, logoutAdmin } = adminAccountSlice.actions

export default adminAccountSlice.reducer

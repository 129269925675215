import React from 'react'
import { Dialog } from '@mui/material'
import styled from 'styled-components'
import ButtonGroup from './ButtonGroup'
import ActionButton from './ActionButton'

type AddAccountDialogProps = {
  open: boolean,
  onClose: () => void,
  onSubmit: () => void,
  title: string,
  body?: string,
  children: React.ReactNode,
}

const AddAccountDialog = ({ open, onClose, onSubmit, title, body, children }: AddAccountDialogProps) => {

  return (
    <Dialog onClose={onClose} open={open} maxWidth={false} PaperProps={{ style: { width: '100%', maxWidth: '37.5rem' } }}>
      <DialogWrapper>
        <DialogTitle>{title}</DialogTitle>
        {
          body && <DialogBody>{body}</DialogBody>
        }
        <FormWrapper>
          {children}
        </FormWrapper>
        <ButtonGroup width='100%' maxWidth='25rem' padding='2.5rem 0 0' justifyContent='center'>
          <ActionButton
            onClick={onClose}
            value={'cancel'.localized()}
            width="100%"
            height="3.125rem"
            bgColor="white"
          />
          <ActionButton
            onClick={onSubmit}
            value={'register'.localized()}
            width="100%"
            height="3.125rem"
            bgColor='white'
          />
        </ButtonGroup>
      </DialogWrapper>
    </Dialog>
  )
}

const DialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.75rem;
`

const DialogTitle = styled.div`
  font-weight: 700;
  font-size: 1.875rem;
  text-align: center;
  padding-bottom: 2.5rem;
`

const DialogBody = styled.div`
  padding-bottom: 2.5rem;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 25rem;
  width: 100%;
`

export const Input = styled.input`
  height: 3.125rem;
  box-sizing: border-box;
  font-size: 1rem;
  text-overflow: ellipsis;
  color: var(--gray700);

  padding: 0 0.875rem;
  border: 1px solid var(--gray300);
  border-radius: 4px;
  
  &::placeholder {
    color: var(--hint);
  }

  &:focus {
    outline: none;
    border: 1px solid var(--focus-border);
  }
`

export const InvalidText = styled.div`
  height: 1rem;
  font-size: 0.75rem;
  color: var(--warning);
  line-height: 100%;
`

export const Empty = styled.div`
  height: 1rem;
`

export default AddAccountDialog

import styled from 'styled-components'

interface ContentsBox {
  minHeight?: string,
  padding: string
}

export const ContentsBox = styled.div<ContentsBox>`
  width: 100%;
  min-height: ${(props) => (props.minHeight)};
  display: flex;
  flex-direction: column;
  align-items: start;

  background: var(--white);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  box-sizing: border-box;
  padding: ${(props) => (props.padding)};

  color: var(--gray900);
`

ContentsBox.defaultProps = {
  minHeight: '0'
}

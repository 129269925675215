import React, { useEffect, useState } from 'react'
import ContainerInfoBox from './ContainerInfoBox'
import { useGetAllSitesQuery } from '../../controllers/services/api/site'

type SiteSelectorProps = {
  currentSite?: Site
  onChangeSite: (currentSite?: Site) => void,
}

const SiteSelector = ({ currentSite, onChangeSite }: SiteSelectorProps) => {
  const getSitesState = useGetAllSitesQuery()
  const [sites, setSites] = useState<Site[]>([])

  useEffect(() => {
    if (getSitesState.isSuccess && getSitesState.data) {
      setSites(getSitesState.data)
    }
  }, [getSitesState.isSuccess, getSitesState.isError])

  const onChange = (value: string) => {
    onChangeSite(sites.find((site) => site.name === value))
  }

  return (
    <ContainerInfoBox
      label={'site_name'.localized()}
      type="dropdown"
      dropdown={{
        value: currentSite?.name ?? 'admin_ad_selector_hint'.localized(),
        options: sites.map((site) => site.name).sort(),
        onClick: onChange
      }}
      titleWidth='5.625rem'
    />
  )
}

export default SiteSelector

import React from 'react'
import styled from 'styled-components'

type ButtonGroupProps = {
  children: React.ReactNode,
  width: string,
  padding: string,
  justifyContent: string,
  maxWidth?: string
}

type GroupStyledProps = {
  width: string,
  padding: string,
  justifyContent: string,
  maxWidth?: string
}

const ButtonGroup = ({ children, width, padding, justifyContent, maxWidth }: ButtonGroupProps) => {
  return (
    <ButtonGroupWrapper width={width} padding={padding} justifyContent={justifyContent} maxWidth={maxWidth}>
      {children}
    </ButtonGroupWrapper>
  )
}

const ButtonGroupWrapper = styled.div<GroupStyledProps>`
  width: ${(props) => props.width};
  padding: ${(props) => props.padding};
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  gap: 0.5rem;
  max-width: ${(props) => props.maxWidth};
`

ButtonGroup.defaultProps = {
  width: '100%',
  padding: '0',
  justifyContent: 'end'
}

export default ButtonGroup

import React, { useEffect, useState } from 'react'
import { BackgroundContainer } from '../../components/BackgroundContainer'
import { ContentsWrapper } from '../../components/ContentsWrapper'
import BodyTitleContainer from '../../components/BodyTitleContainer'
import { ContentsBox } from '../../components/ContentsBox'
import ContainerInfoBox from '../../components/ContainerInfoBox'
import ActionButton from '../../components/ActionButton'
import { useHistory, useParams } from 'react-router-dom'
import { routePath } from '../../../route'
import BoxContentsWrapper from '../../components/BoxContentsWrapper'
import ButtonGroup from '../../components/ButtonGroup'
import { useAppDispatch } from '../../../store/hooks'
import { hideLoading, showLoading } from '../../../controllers/slices/loadingSlice'
import { SubmitHandler, useForm } from 'react-hook-form'
import { formOptions, validateDateRange } from '../../../utils/utils'
import { useDeleteAdMutation, useGetAdMutation, useUpdateAdMutation } from '../../../controllers/services/api/ad'
import { useGetCategoriesQuery } from '../../../controllers/services/api/category'
import useRangeCalendar from '../../../hooks/useRangeCalendar'
import dayjs from 'dayjs'
import { hideDialog, showDialog } from '../../../controllers/slices/dialogSlice'
import styled from 'styled-components'

interface FormInput {
  store: string,
  name: string,
  contact: string,
  addr: string,
  dsc: string,
  web: string
}

const AdEditContainer = () => {
  console.log('AdEditContainer')
  const history = useHistory()
  const dispatch= useAppDispatch()
  const params = useParams<{adId: string}>()

  const [category, setCategory] = useState<string>()
  const [isCateValid, setIsCateValid] = useState(true)
  const [categories, setCategories] = useState<string[]>([])

  const [ad, setAd] = useState<AdInfo>()
  const { register, handleSubmit, formState: { errors }, setValue } = useForm<FormInput>()
  const { startDate, endDate, onChangeStartDate, onChangeEndDate } = useRangeCalendar()

  const [getAdInfo, getAdInfoState] = useGetAdMutation()
  const [updateAd, updateAdState] = useUpdateAdMutation()
  const getCategoriesState = useGetCategoriesQuery()
  const [deleteAd, deleteAdState] = useDeleteAdMutation()

  const onChangeCategory = (value: string) => {
    setCategory(value)
    setIsCateValid(true)
  }

  const fetchDeleteAd = () => {
    if (ad) {
      deleteAd({ adId: ad.adId })
    }
  }

  const handleClickCancel = () => {
    history.goBack()
  }

  const handleClickDelete = () => {
    dispatch(showDialog({
      title: 'notice',
      body: 'admin_ad_delete',
      cancel: {
        text: 'cancel'.localized(),
        onClick: () => dispatch(hideDialog())
      },
      confirm: {
        text: 'confirm'.localized(),
        onClick: fetchDeleteAd,
      },
    }))
  }

  const handleClickSave: SubmitHandler<FormInput> = (fieldData) => {
    if (!category) {
      setIsCateValid(false)
      return
    }

    else if (!validateDateRange(startDate, endDate)) {
      return
    }

    else if (startDate.diff(endDate, 'day') === 0) {
      dispatch(
        showDialog({
          title: 'Error',
          titleHighlight: true,
          body: 'approve_same_period'.localized(),
          confirm: {
            text: 'confirm'.localized(),
            onClick: () => dispatch(hideDialog())
          }
        })
      )
      return
    }

    updateAd({
      adId: ad!.adId,
      category: category,
      author: fieldData.name,
      description: fieldData.dsc,
      storeAddress: fieldData.addr,
      storeContactNum: fieldData.contact,
      storeName: fieldData.store,
      storeWebsiteUrl: fieldData.web,
      type: ad!.type,
      startAt: startDate.toISOString(),
      finishAt: endDate.toISOString(),
    })
  }

  useEffect(() => {
    getAdInfo(params)
  }, [])

  useEffect(() => {
    if (getAdInfoState.isSuccess) {
      setAd(getAdInfoState.data)
    }
  }, [getAdInfoState.isSuccess])

  useEffect(() => {
    updateAdState.isLoading ? dispatch(showLoading()) : dispatch(hideLoading())

    if (updateAdState.isSuccess) {
      history.push(routePath.adInfo, { replace: true })
    }
  }, [updateAdState.isSuccess, updateAdState.isLoading])

  useEffect(() => {
    if (ad) {
      setValue('store', ad.storeName ?? '')
      setValue('contact', ad.storeContactNum ?? '')
      setValue('name', ad.author ?? '')
      setValue('addr', ad.storeAddress ?? '')
      setValue('dsc', ad.description ?? '')
      setValue('web', ad.storeWebsiteUrl ?? '')
      setCategory(ad.category)
      onChangeStartDate(dayjs.unix(Number(ad.startAt)))
      onChangeEndDate(dayjs.unix(Number(ad.finishAt)))
    }
  }, [ad])

  useEffect(() => {
    if (getCategoriesState.isSuccess) {
      const cateData = getCategoriesState.data
      setCategories(cateData)
    }
  }, [getCategoriesState.isSuccess])

  useEffect(() => {
    deleteAdState.isLoading ? dispatch(showLoading()) : dispatch(hideLoading())

    if (deleteAdState.isSuccess) {
      dispatch(hideDialog())
      history.push(routePath.admin, { replace: true })
    }

    if (deleteAdState.isError) {
      dispatch(hideDialog())
    }

  }, [deleteAdState.isSuccess, deleteAdState.isLoading, deleteAdState.isError])

  return (
    <BackgroundContainer>
      <ContentsWrapper>
        <BodyTitleContainer title={'admin_ad_edit'.localized()}/>
        { ad &&
          <ContentsBox padding='1.875rem 2rem'>
            <BoxContentsWrapper>
              <ContainerInfoBox
                label={'company_name'.localized()}
                type='edit'
                register={register('store', formOptions({
                  required: true,
                  maxLength: 20,
                  typePattern: ['korean', 'alpha', 'numeric', 'specialChar'],
                }))}
                errorMessage={errors.store?.message}
                required={true}
              />
              <ContainerInfoBox
                label={'category'.localized()}
                type='dropdown'
                dropdown={{ value: category ?? 'pick_category'.localized(), options: categories, onClick: onChangeCategory }}
                errorMessage={isCateValid ? undefined : 'required_field'.localized()}
                required={true}
              />
              <ContainerInfoBox
                label={'name'.localized()}
                type="edit"
                register={register('name', formOptions({
                  required: true,
                  maxLength: 10,
                  typePattern: ['korean', 'alpha'],
                }))}
                errorMessage={errors.name?.message}
                required={true}
              />
              <ContainerInfoBox
                label={'phone_no'.localized()}
                type='edit'
                register={register('contact', formOptions({
                  required: true,
                  maxLength: 20,
                  typePattern: ['numeric'],
                }))}
                errorMessage={errors.contact?.message}
                required={true}
              />
              <ContainerInfoBox
                label={'address'.localized()}
                type='edit'
                register={register('addr', formOptions({
                  required: true,
                  maxLength: 50,
                  typePattern: ['korean', 'alpha', 'numeric', 'specialChar']
                }))}
                errorMessage={errors.addr?.message}
                required={true}
              />
              <ContainerInfoBox
                label={'desc'.localized()}
                type='edit'
                register={register('dsc', formOptions({
                  required: true,
                  maxLength: 50,
                  typePattern: ['korean', 'alpha', 'numeric'],
                }))}
                errorMessage={errors.dsc?.message}
                required={true}
              />
              <ContainerInfoBox
                label={'homepage'.localized()}
                type='edit'
                register={register('web', formOptions({
                  required: false,
                  maxLength: 100,
                  typePattern: ['korean', 'alpha', 'numeric', 'specialChar'],
                }))}
                errorMessage={errors.web?.message}
              />
              <ContainerInfoBox
                label={'boarding_period'.localized()}
                type='rangePicker'
                maxWidth='28.625rem'
                required={true}
                rangePicker={{
                  start: {
                    value: startDate,
                    onChangeDate: onChangeStartDate
                  },
                  end: {
                    value: endDate,
                    onChangeDate: onChangeEndDate
                  }
                }}
              />
            </BoxContentsWrapper>
          </ContentsBox>
        }
        <ButtonGroup padding='1.188rem 0' justifyContent='space-between'>
          <ActionButton onClick={handleClickDelete} value={'delete'.localized()}/>
          <ButtonsWrapper>
            <ActionButton onClick={handleClickCancel} value={'cancel'.localized()} bgColor='white'/>
            <ActionButton onClick={handleSubmit(handleClickSave)} value={'button_save'.localized()} bgColor='white'/>
          </ButtonsWrapper>
        </ButtonGroup>
      </ContentsWrapper>
    </BackgroundContainer>
  )
}

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`

export default AdEditContainer


import React, { useEffect } from 'react'
import styled from 'styled-components'
import { MainBannerImg, LetterIcon } from '../../../assets/images/index'
import { BackgroundContainer } from '../../components/BackgroundContainer'
import { ContentsWrapper } from '../../components/ContentsWrapper'
import { ContentsBox } from '../../components/ContentsBox'
import { Divider } from '../../components/Divider'
import ContainerInfoBox from '../../components/ContainerInfoBox'
import ContentsBoxTitle from '../../components/ContentsBoxTitle'

const AdminHomeContainer = () => {
  console.log('AdminHomeContainer')

  const handleEvent = () => {
    history.pushState(null, '', location.href)
  }

  useEffect(() => {
    handleEvent()
    window.addEventListener('popstate', handleEvent)
    return () => {
      window.removeEventListener('popstate', handleEvent)
    }
  }, [])

  return (
    <BackgroundContainer>
      <ContentsWrapper gap='2rem' paddingTop='2rem'>
        <AdminMainBanner>
          <BannerTitleWrapper>
            <BannerTitle>
              {'admin_home_banner_title'.localized()}
            </BannerTitle>
            <div style={{ fontSize: '1rem', lineHeight: '150%', marginTop: '1.5rem' }}>
              {'admin_home_banner_sub'.localizedWithBR()}
            </div>
            <div style={{ fontSize: '1rem', fontWeight: '500', marginTop: '2.125rem'}}>
              <img alt='email_icon' src={LetterIcon} style={{ paddingRight: '0.625rem' }}/>
              mhkang7@oucbe.co.kr
            </div>
          </BannerTitleWrapper>
          <img style={{ alignSelf: 'end', maxWidth: '29rem', overflow: 'hidden', marginLeft: 'auto' }} alt='MainBannerImage' src={MainBannerImg}/>
        </AdminMainBanner>
        <ContentsBox padding='2rem 1.5rem'>
          <ContentsBoxTitle title={'admin_home_feature_title'.localized()}/>
          <Divider margin='1.5rem 0'/>
          <BoxWrapper>
            <ContainerInfoBox label={'admin'.localized()} content={'admin_home_feature_admin'.localized()} titleWidth='7.5rem'/>
            <ContainerInfoBox label={'user'.localized()} content={'admin_home_feature_user'.localized()} titleWidth='7.5rem'/>
          </BoxWrapper>
        </ContentsBox>
      </ContentsWrapper>
    </BackgroundContainer>
  )
}

const AdminMainBanner = styled.div`
  width: 100%;
  min-height: 21.5rem;
  padding: 0 3.75rem;
  box-sizing: border-box;
  
  display: flex;
  flex-wrap: wrap;
  
  background: var(--bg-blue);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  @media only screen and (max-width: 767px) {
    padding: 0 2rem;
  }
`

const BannerTitleWrapper = styled.div`
  align-self: center;
  justify-self: start;
  display: flex;
  flex-direction: column;
  padding: 3.75rem 0;
  
  color: var(--white);
  text-align: left;
  letter-spacing: -0.01em;
`

const BannerTitle = styled.div`
  font-size: 1.875rem;
  font-weight: 700;
`

const BoxWrapper = styled.div`
  height: 100%;
  width: 100%;
  
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`

export default AdminHomeContainer

import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryReauth, MEMBER_API } from '../config'
import { getDataForm } from '../../../utils/utils'
import {
  deleteUserRequest,
  deleteUserResponse, getAdminsResponse, getManagersResponse,
  getUserRequest,
  getUserResponse,
  postUserRequest,
  postUserResponse,
  updateUserRequest,
  updateUserResponse
} from './types/user'

export const memberApi = createApi({
  reducerPath: 'memberApi',
  baseQuery: baseQueryReauth,
  endpoints: (builder) => ({
    postMember: builder.mutation<postUserResponse, postUserRequest>({
      query: (request) => ({
        url: MEMBER_API.ROOT,
        method: 'POST',
        body: getDataForm(request)
      })
    }),
    updateMember: builder.mutation<updateUserResponse, updateUserRequest>({
      query: (request) => ({
        url: MEMBER_API.ROOT,
        method: 'PUT',
        params: request,
      })
    }),
    deleteMember: builder.mutation<deleteUserResponse, deleteUserRequest>({
      query: (request) => ({
        url: `${MEMBER_API.ROOT}/${request.userId}`,
        method: 'DELETE',
      })
    }),
    getMember: builder.mutation<getUserResponse, getUserRequest>({
      query: (request) => ({
        url: `${MEMBER_API.ROOT}/${request.userId}`,
        method: 'GET',
      })
    }),
    getAdmins: builder.query<getAdminsResponse, void>({
      query: () => ({
        url: MEMBER_API.ADMIN,
      })
    }),
    getManagers: builder.query<getManagersResponse, void>({
      query: () => ({
        url: MEMBER_API.MANAGER,
      }),
      keepUnusedDataFor: 0
    })
  })
})

export const {
  useGetMemberMutation,
  useDeleteMemberMutation,
  usePostMemberMutation,
  useUpdateMemberMutation,
  useGetAdminsQuery,
  useGetManagersQuery,
} = memberApi

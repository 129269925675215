import React, { useEffect, useState } from 'react'
import { BackgroundContainer } from '../../../../components/BackgroundContainer'
import { ContentsWrapper } from '../../../../components/ContentsWrapper'
import Disclaimer from '../../../../components/Disclaimer'
import styled from 'styled-components'
import BoxContentsWrapper from '../../../../components/BoxContentsWrapper'
import ContainerInfoBox from '../../../../components/ContainerInfoBox'
import { SubmitHandler, useForm } from 'react-hook-form'
import ButtonGroup from '../../../../components/ButtonGroup'
import ActionButton from '../../../../components/ActionButton'
import RegisterGuideContainer from './RegisterGuideContainer'
import ImageUploadContainer from './ImageUploadContainer'
import { useHistory, useLocation } from 'react-router-dom'
import { hideLoading, showLoading } from '../../../../../controllers/slices/loadingSlice'
import { useAppDispatch } from '../../../../../store/hooks'
import { routePath } from '../../../../../route'
import { formOptions, validateDateRange } from '../../../../../utils/utils'
import { useResourceState } from '../../../../../hooks/useResourceState'
import { useGetCategoriesQuery } from '../../../../../controllers/services/api/category'
import {
  useGetAdByMemberMutation,
  useUpdateAdMutation,
  usePostNewAdMutation,
  useDeleteAdMutation
} from '../../../../../controllers/services/api/ad'
import { useGetSiteQuery } from '../../../../../controllers/services/api/site'
import HomeButton from '../../../../components/HomeButton'
import dayjs from 'dayjs'
import { hideDialog, showDialog } from '../../../../../controllers/slices/dialogSlice'
import useRangeCalendar from '../../../../../hooks/useRangeCalendar'
import { useParamSiteSno } from '../../../../../hooks/useParamSiteSno'
import {isFetchBaseQueryError, ResultStatus} from "../../../../../controllers/services/errorHandler";
import { useDeleteMemberMutation } from "../../../../../controllers/services/api/member";
import { useLogoutMutation } from '../../../../../controllers/services/api/auth'

interface FormInput {
  store: string,
  name: string,
  contact: string,
  addr: string,
  dsc: string,
  web: string
}

type RouteState = {
  userId: string
}

const RegisterAdContainer = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { siteSno } = useParamSiteSno()

  const [getAd, getAdState] = useGetAdByMemberMutation()
  const [updateAd, updateAdState] = useUpdateAdMutation()
  const [deleteAd] = useDeleteAdMutation()
  const [deleteMember] = useDeleteMemberMutation()
  const getSite = useGetSiteQuery({ siteSno })
  const getCategoriesState = useGetCategoriesQuery()
  const { postRsc } = useResourceState()

  const [imgFile, setImgFile] = useState<File | null>(null)
  const [imagePath, setImagePath] = useState<string | null>(null)
  const [imgValid, setImgValid] = useState(true)

  const [category, setCategory] = useState<string>()
  const [isCateValid, setIsCateValid] = useState(true)
  const [categories, setCategories] = useState<string[]>([])

  const { startDate, endDate, onChangeStartDate, onChangeEndDate } = useRangeCalendar()

  const [ad, setAd] = useState<AdInfo>()
  const [postAd, postAdState] = usePostNewAdMutation()
  const { state } = useLocation()
  const userId = (location.state as RouteState)
  const [userLogout] = useLogoutMutation()

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<FormInput>()

  useEffect(() => {
    const listen = history.listen(() => {
      if (history.action === 'POP') {
        userLogout({userId: userId.userId})
        navigateToMain()
      }
    })

    return () => {
      listen()
    }
  }, [history])

  useEffect(() => {
    if (!state) {
      history.push(routePath.main(), { replace: true })
    }
    else {
      getAd(userId)
    }
  }, [])

  useEffect(() => {
    getSite.isLoading ? dispatch(showLoading()) : dispatch(hideLoading())

  }, [getSite.isLoading])

  useEffect(() => {
    getAdState.isLoading ? dispatch(showLoading()) : dispatch(hideLoading())

    if (getAdState.isSuccess) {
      setAd(getAdState.data[0])
    }
  }, [getAdState.isLoading, getAdState.isSuccess, getAdState.isError])

  useEffect(() => {
    postAdState.isLoading ? dispatch(showLoading()) : dispatch(hideLoading())

    if (postAdState.isSuccess) {
      logout()
    } else {
      if (isFetchBaseQueryError(postAdState.error)) {
        switch (postAdState.error.status) {
          case ResultStatus.BadRequest:
            dispatch(
              showDialog({
                title: 'Error',
                titleHighlight: true,
                body: 'fail_register_detail',
                confirm: {
                  text: 'confirm',
                  onClick: () => dispatch(hideDialog()),
                },
              })
            )
            break
        }
      }
    }
  }, [postAdState.isLoading, postAdState.isSuccess])

  useEffect(() => {
    if (ad) {
      setValue('store', ad.storeName ?? '')
      setValue('contact', ad.storeContactNum?? '')
      setValue('name', ad.author ?? '')
      setValue('addr', ad.storeAddress ?? '')
      setValue('dsc', ad.description ?? '')
      setValue('web', ad.storeWebsiteUrl ?? '')
      setCategory(ad.category)
      setImagePath(ad.bannerImageUrl ?? null)
      onChangeStartDate(dayjs.unix(Number(ad.startAt)))
      onChangeEndDate(dayjs.unix(Number(ad.finishAt)))
    }
  }, [ad])

  useEffect(() => {
    if (getCategoriesState.isSuccess) {
      const cateData = getCategoriesState.data
      setCategories(cateData)
    }
  }, [getCategoriesState.isSuccess])

  useEffect(() => {
    updateAdState.isLoading ? dispatch(showLoading()) : dispatch(hideLoading())

    if (updateAdState.isSuccess) {
      logout()
    }
  }, [updateAdState.isLoading, updateAdState.isSuccess, updateAdState.isError])

  const onChangeCategory = (value: string) => {
    setCategory(value)
    setIsCateValid(true)
  }

  const onChangeImage = (path: string | null, file?: File) => {
    const maxSize = 1024 * 1024 * 5 // 5MB

    if (file) {
      if (file.size > maxSize) {
        dispatch(
          showDialog({
            title: 'Error',
            titleHighlight: true,
            body: 'file_limit_size',
            confirm: {
              text: 'confirm'.localized(),
              onClick: () => dispatch(hideDialog())
            }
          })
        )
        return
      }
      setImgFile(file)
    } else {
      setImgFile(null)
    }

    setImagePath(path)
    setImgValid(true)
  }

  const validSelectField = () => {
    if (!category) {
      setIsCateValid(false)
      return false
    }

    else if (!imagePath) {
      setImgValid(false)
      return false
    }

    else if (!validateDateRange(startDate, endDate)) {
      return false
    }

    else if (startDate.diff(endDate, 'day') === 0) {
      dispatch(
        showDialog({
          title: 'Error',
          titleHighlight: true,
          body: 'approve_same_period'.localized(),
          confirm: {
            text: 'confirm'.localized(),
            onClick: () => dispatch(hideDialog())
          }
        })
      )
      return false
    }

    return true
  }


  const S3_BASE_URL = process.env.REACT_APP_S3_BASE_URL!
  const uploadImageFile = async (file: File | null) => {
    if (!file) return imagePath
    return await postRsc(file)
      .then((response: any) => {
        return S3_BASE_URL + response.data.fileId
      })
  }

  const handleSubmitPost: SubmitHandler<FormInput> = async (fieldData) => {
    const uploadedImage: string | null = await uploadImageFile(imgFile)

    if (validSelectField() && uploadedImage) {
      postAd({
        type: 'GENERAL',
        author: fieldData.name,
        bannerImageUrl: uploadedImage,
        category: category!,
        description: fieldData.dsc,
        userId: (location.state as RouteState).userId,
        siteSno: siteSno,
        storeAddress: fieldData.addr,
        storeContactNum: fieldData.contact,
        storeName: fieldData.store,
        storeWebsiteUrl: fieldData.web,
        startAt: startDate.toISOString(),
        finishAt: endDate.toISOString(),
      })
    }
  }

  const handleSubmitUpdate: SubmitHandler<FormInput> = async (fieldData) => {
    const uploadedImage: string | null = await uploadImageFile(imgFile)

    if (validSelectField() && uploadedImage) {
      updateAd({
        storeName: fieldData.store,
        category: category!,
        author: fieldData.name,
        storeContactNum: fieldData.contact,
        storeAddress: fieldData.addr,
        description: fieldData.dsc,
        storeWebsiteUrl: fieldData.web,
        bannerImageUrl: uploadedImage,
        type: ad!.type,
        adId: ad!.adId,
        approvalYn: 'N',
        startAt: startDate.toISOString(),
        finishAt: endDate.toISOString(),
      })
    }
  }

  const handleDelete = () => {
    dispatch(
      showDialog({
        title: 'delete_user_title',
        body: 'delete_user',
        cancel:{
          text: 'cancel',
          onClick: () => dispatch(hideDialog()),
        },
        confirm: {
          text: 'confirm',
          onClick: () => deleteData()
        },
      })
    )
  }

  const deleteData = () => {
    if (getAdState.data && getAdState.data.length > 0) {
      deleteAd({ adId: getAdState.data[0].adId })
      dispatch(hideDialog())
      navigateToMain()
    } else {
      deleteMember({ userId: userId.userId })
      dispatch(hideDialog())
      navigateToMain()
    }
  }

  const logout = () => {
    userLogout({
      userId : userId.userId
    })
    history.push(routePath.main(siteSno), { replace: true })
  }

  const navigateToMain = () => {
    history.push(routePath.main(siteSno), { replace: true })
  }

  return (
    <BackgroundContainer headerHeight="0rem">
      <ContentsWrapper gap="0rem" paddingTop="1.875rem">
        <Disclaimer />
        <HomeButton marginTop={'1.25rem'} logOut={logout}/>
        <TitleWrapper>{'ad_register_title'.localized()}</TitleWrapper>
        <AdFormWrapper>
          <BoxContentsWrapper gap="1rem">
            <ContainerInfoBox
              label={'company_name'.localized()}
              type="edit"
              register={register('store', formOptions({
                required: true,
                maxLength: 20,
                typePattern: ['korean', 'alpha', 'numeric', 'specialChar'],
              }))}
              errorMessage={errors.store?.message}
              required={true}
            />
            <ContainerInfoBox
              label={'category'.localized()}
              type="dropdown"
              dropdown={{
                value: category ?? 'pick_category'.localized(),
                options: categories,
                onClick: onChangeCategory
              }}
              maxWidth='15.3125rem'
              errorMessage={isCateValid ? undefined : '필수 입력 항목입니다.'}
              required={true}
            />
            <ContainerInfoBox
              label={'name'.localized()}
              type="edit"
              register={register('name', formOptions({
                required: true,
                maxLength: 10,
                typePattern: ['korean', 'alpha'],
              }))}
              errorMessage={errors.name?.message}
              required={true}
            />
            <ContainerInfoBox
              label={'phone_no'.localized()}
              type="edit"
              register={register('contact', formOptions({
                required: true,
                maxLength: 20,
                typePattern: ['numeric'],
              }))}
              errorMessage={errors.contact?.message}
              required={true}
            />
            <ContainerInfoBox
              label={'address'.localized()}
              type="edit"
              register={register('addr', formOptions({
                required: true,
                maxLength: 50,
                typePattern: ['korean', 'alpha', 'numeric', 'specialChar']
              }))}
              errorMessage={errors.addr?.message}
              required={true}
            />
            <ContainerInfoBox
              label={'desc'.localized()}
              type="edit"
              register={register('dsc', formOptions({
                required: true,
                maxLength: 50,
                typePattern: ['korean', 'alpha', 'numeric'],
              }))}
              errorMessage={errors.dsc?.message}
              required={true}
            />
            <ContainerInfoBox
              label={'homepage'.localized()}
              type="edit"
              register={register('web', formOptions({
                required: false,
                maxLength: 100,
                typePattern: ['korean', 'alpha', 'numeric', 'specialChar'],
              }))}
              errorMessage={errors.web?.message}
            />
            <ContainerInfoBox
              label={'boarding_period'.localized()}
              type='rangePicker'
              maxWidth='28.625rem'
              required={true}
              rangePicker={{
                start: {
                  value: startDate,
                  onChangeDate: onChangeStartDate
                },
                end: {
                  value: endDate,
                  onChangeDate: onChangeEndDate
                }
              }}
            />
          </BoxContentsWrapper>
          <ImageUploadContainer imgPath={imagePath} onChangeImage={onChangeImage} isValid={imgValid}/>
        </AdFormWrapper>
        <ButtonGroup padding="2rem 0 1.5rem">
          <ActionButton
            onClick={handleDelete}
            value={'delete'.localized()}
            padding="0.75rem"
            bgColor="white"
            width="6.5rem"
          />
          <ActionButton
            onClick={ad ? handleSubmit(handleSubmitUpdate) : handleSubmit(handleSubmitPost)}
            value={ad ? 'edit'.localized() : 'register'.localized()}
            padding="0.75rem"
            bgColor="white"
            width="6.5rem"
          />
        </ButtonGroup>
        {getSite.isSuccess && (
          <RegisterGuideContainer
            siteName={getSite.data.name}
            guideContent={getSite.data.guideline}
          />
        )}
      </ContentsWrapper>
    </BackgroundContainer>
  )
}

const TitleWrapper = styled.div`
  padding: 0.87rem 0 3.25rem;
  color: var(--gray900);
  font-weight: 700;
  font-size: 1.75rem;
`

const AdFormWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 3rem;
  padding: 2rem;
  background: var(--white);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  box-sizing: border-box;

  @media only screen and (max-width: 1023px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`

export default RegisterAdContainer

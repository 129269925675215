import React, { Suspense, useEffect } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { routePath } from './routePath'
import RootContainer from './../view/container/RootContainer'
import MainContainer from '../view/container/main/main/MainContainer'
import ManageLoginContainer from './../view/container/main/manage/ManageLoginContainer'
import ApproveContainer from './../view/container/main/manage/ApproveContainer'
import DisclaimerContainer from './../view/container/main/manage/DisclaimerContainer'
import GuideContainer from './../view/container/main/manage/GuideContainer'
import RegisterLoginContainer from './../view/container/main/register/RegisterLoginContainer'
import TermsContainer from '../view/container/main/register/terms/TermsContainer'
import RegisterAdContainer from './../view/container/main/register/ad/RegisterAdContainer'
import SearchPassContainer from './../view/container/main/register/SearchPassContainer'
import AdminRoutes from './AdminRoutes'
import MainPreviewContainer from '../view/container/main/MainPreviewContainer'
import ChangePwContainer from '../view/container/main/ChangePwContainer'
import ErrorPage from '../view/container/ErrorPage'
import MainUseTermContainer from '../view/container/main/MainUseTermContainer'
import MainPersonalInfoContainer from '../view/container/main/MainPersonalInfoContainer'

const RouterPageView = () => {
  console.log('RouterPageView')
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          {/* main */}
          <Route exact path={routePath.root} component={RootContainer} />
          <Route exact path={routePath.main(':siteSno')} component={MainContainer} />
          <Route exact path={routePath.preview(':siteSno', ':adId')} component={MainPreviewContainer} />
          <Route exact path={routePath.useTerms(':siteSno')} component={MainUseTermContainer} />
          <Route exact path={routePath.personalInfo(':siteSno')} component={MainPersonalInfoContainer} />

          {/* manage */}
          <Route exact path={routePath.manage(':siteSno')} component={ManageLoginContainer} />
          <Route exact path={routePath.approve(':siteSno')} component={ApproveContainer} />
          <Route exact path={routePath.disclaimer()} component={DisclaimerContainer} />
          <Route exact path={routePath.guide(':siteSno')} component={GuideContainer} />

          {/* register */}
          <Route exact path={routePath.register(':siteSno')} component={RegisterLoginContainer} />
          <Route exact path={routePath.registerAd(':siteSno')} component={RegisterAdContainer} />
          <Route exact path={routePath.terms(':siteSno')} component={TermsContainer} />
          <Route exact path={routePath.searchPass(':siteSno')} component={SearchPassContainer} />
          <Route exact path={routePath.changePassword(':user', ':siteSno')} component={ChangePwContainer} />

          {/* admin */}
          <Route path={routePath.admin} component={AdminRoutes} />

          <Route path={routePath.error(':errorCode')} component={ErrorPage} />
          <Route path={'*'} component={RedirectToError}/>
        </Switch>
      </Suspense>
    </>
  )
}

function RedirectToError() {
  return <Redirect to={routePath.error('404')}/>
}

export default RouterPageView

import styled from 'styled-components'

type CalendarStyleProps = {
  visible: boolean,
  placement?: 'top' | 'bottom',
}

const getCalendarStyle = ({ visible = false, placement = 'bottom' }: CalendarStyleProps) => {

  const getPlacement = (placement: 'top' | 'bottom') => {
    switch (placement) {
      case 'top':
        return 'bottom: 2.6rem'
      case 'bottom':
      default:
        return 'top: 2.6rem'
    }
  }
  return `
    display: ${visible ? 'block' : 'none'};
    ${getPlacement(placement)};
  `
}

const getBoxBorderStyle = ({ error = false }) => {
  return `
    border: ${error ? '1px solid var(--warning)' : '1px solid var(--gray300)'};
  `
}

const Styled = {
  selectBox: styled.div`
    flex-grow: 1;
    min-width: 9.375rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 1rem;
    border-radius: 3px;
    cursor: pointer;
    line-height: 100%;
    ${getBoxBorderStyle}
  `,

  selectedLabel: styled.button`
    box-sizing: border-box;
    display: flex;
    justify-content: start;
    align-items: center;
    width: inherit;
    height: inherit;
    border: none;
    padding: 0.75rem 1rem;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: var(--white);
  `,

  calendarContainer: styled.div`
    ${getCalendarStyle};
    z-index: 99;
    box-sizing: border-box;
    position: absolute;
    background: var(--white);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.08);
    border-radius: 4px;

    padding: 0.5rem;

    @media only screen and (max-width: 767px) {
      margin: 0 auto;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: rgba(0, 0, 0, 0.2) 0 5px 5px -3px, rgba(0, 0, 0, 0.14) 0 8px 10px 1px, rgba(0, 0, 0, 0.12) 0 3px 14px 2px;
      font-size: 1rem;
    }
  `,

  calendarIcon: styled.img`
    height: 1.5rem;
    width: 1.5rem;
    margin: 0.5rem;
  `
}

export default Styled

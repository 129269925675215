import React from 'react'
import AdminNavBar from '../view/container/admin/AdminNavBar'
import { Route, useLocation } from 'react-router-dom'
import { routePath } from './index'
import AdminHomeContainer from '../view/container/admin/AdminHomeContainer'
import AdminLoginContainer from '../view/container/admin/AdminLoginContainer'
import AccountContainer from '../view/container/admin/Account/AccountContainer'
import AdInfoContainer from '../view/container/admin/AdInfo/AdInfoContainer'
import AdEditContainer from '../view/container/admin/AdEditContainer'
import AdminTermsContainer from '../view/container/admin/Terms/AdminTermsContainer'
import AuthorityContainer from '../view/container/admin/Authority/AuthorityContainer'
import AdminAdRegisterContainer from '../view/container/admin/AdminAdRegisterContainer'
import ResourceContainer from '../view/container/admin/Resource/ResourceContainer'
import AccountEditContainer from '../view/container/admin/Account/AccountEditContainer'

const AdminRoutes = () => {
  const { pathname } = useLocation()
  const hasNavbar = () => {
    return pathname !== routePath.adminLogin
  }

  return (
    <>
      {
        hasNavbar() ? (
          <>
            <AdminNavBar/>
            <Route exact path={routePath.admin} component={AdminHomeContainer} />
            <Route exact path={routePath.account} component={AccountContainer} />
            <Route exact path={routePath.accountEdit + '/:id'} component={AccountEditContainer} />
            <Route exact path={routePath.adInfo} component={AdInfoContainer} />
            <Route exact path={routePath.adEdit + '/:adId'} component={AdEditContainer} />
            <Route exact path={routePath.adminTerms} component={AdminTermsContainer} />
            <Route exact path={routePath.resource} component={ResourceContainer} />
            <Route exact path={routePath.authority} component={AuthorityContainer} />
            <Route exact path={routePath.adminAdRegister} component={AdminAdRegisterContainer} />
          </>
        ) :
        <Route exact path={routePath.adminLogin} component={AdminLoginContainer} />
      }
    </>
  )
}

export default AdminRoutes

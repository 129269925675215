import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryReauth, SITE_API } from '../config'

import {
  getAllSitesResponse,
  getSiteRequest,
  getSiteResponse,
  updateSiteRequest,
  updateSiteResponse
} from './types/site'

export const siteApi = createApi({
  reducerPath: 'siteApi',
  baseQuery: baseQueryReauth,
  endpoints: (builder) => ({
    getAllSites: builder.query<getAllSitesResponse, void>({
      query: () => SITE_API.ROOT,
    }),
    updateSite: builder.mutation<updateSiteResponse, updateSiteRequest>({
      query: (request) => ({
        url: SITE_API.ROOT,
        method: 'PUT',
        params: request,
      })
    }),
    getSite: builder.query<getSiteResponse, getSiteRequest>({
      query: (request) => `${SITE_API.ROOT}/${request.siteSno}`
    }),
  })
})

export const {
  useGetAllSitesQuery,
  useGetSiteQuery,
  useUpdateSiteMutation,
} = siteApi

import React from 'react'
import LoginContainer from '../../components/LoginContainer'
import { routePath } from '../../../route'
import { useHistory } from 'react-router-dom'
import { useAppDispatch } from '../../../store/hooks'
import { hideDialog, showDialog } from '../../../controllers/slices/dialogSlice'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import useAllowIP from '../../../hooks/useAllowIP'

const AdminLoginContainer = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { isAllowed } = useAllowIP()

  const handleClickCancel = () => {
    history.push(routePath.root, { replace: true })
  }

  const onLoginSucceed = (user: User) => {
    sessionStorage.setItem(
      "admin_auth",
      JSON.stringify({
        id: user.userId,
      })
    )
    history.push(routePath.admin, { replace: true })
  }

  const onLoginFailed = (error: FetchBaseQueryError) => {
    dispatch(
      showDialog({
        title: '알림',
        body: (error.data as Error).error,
        confirm: {
          text: 'confirm',
          onClick: () => dispatch(hideDialog()),
        },
      })
    )
  }

  const title = `
    <span style='color : var(--lg-red)'>
      ${'admin_header_title1'.localized()}
    </span>
    <span style='color : #6B6B6B'>
       ${'admin_header_title2'.localized()}
    </span>
  `

  return (
    <>
      { isAllowed &&
        <LoginContainer
          target='admin'
          title={title}
          subTitle={'admin_login_sub'.localized()}
          submitText={'login'.localized()}
          onCancel={handleClickCancel}
          onSucceed={onLoginSucceed}
          onFailed={onLoginFailed}
        />
      }
    </>
  )
}

export default AdminLoginContainer

import { BaseQueryFn, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../../store'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { setCredentials } from '../slices/authSlice'
import { ResultStatus } from './errorHandler'

const BASE_URL_V2 = process.env.REACT_APP_API_URL_V2!

export const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL_V2,
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token
    if (token) {
      headers.set('Authorization', token)
    }
    return headers
  }
})

export const baseQueryReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === ResultStatus.Forbidden) {
    const refreshResult = await baseQuery(
      { url: AUTH_API.RE_TOKEN, method: 'POST', responseHandler: (response) => response.text(), },
      api,
      extraOptions,
    )
    const refreshTokenResult = refreshResult.meta?.response?.headers?.get('Authorization')

    if (refreshTokenResult) {
      api.dispatch(setCredentials({ accessToken: refreshTokenResult }))
      result = await baseQuery(args, api, extraOptions)
    }
  }
  return result
}

export const AD_API = {
  ROOT: '/ads',
  MEMBER: '/ads/of-user-',
  SITE: '/ads/of-site-',
}

export const SITE_API = {
  ROOT: '/sites',
}

export const RESOURCE_API = {
  ROOT: '/files',
}

export const MEMBER_API = {
  ROOT: '/users',
  ADMIN: '/users/admin',
  ALL: '/users/all',
  MANAGER: '/users/manager',
}

export const NOTICE_API = {
  ROOT: '/terms',
}

export const AUTH_API = {
  ROOT: '/auth',
  FIND_PASSWORD: '/auth/reset-password',
  LOGIN: '/auth/login',
  LOGOUT: '/auth/logout',
  RE_TOKEN: '/auth/refresh-token',
  CHECK_IP: '/auth/check-accessed-ip',
  ACTIVE_ACCOUNT: '/auth/active-account'
}

export const CATEGORY_API = {
  ROOT: '/categories',
}

import { createApi } from '@reduxjs/toolkit/query/react'
import { AD_API, baseQueryReauth } from '../config'
import { getDataForm } from '../../../utils/utils'
import {
  deleteAdRequest,
  deleteAdResponse,
  getAdBySiteRequest,
  getAdBySiteResponse,
  getAdByUserRequest,
  getAdByUserResponse,
  getAdRequest,
  getAdResponse,
  getAllAdsResponse,
  postAdRequest,
  postAdResponse,
  updateAdRequest,
  updateAdResponse
} from './types/ad'

export const adApi = createApi({
  reducerPath: 'adApi',
  baseQuery: baseQueryReauth,
  endpoints: (builder) => ({
    getAllAds: builder.query<getAllAdsResponse, void>({
      query: () => AD_API.ROOT
    }),
    postNewAd: builder.mutation<postAdResponse, postAdRequest>({
      query: (request: postAdRequest) => ({
        url: AD_API.ROOT,
        method: 'POST',
        body: getDataForm(request),
      })
    }),
    updateAd: builder.mutation<updateAdResponse, updateAdRequest>({
      query: (request) => ({
        url: AD_API.ROOT,
        method: 'PUT',
        params: request,
      })
    }),
    deleteAd: builder.mutation<deleteAdResponse, deleteAdRequest>({
      query: (request) => ({
        url: `${AD_API.ROOT}/${request.adId}`,
        method: 'DELETE',
      })
    }),
    getAd: builder.mutation<getAdResponse, getAdRequest>({
      query: (request) => ({
        url : `${AD_API.ROOT}/${request.adId}`,
        method: 'GET'
      })
    }),
    getAdByMember: builder.mutation<getAdByUserResponse, getAdByUserRequest>({
      query: (request) => ({
        url: `${AD_API.MEMBER}${request.userId}`,
        method: 'GET',
      })
    }),
    getAdBySite: builder.mutation<getAdBySiteResponse, getAdBySiteRequest>({
      query: (request) => ({
        url: `${AD_API.SITE}${request.siteSno}`,
        method: 'GET',
      })
    }),
  })
})

export const {
  useDeleteAdMutation,
  useGetAdMutation,
  useGetAllAdsQuery,
  useGetAdByMemberMutation,
  usePostNewAdMutation,
  useGetAdBySiteMutation,
  useUpdateAdMutation,
} = adApi

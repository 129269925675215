import { useGetCurrentTermQuery } from '../controllers/services/api/term'
import { useEffect, useState } from 'react'
import { useAppDispatch } from '../store/hooks'
import { hideLoading, showLoading } from '../controllers/slices/loadingSlice'
import { Storage } from '../libs/StorageService'

type ChangeLog = {
  previousTermsOfUse?: string,
  newTermsOfUse?: string,
  effectiveDate?: string,
}

const useRenewalTerms = () => {
  const { data, isSuccess, isLoading, isError } = useGetCurrentTermQuery()
  const dispatch = useAppDispatch()
  const [changeLog, setChangeLog] = useState<ChangeLog>()
  const [showTermsNotice, setShowTermsNotice] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    isLoading ? dispatch(showLoading()) : dispatch(hideLoading())

    if (isSuccess) {
      if (data?.termsOfUseChangeLog) {
        const storageValue = Storage.localStorage('terms-renewal')
        if (!(storageValue && storageValue === data.termsId)) {
          setChangeLog({ ...data.termsOfUseChangeLog })
          setShowTermsNotice(true)
        }
      }
    }

  }, [isSuccess, isLoading, isError])

  const onCloseDialog = () => {
    setShowTermsNotice(false)
  }

  const onCheckBox = () => {
    Storage.localStorage('terms-renewal', data?.termsId)
    setIsChecked(!isChecked)
    onCloseDialog()
  }

  return { changeLog, showTermsNotice, isChecked, onCloseDialog, onCheckBox }
}

export default useRenewalTerms

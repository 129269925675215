import React, { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { usePostMemberMutation } from '../../../../controllers/services/api/member'
import AccountAddDialog, {
  Empty,
  Input,
  InvalidText
} from '../../../components/AccountAddDialog'
import { formOptions } from '../../../../utils/utils'
import { useAppDispatch } from '../../../../store/hooks'
import { hideDialog, showDialog } from '../../../../controllers/slices/dialogSlice'

type AddManagerDialogProps = {
  open: boolean,
  onClose: () => void,
  site?: Site,
}

interface FormInput {
  id: string,
  pw: string,
}

const AddManagerDialog = ({ open, onClose, site }: AddManagerDialogProps) => {
  const dispatch = useAppDispatch()
  const { register, handleSubmit, formState: { errors } } = useForm<FormInput>()
  const [signUp, { isSuccess, isError, isLoading }] = usePostMemberMutation()

  useEffect(() => {
    if (isSuccess) {
      onClose()
      dispatch(showDialog({
        title: 'notice',
        body: 'admin_account_add_msg',
        confirm: {
          text: 'confirm',
          onClick: () => dispatch(hideDialog())
        },
      }))
    }

    if (isError) {
      alert('admin_account_add_failed'.localized())
    }
  }, [isSuccess, isError, isLoading])

  const onClickDone: SubmitHandler<FormInput> = (fieldData) => {
    if (fieldData.id && fieldData.pw) {
      signUp({
        password: fieldData.pw,
        role: 'MANAGER',
        username: fieldData.id,
        siteSno: site!.siteSno
      })
    } else {
      dispatch(showDialog({
        title: 'notice',
        body: 'admin_account_add_empty',
        confirm: {
          text: 'confirm',
          onClick: () => dispatch(hideDialog())
        },
      }))
    }
  }

  return (
    <AccountAddDialog
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit(onClickDone)}
      title={'admin_account_add_title'.localized()}
      body={'admin_account_add_description'.localized(site?.name)}
    >
      <>
        <Input
          type="text"
          placeholder={'input_id'.localized()}
          {...register('id', formOptions({ required: false, typePattern: ['alpha', 'numeric'], maxLength: 20 }))}
        />
        {errors.id ? <InvalidText>{errors.id.message}</InvalidText> : <Empty/>}
        <Input
          type="password"
          placeholder={'input_password'.localized()}
          {...register('pw', formOptions({ required: false, typePattern: ['alpha', 'numeric', 'specialChar'], maxLength: 20 }))}
        />
        {errors.pw ? <InvalidText>{errors.pw.message}</InvalidText> : <Empty/>}
      </>
    </AccountAddDialog>
  )
}

export default AddManagerDialog

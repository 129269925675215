import React, { useEffect } from 'react'
import { routePath } from '../../../../route'
import { useHistory } from 'react-router-dom'
import LoginContainer from '../../../components/LoginContainer'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { hideLoading, showLoading } from '../../../../controllers/slices/loadingSlice'
import { hideDialog, showDialog } from '../../../../controllers/slices/dialogSlice'
import { useLoginMutation } from '../../../../controllers/services/api/auth'
import { ResultStatus } from '../../../../controllers/services/errorHandler'
import { usePostMemberMutation } from '../../../../controllers/services/api/member'
import { useParamSiteSno } from '../../../../hooks/useParamSiteSno'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

const RegisterLoginContainer = () => {
  console.log('RegisterLoginContainer')
  const history = useHistory()
  const { siteSno } = useParamSiteSno()
  const dispatch = useAppDispatch()
  const [userLogin, userLoginState] = useLoginMutation()
  const [userSignUp, userSignUpState] = usePostMemberMutation()
  const { id, pw } = useAppSelector((state) => state.auth)


  const handleClickCancel = () => {
    history.push(routePath.main(siteSno), { replace: true })
  }

  const onLoginSucceed = (user: User) => {
    if (user.termsApprovedYn === 'Y') {
      history.push(routePath.registerAd(siteSno), { userId: user.userId })
    } else {
      history.push(routePath.terms(siteSno), { userId: user.userId, userRole: user.role })
    }
  }

  const onLoginFailed = (error: FetchBaseQueryError) => {
    switch (error.status) {
      case ResultStatus.NotFound:
      case ResultStatus.BadRequest:
        if((error.data as Error).error === '존재하지 않는 이메일입니다.'){
          userSignUp({
            email: id,
            password: pw,
            role: 'USER',
            siteSno,
          })
        } else {
          dispatch(
            showDialog({
              title: 'notice',
              body: (error.data as Error).error,
              confirm: {
                text: 'confirm',
                onClick: () => dispatch(hideDialog()),
              },
            })
          )
        }
        break
    }
  }

  useEffect(() => {
    userSignUpState.isLoading ? dispatch(showLoading()) : dispatch(hideLoading())

    if (userSignUpState.isError) {
      dispatch(
        showDialog({
          title: 'failed_signUp'.localized(),
          titleHighlight: true,
          body: 'failed_signUp_desc'.localized(),
          detailText: 'failed_signUp_detail'.localized(),
          confirm: {
            text: 'confirm',
            onClick: () => dispatch(hideDialog()),
          },
        })
      )
    }

    if (userSignUpState.isSuccess) {
      userLogin({ email: id, password: pw, siteSno: siteSno })
    }

  }, [userSignUpState.isLoading, userSignUpState.isError, userSignUpState.isSuccess]);

  useEffect(() => {
    userLoginState.isLoading ? dispatch(showLoading()) : dispatch(hideLoading())

    if (userLoginState.isSuccess) {
      sessionStorage.setItem(
        "session_auth",
        JSON.stringify({
          time: userLoginState.data.accessTokenExpiration
        })
      )
      history.push(routePath.terms(siteSno), userLoginState.data)
    }
  }, [userLoginState.isLoading, userLoginState.isSuccess])

  return (
    <LoginContainer
      target='user'
      title={'ad_register_login'.localized()}
      subTitle={'ad_register_login_detail'.localized()}
      submitText={'login'.localized()}
      styleOptions={{ hasFindBtn: true }}
      onCancel={handleClickCancel}
      onSucceed={onLoginSucceed}
      onFailed={onLoginFailed}
    />
  )
}

export default RegisterLoginContainer

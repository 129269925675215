import React, { useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { routePath } from '../../../route'
import { SubmitHandler, useForm } from 'react-hook-form'
import ButtonGroup from '../../components/ButtonGroup'
import ActionButton from '../../components/ActionButton'
import { formOptions } from '../../../utils/utils'
import styled from 'styled-components'
import { useUpdateMemberMutation } from '../../../controllers/services/api/member'
import { hideLoading, showLoading } from '../../../controllers/slices/loadingSlice'
import { useAppDispatch } from '../../../store/hooks'
import { hideDialog, showDialog } from '../../../controllers/slices/dialogSlice'
import { useLoginMutation, useLogoutMutation } from '../../../controllers/services/api/auth'
import ContainerInfoBox from '../../components/ContainerInfoBox'
import LoginCardWrapper from '../../components/LoginCardWrapper'
import { isFetchBaseQueryError } from '../../../controllers/services/errorHandler'
interface FormInput {
  temp: string
  password: string
  check: string
}

type RouteParams = {
  user: string,
  siteSno: string,
}

type RouteState = {
  userId?: string,
  backPath: string,
}

const ChangePwContainer = () => {
  const params = useParams<RouteParams>()
  const location = useLocation()
  const user = decodeURI(params.user)
  const history = useHistory()
  const dispatch = useAppDispatch()
  const [updateMember, updateMemberState] = useUpdateMemberMutation()
  const [auth, authState] = useLoginMutation()
  const [unAuth, unAuthState] = useLogoutMutation()
  const { userId, backPath } = location.state as RouteState

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
  } = useForm<FormInput>()

  useEffect(() => {
    if (!user) {
      history.replace(routePath.main())
    }
  }, [])

  useEffect(() => {
    authState.isLoading ? dispatch(showLoading()) : dispatch(hideLoading())

    if (authState.isSuccess) {
      onUpdatePassword(authState.data['userId'], getValues('password'))
    }

    if (authState.isError) {
      if (isFetchBaseQueryError(authState.error)) {
        if (((authState.error.data as Error).error.split(' ')[0] === "해당")) {
          const userId = (authState.error.data as Error).error.split(/'/)[1]
          unAuth({ userId }).then(showFailedDialog)
        } else {
          dispatch(
            showDialog({
              title: 'notice',
              body: '임시 비밀번호가 틀립니다.',
              confirm: {
                text: 'confirm',
                onClick: () => dispatch(hideDialog()),
              },
            })
          )
        }
      } else {
        showFailedDialog()
      }
    }

  }, [authState.isLoading, authState.isSuccess, authState.isError])

  useEffect(() => {
    unAuthState.isLoading ? dispatch(showLoading()) : dispatch(hideLoading())

    if (unAuthState.isError) {
      showFailedDialog()
    }
  }, [unAuthState.isLoading, unAuthState.isError])

  useEffect(() => {
    updateMemberState.isLoading ? dispatch(showLoading()) : dispatch(hideLoading())

    if (updateMemberState.isSuccess) {
      unAuth({ userId: updateMemberState.data.userId })
        .then(() => {
          dispatch(
            showDialog({
              title: 'notice',
              body: '비밀번호가 변경되었습니다.',
              confirm: {
                text: 'confirm',
                onClick: () => {
                  dispatch(hideDialog())
                  history.push(backPath, { replace: true })
                }
              }
            })
          )
        })
    }

    if (updateMemberState.isError) {
      showFailedDialog()
    }
  }, [updateMemberState.isSuccess, updateMemberState.isError, updateMemberState.isLoading])

  const showFailedDialog = () => {
    dispatch(
      showDialog({
        title: 'Error',
        titleHighlight: true,
        body: '현재 계정이 사용중입니다. \n비밀번호를 변경할 수 없습니다.',
        confirm: {
          text: 'confirm',
          onClick: () => dispatch(hideDialog()),
        },
      })
    )
  }

  const handleClickCancel = () => {
    history.push(backPath)
  }

  const handleSubmitUpdate: SubmitHandler<FormInput> = async (fieldData) => {
    if (user === fieldData.password) {
      setError('password', {message: 'ID와 비밀번호는 같은 값을 사용할 수 없습니다.'})
      return
    }

    if (fieldData.password !== fieldData.check) {
      dispatch(
        showDialog({
          title: 'Error',
          titleHighlight: true,
          body: '비밀번호가 일치하지 않습니다.\n다시 입력해 주세요.',
          confirm: {
            text: 'confirm',
            onClick: () => dispatch(hideDialog()),
          },
        })
      )
      return
    }

    if (userId) {
      onUpdatePassword(userId, fieldData.password)
    } else {
      auth({ email: user, password: fieldData.temp, siteSno: params.siteSno })
    }
  }

  const onUpdatePassword = (userId: string, password: string) => {
    updateMember({ userId, password })
  }

  return (
    <LoginCardWrapper>
      <Title>{'change_password'.localized()}</Title>
      <BodyWrapper>
        {
          !userId &&
          <ContainerInfoBox
            label={'temp_password'.localized()}
            type="edit"
            inputType="password"
            register={register(
              'temp',
              formOptions({
                required: !userId,
                maxLength: 20,
                typePattern: ['alpha', 'numeric', 'specialChar']
              })
            )}
            errorMessage={errors.temp?.message}
          />
        }
        <ContainerInfoBox
          label={'new_password'.localized()}
          type="edit"
          inputType="password"
          register={register(
            'password',
            formOptions({
              required: true,
              maxLength: 20,
              typePattern: 'password',
            })
          )}
          errorMessage={errors.password?.message}
        />
        <ContainerInfoBox
          label={'check_password'.localized()}
          placeholder={'check_password_placeholder'.localized()}
          type="edit"
          inputType="password"
          register={register('check', {
            ...formOptions({
              required: true,
            })
          })}
          errorMessage={errors.check?.message}
        />
      </BodyWrapper>
      <ButtonGroup
        maxWidth="25rem"
        width="100%"
        padding="2.75rem 0 0"
        justifyContent="center"
      >
        <ActionButton
          onClick={handleClickCancel}
          value={'cancel'.localized()}
          width="100%"
          padding="1rem"
        />
        <ActionButton
          onClick={handleSubmit(handleSubmitUpdate)}
          value={'change_password'.localized()}
          width="100%"
          padding="1rem"
        />
      </ButtonGroup>
    </LoginCardWrapper>
  )
}

const Title = styled.div`
  font-weight: 700;
  font-size: 1.875rem;
  padding-bottom: 3.13rem;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 28.125rem;
  gap: 1rem;
`

export default ChangePwContainer

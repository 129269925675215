import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ContentsWrapper } from '../../../../components/ContentsWrapper'
import ButtonGroup from '../../../../components/ButtonGroup'
import ActionButton from '../../../../components/ActionButton'
import { hideDialog, showDialog } from '../../../../../controllers/slices/dialogSlice'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { hideLoading, showLoading } from '../../../../../controllers/slices/loadingSlice'
import { useGetCurrentTermQuery } from '../../../../../controllers/services/api/term'
import { routePath } from '../../../../../route'
import { BackgroundContainer } from '../../../../components/BackgroundContainer'
import Disclaimer from '../../../../components/Disclaimer'
import { Divider } from '../../../../components/Divider'
import TermHeader from './TermHeader'
import Term from './Term'
import HomeButton from '../../../../components/HomeButton'
import { useParamSiteSno } from '../../../../../hooks/useParamSiteSno'
import { useUpdateMemberMutation } from '../../../../../controllers/services/api/member'
import { useLogoutMutation } from '../../../../../controllers/services/api/auth'
import PersonalInfoTermHeader from './PersonalInfoTermHeader'

type CheckState = {
  termsOfUse: boolean,
  termsOfUseAgree: boolean
}

type RouteState = {
  userId: string,
  userRole: string
}

type TermType = 'termsOfUse' | 'termsOfUseAgree'

const TermsContainer = () => {
  console.log('TermsContainer')
  const history = useHistory()
  const { siteSno } = useParamSiteSno()
  const dispatch = useDispatch()
  const location = useLocation()
  const [allCheck, setAllCheck] = useState(false)
  const [check, setCheck] = useState<CheckState>({
    termsOfUse: false,
    termsOfUseAgree: false
  })
  const [terms, setTerms] = useState<Terms>({
    disclaimer: '',
    effectiveDate: '',
    termsOfConsent: '',
    termsOfManagerConsent: '',
    termsOfManagerPrivacyPolicy: '',
    termsOfPrivacyPolicy: '',
    termsOfUse: '',
    termsId: '',
  })
  const userId = (location.state as RouteState)
  const userRole = (location.state as RouteState)
  const [updateUser,{ isSuccess }] = useUpdateMemberMutation()
  const getNotice = useGetCurrentTermQuery()
  const [userLogout] = useLogoutMutation()
  const [role, setRole] = useState('')

  useEffect(() => {
    if (!userId) {
      history.push(routePath.register(siteSno), { replace: true })
    }
    setRole(userRole.userRole)
  }, [])

  useEffect(() => {
    if (check.termsOfUse && check.termsOfUseAgree) {
      setAllCheck(true)
    } else {
      setAllCheck(false)
    }
  }, [check])

  useEffect(() => {
    const listen = history.listen(() => {
      if (history.action === 'POP') {
        userLogout({userId: userId.userId})
        navigateToMain()
      }
    })

    return () => {
      listen()
    }
  }, [history])

  useEffect(() => {
    getNotice.isLoading ? dispatch(showLoading()) : dispatch(hideLoading())

    if (getNotice.isSuccess) {
      if (getNotice.data) {
        setTerms(getNotice.data)
      }
    }
  }, [getNotice.isLoading, getNotice.isSuccess])

  const onCancel = () => {
    history.goBack()
  }

  const onConfirm = () => {
    if (!allCheck) {
      dispatch(
        showDialog({
          title: 'terms_title_agree',
          body: 'agree_all_term',
          confirm: {
            text: 'confirm',
            onClick: () => dispatch(hideDialog())
          },
        })
      )
    } else {
      updateUser({
        userId: userId.userId,
        termsApprovedYn: 'Y',
      })
    }
  }

  useEffect(() => {
    if (isSuccess) {
      if (role !== 'MANAGER'){
        history.push(routePath.registerAd(siteSno) , userId)
      } else {
        history.push(routePath.approve(siteSno), userId)
      }
    }
  }, [isSuccess])

  const onChangeAllCheck = () => {
    setAllCheck(!allCheck)
    setCheck({
      termsOfUse: !allCheck,
      termsOfUseAgree: !allCheck,
    })
  }

  const navigateToMain = () => {
    userLogout({userId: userId.userId})
      .then(() => {
        history.push(routePath.main(siteSno), { replace: true })
      })
  }

  const onChangeCheck = (value: TermType) => {
    setCheck({
      ...check,
      [value]: !check[value],
    })
  }

  return (
    <BackgroundContainer headerHeight='0rem'>
      <ContentsWrapper gap="0rem" paddingTop="1.875rem">
        <Disclaimer />
        <HomeButton marginTop={'1.25rem'} logOut={navigateToMain}/>
        <TermsTitle>{role !== "MANAGER" ? 'terms_title'.localized() : 'terms_title_manager'.localized()}</TermsTitle>
        <TermHeader
          title={'terms_all_agree'.localized()}
          description={'terms_agree_desc'.localized()}
          isChecked={allCheck}
          onChangeCheck={onChangeAllCheck}
          checkBoxLabel={'terms_complete_agreement'.localized()}
        />
        <Divider margin='1rem 0 0'/>

        <Term
          title={'terms_object'.localized()}
          termContent={terms.termsOfUse ? terms.termsOfUse : 'terms_no_data'.localized()}
          isChecked={check.termsOfUse}
          onChangeCheck={() => onChangeCheck('termsOfUse')}
        />

        <Term
          title={'terms_personal_info_agree'.localized()}
          termContent={role !== "MANAGER" ? terms.termsOfConsent ? terms.termsOfConsent : 'terms_no_data'.localized()
            : terms.termsOfManagerConsent ? terms.termsOfManagerConsent : 'terms_no_data'.localized()}
          isChecked={check.termsOfUseAgree}
          onChangeCheck={() => onChangeCheck('termsOfUseAgree')}
        />

        <TermWrapper>
          <PersonalInfoTermHeader
            title={'terms_personal_info_management'.localized()}
          />
          <DetailTermContainer>{role !== "MANAGER" ? terms.termsOfPrivacyPolicy ? terms.termsOfPrivacyPolicy : 'terms_no_data'.localized()
            : terms.termsOfManagerPrivacyPolicy ? terms.termsOfManagerPrivacyPolicy : 'terms_no_data'.localized()}</DetailTermContainer>
        </TermWrapper>

        <ButtonGroup padding='2.25rem 0'>
          <ActionButton onClick={onCancel} value={'cancel'.localized()} padding='0.875rem' bgColor='white' width='6rem'/>
          <ActionButton onClick={onConfirm} value={'confirm'.localized()} padding='0.875rem' bgColor='white' width='6rem'/>
        </ButtonGroup>
      </ContentsWrapper>
    </BackgroundContainer>
  )
}

const TermsTitle = styled.div`
  padding: 0.87rem 0 4rem;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 100%;
`

const TermWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 2.25rem;
`

const DetailTermContainer = styled.div`
  height: 13rem;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.08);
  border-radius: 0.5rem;
  text-align: left;
  padding: 1.5rem 1.75rem;
  box-sizing: border-box;
  overflow-y: scroll;
  color: var(--gray700);
  white-space: pre-wrap;
  
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #d9d9d9;
  }
`

export default TermsContainer

import { useAppDispatch } from '../store/hooks'
import { hideDialog, showDialog } from '../controllers/slices/dialogSlice'

type PopUpType = 'notice' | 'error'

export const useNoticePopUp = () => {
  const dispatch = useAppDispatch()

  const showPopUp = (text: string, type: PopUpType = 'notice') => {
    dispatch(
      showDialog({
        title: type,
        body: text,
        confirm: {
          text: 'confirm',
          onClick: () => dispatch(hideDialog())
        }
      })
    )
  }

  return { showPopUp }
}

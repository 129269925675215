import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { routePath } from '../../route'
import { Move, Home } from './../../assets/images/index'
import Styled from './RootContainer.styled'
import { useAppDispatch } from '../../store/hooks'
import { hideDialog, showDialog } from '../../controllers/slices/dialogSlice'
import { Storage } from '../../libs/StorageService'
import { useGetAllSitesQuery } from '../../controllers/services/api/site'

const RootContainer = () => {
  console.log('RootContainer')
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { data, isSuccess, isError, error } = useGetAllSitesQuery()
  const [sites, setSites] = useState<Site[]>()

  const onClickSite = (siteSno: string, siteName: string) => {
    history.push(routePath.main(siteSno), { replace: true })
    Storage.localStorage('brand_name', siteName)
  }

  useEffect(() => {
    if (isSuccess) {
      setSites(data)
    }

    if (isError) {
      if ('status' in error) {
        dispatch(
          showDialog({
            title: error.status,
            body: error.data,
            confirm: {
              text: 'confirm',
              onClick: () => dispatch(hideDialog())
            }
          })
        )
      }
    }
  }, [isSuccess, isError])

  return (
    <Styled.Background>
      <Styled.RootWrapper>
        <Styled.RootTitleContainer>
          <div>{'home_title'.localized()}</div>
          <div>{'home_subject'.localizedWithBR()}</div>
          <div>{'home_desc'.localizedWithBR()}</div>
        </Styled.RootTitleContainer>
        <Styled.SelectorContainer>
          <Styled.SitesListContainer>
            { isSuccess && sites &&
              sites.map((site) => (
              <Styled.SiteItem key={site.siteSno} onClick={() => onClickSite(site.siteSno ?? 'unknown', site.name ?? 'unknown')}>
                <div>{site.name}</div>
                <img className='icon' alt='nextIcon' src={Move} />
              </Styled.SiteItem>
            ))}
          </Styled.SitesListContainer>
          <div className="helper">{'home_text'.localized()}</div>
        </Styled.SelectorContainer>
      </Styled.RootWrapper>
      <Styled.BottomImg alt="bottomImg" src={Home} />
    </Styled.Background>
  )
}

export default RootContainer

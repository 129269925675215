const root: string = '/'
const admin: string = '/home-ad'

const mainPath = {
  main: 'main',
  mainPreview: '/preview',
  manageLogin: '/manage-login',
  approve: '/approve',
  disclaimer: '/disclaimer',
  guide: '/guide',
  terms: '/terms',
  useTerms: '/use-terms',
  personalInfo: '/personal-info-terms'
}

const registerPath = {
  register: '/register',
  advertisement: '/ad',
  searchPass: '/search-pass',
  changePassword: '/reset-password',
}

const adminPath = {
  account: '/account',
  accountEdit: '/account/edit',
  adInfo: '/ad-info',
  adEdit: '/ad-edit',
  adminTerms: '/terms',
  resource: '/resource',
  tools: '/tools',
  authority: '/auth',
  adRegister: '/ad-register',
  login: '/login',
}

export const routePath = {
  root: root,
  error: (code?: string) => `/error/${code}`,

  // main
  main: (siteSno?: string) => `${root}${mainPath.main}/${siteSno}`,
  preview: (siteSno: string, adId: string) => `/${mainPath.main}/${siteSno}/${adId}${mainPath.mainPreview}`,
  useTerms: (siteSno: string) => `/${mainPath.main}/${siteSno}${mainPath.useTerms}`,
  personalInfo : (siteSno: string) => `/${mainPath.main}/${siteSno}${mainPath.personalInfo}`,
  // manage
  manage: (siteSno: string) => `/${mainPath.main}/${siteSno}${mainPath.manageLogin}`,                                                             // 관리사무소 로그인
  approve: (siteSno: string) => `/${mainPath.main}/${siteSno}${mainPath.approve}`,                                                                // 승인 및 거절
  disclaimer: () => root + mainPath.main + mainPath.disclaimer,                                   // 디스클레이머
  guide: (siteSno: string) => `/${mainPath.main}/${siteSno}${mainPath.guide}`,                                                                    // 등록 가이드
  terms: (siteSno: string) => `/${mainPath.main}/${siteSno}${mainPath.terms}`,                // 이용 약관

  // register
  register: (siteSno: string) => `/${mainPath.main}/${siteSno}${registerPath.register}`,                                                          // 등록자 로그인
  registerAd: (siteSno: string) => `/${mainPath.main}/${siteSno}${registerPath.register}${registerPath.advertisement}`,                                                   // 광고 등록하기
  searchPass: (siteSno: string) => `/${mainPath.main}/${siteSno}${registerPath.register}${registerPath.searchPass}`,      // 비밀번호 찾기
  changePassword: (user: string, siteSno: string) => `/${siteSno}${registerPath.changePassword}/${user}`,

  // admin
  admin: admin,                                                                             // Admin Home
  adminLogin: admin + adminPath.login,                                                      // Admin Login
  account: admin + adminPath.account,                                                       // 계정 관리
  accountEdit: admin + adminPath.accountEdit,                                               // 계정 편집
  adInfo: admin + adminPath.adInfo,                                                         // 광고 정보
  adEdit: admin + adminPath.adEdit,                                                         // 광고 편집
  adminTerms: admin + adminPath.adminTerms,                                                 // 이용 약관
  resource: admin + adminPath.resource,                                                     // 리소스
  tools: admin + adminPath.tools,                                                           // 검증도구
  authority: admin + adminPath.authority,                                                   // 권한
  adminAdRegister: admin + adminPath.adRegister,                                            // 광고 등록(LG)
}

import React from 'react'
import styled from 'styled-components'

type BoxContentsWrapperProps = {
  children: React.ReactNode,
  gap: string
}

const BoxContentsWrapper = ({ children, gap }: BoxContentsWrapperProps) => {
  return (
    <Wrapper gap={gap}>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div<{gap: string}>`
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${(props) => props.gap};
`

BoxContentsWrapper.defaultProps = {
  gap: '1rem',
}

export default BoxContentsWrapper

export const adminAuthority = [
  'admin',
  'user',
]

export const Authorities = {
  'LEVEL0': 'admin',
  'LEVEL1': 'user',
} as const

export type AccessLevel = 'LEVEL0' | 'LEVEL1'

import React from 'react'
import styled from 'styled-components'

const BodyTitleContainer = ({ title }: { title : string }) => {
  return (
    <ContainerTitle>
      {title}
    </ContainerTitle>
  )
}

const ContainerTitle = styled.div`
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 100%;
  letter-spacing: -0.01em;

  color: var(--gray900);
  
  padding: 2.5rem 0;
  margin-right: auto;
`

export default BodyTitleContainer

import React, { KeyboardEvent, useState } from 'react'
import styled from 'styled-components'
import { SearchIcon } from '../../../../assets/images'
import { useHistory, useLocation } from 'react-router-dom'
import { getInputPattern } from '../../../../utils/utils'

type SearchProps = {
  keyword: string | null,
}

const SearchContainer = ({ keyword }: SearchProps) => {
  const history = useHistory()
  const { pathname } = useLocation()
  const [input, setInput] = useState(keyword ?? '')
  const [error, setError] = useState<string | null>(null)

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value)
    const inputPattern = getInputPattern(['korean', 'alpha', 'numeric', 'specialChar'], 20)
    setError(inputPattern.value.test(e.target.value) ? null : inputPattern.message)
  }

  const onPressEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch()
    }
  }

  const onSearch = () => {
    if (!error) {
      history.push(`${pathname}?page=1&keyword=${encodeURIComponent(input)}`, { replace: true })
    }
  }

  return (
    <SearchWrapper>
      <SearchField
        placeholder="Search"
        onChange={onChangeInput}
        onKeyUp={onPressEnter}
        value={input}
      />
      <IconImg src={SearchIcon} onClick={onSearch} />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </SearchWrapper>
  )
}

const SearchWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;

  background-color: var(--white);
  border: 1px solid var(--gray300);
  border-radius: 3px
`

const SearchField = styled.input`
  width: 13.25rem;
  border-style: none;
  padding: 0.5rem 1rem;
  box-sizing: border-box;

  font-size: 1rem;
  line-height: 100%;
  color: var(--gray900);

  &::placeholder {
    color: var(--gray500);
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 16.25rem;
  }
`

const IconImg = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.5rem 0.75rem;
  
  cursor: pointer;
`

const ErrorMessage = styled.div`
  white-space: nowrap;
  font-size: 0.75rem;
  position: absolute;
  bottom: -1rem;
  color: var(--warning);
`

export default SearchContainer

import React, { useEffect } from 'react'
import { BackgroundContainer } from '../../../components/BackgroundContainer'
import { ContentsWrapper } from '../../../components/ContentsWrapper'
import BodyTitleContainer from '../../../components/BodyTitleContainer'
import { ContentsBox } from '../../../components/ContentsBox'
import ContentsBoxTitle from '../../../components/ContentsBoxTitle'
import { Divider } from '../../../components/Divider'
import BoxContentsWrapper from '../../../components/BoxContentsWrapper'
import ContainerInfoBox from '../../../components/ContainerInfoBox'
import ButtonGroup from '../../../components/ButtonGroup'
import ActionButton from '../../../components/ActionButton'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useAppDispatch } from '../../../../store/hooks'
import { formOptions } from '../../../../utils/utils'
import { useUpdateMemberMutation } from '../../../../controllers/services/api/member'
import { routePath } from '../../../../route'
import { hideDialog, showDialog } from '../../../../controllers/slices/dialogSlice'
import { isFetchBaseQueryError } from '../../../../controllers/services/errorHandler'

interface FormInput {
  username: string,
  password: string,
  managerName: string,
  managerContact: string,
  managerEmail: string
}

const AccountEditContainer = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const params = useParams<{id: string}>()
  const { register, handleSubmit, formState: { errors }, setValue } = useForm<FormInput>()
  const memberState = useLocation().state as User
  const [updateManager, { isSuccess, isError, error }] = useUpdateMemberMutation()

  useEffect(() => {
    if (!memberState) {
      history.goBack()
    } else {
      setValue('username', memberState.username ?? '')
      setValue('managerName', memberState.name ?? '')
      setValue('managerContact', memberState.mobile ?? '')
      setValue('managerEmail', memberState.email ?? '')
    }
  }, [])

  useEffect(() => {
    if (isSuccess) {
      history.push(routePath.account)
    }

    if (isError) {
      if (isFetchBaseQueryError(error)) {
        dispatch(showDialog({
          title: 'notice',
          body: (error.data as Error).error,
          confirm: {
            text: 'confirm',
            onClick: () => dispatch(hideDialog()),
          },
        }))
      }
    }
  }, [isSuccess, isError])

  const handleClickCancel = () => {
    history.goBack()
  }

  const handleClickSave: SubmitHandler<FormInput> = (fieldData) => {
    updateManager({
      userId: params.id,
      name: fieldData.managerName,
      email: fieldData.managerEmail,
      mobile: fieldData.managerContact,
      password: fieldData.password,
      ...(memberState.username !== fieldData.username && { username: fieldData.username })
    })
  }

  return (
    <BackgroundContainer>
      <ContentsWrapper>
        <BodyTitleContainer title={'admin_account_edit'.localized()}/>
        {
          memberState && (
            <ContentsBox padding='1.785rem 2rem'>
              <ContentsBoxTitle hasSiteNameLabel title={memberState.siteSno ?? '-'}/>
              <Divider margin='1.5rem 0' />
              <BoxContentsWrapper>
                <ContainerInfoBox
                  label={'admin_account_id'.localized()}
                  type='edit' maxColumns={2}
                  register={register('username', formOptions({ required: true }))}
                  errorMessage={errors.username?.message}
                />
                <ContainerInfoBox
                  label={'admin_account_name'.localized()}
                  type='edit' maxColumns={2}
                  register={register('managerName', formOptions({ required: true }))}
                  errorMessage={errors.managerName?.message}
                />
                <ContainerInfoBox
                  label={'admin_account_contact'.localized()}
                  type='edit' maxColumns={2}
                  register={register('managerContact', formOptions({ required: true }))}
                  errorMessage={errors.managerContact?.message}
                />
                <ContainerInfoBox
                  label={'admin_account_email'.localized()}
                  type='edit' maxColumns={2}
                  register={register('managerEmail', formOptions({ required: true }))}
                  errorMessage={errors.managerEmail?.message}
                />
              </BoxContentsWrapper>
            </ContentsBox>
          )
        }
        <ButtonGroup padding='1.188rem 0'>
          <ActionButton onClick={handleClickCancel} value={'cancel'.localized()} bgColor='white'/>
          <ActionButton onClick={handleSubmit(handleClickSave)} value={'button_save'.localized()} bgColor='white'/>
        </ButtonGroup>
      </ContentsWrapper>
    </BackgroundContainer>
  )
}

export default AccountEditContainer

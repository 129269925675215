declare const window: any

/** 관리 항목 유무 구분을 위한 prefix, -ths- */
const prefixKey = '-ths-'

function StorageServiceFactory(type: string) {
  return {
    [type]: (key: string, value?: any) => {
      if (value !== undefined) {
        return window[type].setItem(prefixKey + key, JSON.stringify(value))
      } else {
        const obj = window[type].getItem(prefixKey + key)
        try {
          return JSON.parse(obj)
        } catch (e) {
          window[type].removeItem(prefixKey + key)
          return undefined;
        }
      }
    },
  }
}

const Storage = StorageServiceFactory("localStorage")
export {Storage}
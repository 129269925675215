import React from 'react'
import styled from 'styled-components'
import { useGetCurrentTermQuery } from '../../controllers/services/api/term'

const Disclaimer = () => {
  const { data, isSuccess, isError } = useGetCurrentTermQuery()

  return (
    <DisclaimerWrapper>
      { isError || !data ? 'terms_no_data'.localized() : isSuccess && data.disclaimer}
    </DisclaimerWrapper>
  )
}

const DisclaimerWrapper = styled.div`
  width: 100%;
  padding: 1.375rem 1.875rem;
  min-height: 5rem;

  background: var(--white);
  
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  border-radius: 0.5rem;
  
  text-align: start;
  font-size: 0.75rem;
  color: var(--gray700);
  white-space: pre-wrap;

  @media only screen and (max-width: 1023px) {
    font-size: 0.625rem;
    padding: 0.5rem 0.62rem;
  }
`

export default Disclaimer

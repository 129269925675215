import { useEffect } from 'react'
import { useDeleteFileMutation, useLazyGetAllFilesQuery, usePostFileMutation } from '../controllers/services/api/file'
import { hideLoading, showLoading } from '../controllers/slices/loadingSlice'
import { useAppDispatch } from '../store/hooks'
import { useNoticePopUp } from './useNoticePopUp'
import reSize from '../utils/Compress'

const BUILD_ENV = process.env.REACT_APP_SERVICE_TYPE_2!

export const useResourceState = () => {
  const [trigger, getRscState] = useLazyGetAllFilesQuery()
  const [postRscRaw, postRscState] = usePostFileMutation()
  const [deleteRsc, deleteRscState] = useDeleteFileMutation()

  const resources = getRscState.data as Resource[]
  const dispatch = useAppDispatch()
  const { showPopUp } = useNoticePopUp()

  useEffect(() => {
    getRscState.isLoading ? dispatch(showLoading()) : dispatch(hideLoading())

    if (getRscState.isError) {
      showPopUp('receive_fail'.localized('GET'), 'error')
    }
  }, [getRscState.isLoading, getRscState.isError])

  useEffect(() => {
    postRscState.isLoading ? dispatch(showLoading()) : dispatch(hideLoading())

    if (postRscState.isError) {
      showPopUp('receive_fail'.localized('POST'), 'error')
    }
  }, [postRscState.isLoading, postRscState.isError])

  useEffect(() => {
    deleteRscState.isLoading ? dispatch(showLoading()) : dispatch(hideLoading())

    if (deleteRscState.isError) {
      showPopUp('receive_fail'.localized('DEL'), 'error')
    }
  }, [deleteRscState.isLoading, deleteRscState.isError])

  const postRsc = (file: File) => {
    const maxSize = 1024 * 500 // 500KB
    if (file.size > maxSize) {
      dispatch(showLoading())
      return reSize(file)
        .then((result) => postRscRaw({ dirName: BUILD_ENV, multipartFile: result }))
        .finally(() => dispatch(hideLoading()))
    } else {
      return postRscRaw({ dirName: BUILD_ENV, multipartFile: file })
    }
  }

  const getPathById = (id: string) => {
    const target = resources.find((rsc) => rsc.fileId === id)
    if (target) {
      return target.url
    } else {
      showPopUp('리소스에 없는 이미지입니다.\n이미지를 다시 입력해 주세요.')
      return null
    }
  }

  const deleteRscByPath = (path: string) => {
    const decodedPath = decodeURIComponent(path)
    const key = decodedPath.replace(process.env.REACT_APP_S3_BASE_URL!, '')
    return deleteRsc({ fileId: key })
  }

  const getRsc = () => {
    trigger()
  }

  return { getRsc, postRsc, deleteRsc, resources, getPathById, deleteRscByPath }
}

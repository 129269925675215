import React, { useEffect, useState } from 'react'
import { BackgroundContainer } from '../../../components/BackgroundContainer'
import { ContentsWrapper } from '../../../components/ContentsWrapper'
import BodyTitleContainer from '../../../components/BodyTitleContainer'
import AdInfoContent from './AdInfoContent'
import { ContentsBox } from '../../../components/ContentsBox'
import { useGetAllAdsQuery } from '../../../../controllers/services/api/ad'
import SiteSelector from '../../../components/SiteSelector'
import styled from 'styled-components'

const AdInfoContainer = () => {
  console.log('AdInfoContainer')
  const { data, isSuccess } = useGetAllAdsQuery()
  const [ads, setAds] = useState<AdInfo[] | null>(null)
  const [currentSite, setCurrentSite] = useState<Site>()

  useEffect(() => {
    if (isSuccess && data.length > 0 && currentSite) {
      const selectedAds = data.filter((ad) => currentSite.name === ad.site.name)
      setAds(selectedAds.length > 0 ? selectedAds : null)
    } else {
      setAds(null)
    }
  }, [currentSite])

  const onChangeSite = (currentSite?: Site) => {
    if (currentSite) {
      setCurrentSite(currentSite)
    }
  }

  return (
    <BackgroundContainer>
      {
        isSuccess && (
          <ContentsWrapper>
            <BodyTitleContainer title={'admin_ad_info'.localized()}/>
            <ContentsBox padding='1.25rem 2rem'>
              <SelectorWrapper>
                <SiteSelector currentSite={currentSite} onChangeSite={onChangeSite}/>
              </SelectorWrapper>
            </ContentsBox>
            { currentSite && (
              ads?.map((ad) => <AdInfoContent key={ad.adId} ad={ad} />) ??
                <ContentsBox padding='1.875rem 2rem'>{'admin_ad_empty'.localized()}</ContentsBox>
            )}
          </ContentsWrapper>
        )
      }
    </BackgroundContainer>
  )
}

const SelectorWrapper = styled.div`
  width: 100%;
  max-width: 21rem;
  margin: 0 auto;
`

export default AdInfoContainer

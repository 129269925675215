import React, { useEffect, useMemo, useState } from 'react'
import { BackgroundContainer } from '../../../components/BackgroundContainer'
import { ContentsWrapper } from '../../../components/ContentsWrapper'
import styled from 'styled-components'
import AdBoardContainer from './AdBoardContainer'
import { useAppDispatch } from '../../../../store/hooks'
import { useHistory, useLocation } from 'react-router-dom'
import SearchContainer from './SearchContainer'
import { hideLoading, showLoading } from '../../../../controllers/slices/loadingSlice'
import ButtonGroup from '../../../components/ButtonGroup'
import ActionButton from '../../../components/ActionButton'
import { routePath } from '../../../../route'
import { hideDialog, showDialog } from '../../../../controllers/slices/dialogSlice'
import Disclaimer from '../../../components/Disclaimer'
import { useGetAdBySiteMutation } from '../../../../controllers/services/api/ad'
import { useParamSiteSno } from '../../../../hooks/useParamSiteSno'
import useRenewalTerms from '../../../../hooks/useRenewalTerms'
import TermsNoticeDialog from '../../../components/TermsNoticeDialog'
import MainFooter from '../../../components/MainFooter'
import { useGetSiteQuery } from '../../../../controllers/services/api/site'

const MainContainer = () => {
  const dispatch = useAppDispatch()
  const [brandName, setBrandName] = useState('')

  const [getAllAds, getAllAdsState] = useGetAdBySiteMutation()
  const [ads, setAds] = useState<AdInfo[]>([])
  const [filteredAds, setFilteredAds] = useState<AdInfo[]>([])

  const history = useHistory()
  const { siteSno } = useParamSiteSno()
  const { pathname, search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const currentPage = Number(searchParams.get('page')) || 1
  const searchKeyword = searchParams.get('keyword') ? decodeURIComponent(encodeURIComponent(searchParams.get('keyword') as string)) : null
  const { changeLog, showTermsNotice, isChecked, onCloseDialog, onCheckBox } = useRenewalTerms()
  const getSite = useGetSiteQuery({ siteSno })


  useEffect(() => {
    getAllAds({ siteSno })
    sessionStorage.removeItem("session_auth")
  }, [])

  useEffect(() => {
    if (getSite.isSuccess){
      setBrandName(getSite.data.name)
    }
  }, [getSite.isSuccess])

  useEffect(() => {
    if (searchKeyword) {
      setFilteredAds(ads.filter((ad) => ad.storeName?.includes(searchKeyword) || ad.author?.includes(searchKeyword)))
    }
  }, [ads, searchKeyword])

  useEffect(() => {
    getAllAdsState.isLoading ? dispatch(showLoading()) : dispatch(hideLoading())

    if (getAllAdsState.isSuccess) {
      setAds(getAllAdsState.data)
    }

    if (getAllAdsState.isError) {
      dispatch(
        showDialog({
          title: 'Error',
          titleHighlight: true,
          body: '광고 목록을 불러올 수 없습니다.\n초기화면으로 이동합니다.',
          confirm: {
            text: 'confirm',
            onClick: () => {
              dispatch(hideDialog())
              history.replace(routePath.root)
            }
          }
        })
      )
    }
  }, [getAllAdsState.isSuccess, getAllAdsState.isLoading, getAllAdsState.isError])

  const onClickAdRegister = () => {
    history.push(routePath.register(siteSno), { replace: true })
  }

  const onClickManagerLogin = () => {
    history.push(routePath.manage(siteSno), { replace: true })
  }

  const onClickAd = (adId : string) => {
    history.push(routePath.preview(siteSno, adId), { replace: true })
  }

  const onPageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    searchParams.set('page', page.toString())
    history.push(`${pathname}?${searchParams.toString()}`, { replace: true })
  }

  return (
    <BackgroundContainer headerHeight='0rem'>
      <ContentsWrapper gap='0rem' paddingTop='1.875rem'>
        <Disclaimer/>
        <HeaderWrapper>
          <TitleWrapper>{brandName}</TitleWrapper>
          <SearchContainer keyword={searchKeyword}/>
        </HeaderWrapper>
        <BoardWrapper>
          {
            searchKeyword && filteredAds.length === 0 && getAllAdsState.isSuccess ? (
              <EmptyContainer>
                <EmptyResult>'{searchKeyword}' 검색 결과가 없습니다.</EmptyResult>
              </EmptyContainer>
            ) : (
              <AdBoardContainer
                ads={searchKeyword ? filteredAds : ads}
                onClickAd={onClickAd}
                currentPage={currentPage}
                onPageChange={onPageChange} />
            )
          }
        </BoardWrapper>
        <ButtonsWrapper>
          <ButtonGroup width='inherit' padding='0'>
            <ActionButton onClick={onClickAdRegister} value={'register_ad'.localized()} bgColor='white' padding='0.75rem 1rem' width='inherit'/>
            <ActionButton onClick={onClickManagerLogin} value={'manage_login'.localized()} bgColor='white' padding='0.75rem 1rem' width='inherit'/>
          </ButtonGroup>
        </ButtonsWrapper>
      </ContentsWrapper>
      <FooterContainer>
        <MainFooter />
      </FooterContainer>
      <TermsNoticeDialog
        isShow={showTermsNotice}
        isChecked={isChecked}
        changeLog={changeLog}
        onClose={onCloseDialog}
        onCheckBox={onCheckBox}
      />
    </BackgroundContainer>
  )
}

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  
  padding: 3.125rem 0 1.25rem;
  gap: 1.87rem;
  
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: stretch;
    gap: 1.25rem;
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    flex-direction: column;
    padding: 1.87rem 0 1rem;
    align-items: end;
  }
`

const BoardWrapper = styled.div`
  position: relative;
  width: 100%;
  order: 2;
`

const EmptyContainer = styled.div`
  max-width: 75rem;
  width: 100%;
  min-height: 40.75rem;
`


const TitleWrapper = styled.div`
  font-weight: 700;
  font-size: 1.75rem;
  color: var(--gray900);
  align-self: start;

  @media only screen and (max-width: 1023px) {
    font-size: 1.25rem;
  }
`

const ButtonsWrapper = styled.div`
  position: relative;
  bottom: 2.25rem;
  align-self: end;
  order: 2;

  @media only screen and (max-width: 767px) {
    width: 100%;
    height: auto;
    align-self: stretch;
    bottom: 0;
    padding: 0.625rem 0 1.25rem;
    order: 1;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    height: 0;
    bottom: 3.625rem;
    align-self: start;
    order: 1;
    margin-bottom: 2rem;
  }
`

const EmptyResult = styled.div`
  padding : 15rem 10rem;
`

const FooterContainer = styled.div`
  position: relative;
  width: 100%;
  order: 2;
  margin-top: -3.58rem;
  
  @media only screen and (max-width: 1023px) {
    margin-top: -1rem;
  }
`


export default MainContainer

import React, { useEffect, useState } from 'react'
import { BackgroundContainer } from '../../../components/BackgroundContainer'
import { ContentsWrapper } from '../../../components/ContentsWrapper'
import BodyTitleContainer from '../../../components/BodyTitleContainer'
import ActionButton from '../../../components/ActionButton'
import { routePath } from '../../../../route'
import { useHistory } from 'react-router-dom'
import ButtonGroup from '../../../components/ButtonGroup'
import { Authorities, AccessLevel, adminAuthority } from '../../../../resource/constants'
import { useAppDispatch } from '../../../../store/hooks'
import { hideLoading, showLoading } from '../../../../controllers/slices/loadingSlice'
import AccountsContainer from './AccountsContainer'
import { useGetAdminsQuery, useUpdateMemberMutation } from '../../../../controllers/services/api/member'
import AddAdminDialog from './AddAdminDialog'

const AuthorityContainer = () => {
  console.log('AuthorityContainer')
  const history = useHistory()
  const dispatch = useAppDispatch()
  const getAllAdminsState = useGetAdminsQuery()
  const [updateAdmin, updateAdminState] = useUpdateMemberMutation()
  const [admins, setAdmins] = useState<User[]>([])
  const [updatedAuth, setUpdatedAuth] = useState<User[]>([])
  const [dialog, setDialog] = useState(false)

  useEffect(() => {
    if (getAllAdminsState.isSuccess) {
      setAdmins(getAllAdminsState.data)
    }
  }, [getAllAdminsState.isSuccess])

  useEffect(() => {
    updateAdminState.isLoading ? dispatch(showLoading()) : dispatch(hideLoading())

    if (updateAdminState.isSuccess) {
      history.push(routePath.admin)
    }
  }, [updateAdminState.isLoading, updateAdminState.isSuccess])

  const handleClickCancel = () => {
    history.push(routePath.admin)
  }

  const handleClickSave = () => {
    updatedAuth.forEach((auth) => {
      updateAdmin({
        userId: auth.userId,
        accessLevel: auth.accessLevel,
      })
    })
  }

  const handleOpenDialog = () => {
    setDialog(true)
  }

  const handleCloseDialog = () => {
    setDialog(false)
  }

  const onChangeAuthority = (id: string, value: AccessLevel) => {
    const account = admins.find((account) => account.username === id)
    if (account) {
      const idx = updatedAuth.findIndex((item: User) => item.userId === account.userId)
      const cpyArray = [...updatedAuth]
      cpyArray[idx === -1 ? updatedAuth.length : idx] = {
        ...account,
        accessLevel: value,
      }
      setUpdatedAuth(cpyArray)
    }
  }

  return (
    <BackgroundContainer>
      <ContentsWrapper>
        <BodyTitleContainer title={'admin_auth'.localized()}/>
        { admins &&
          adminAuthority.map((authority) => <AccountsContainer
            key={authority}
            role={authority}
            accounts={ admins.filter((admin) => Authorities[admin.accessLevel] === authority) }
            onChangeAuth={onChangeAuthority}
            handleOpenDialog={handleOpenDialog}
          />)
        }
        <ButtonGroup padding='1.25rem 0'>
          <ActionButton onClick={handleClickCancel} value={'cancel'.localized()} bgColor='white'/>
          <ActionButton onClick={handleClickSave} value={'button_save'.localized()} bgColor='white'/>
        </ButtonGroup>
      </ContentsWrapper>
      <AddAdminDialog open={dialog} onClose={handleCloseDialog} />
    </BackgroundContainer>
  )
}

export default AuthorityContainer

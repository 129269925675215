import React from 'react'
import {
  Pagination, paginationClasses,
  styled,
} from '@mui/material'
import dayjs from 'dayjs'
import AdStatusBadge, { getAdStatus } from '../../../components/AdStatusBadge'
import ApproveContent from './ApproveContent'

type AdTableProps = {
  ads: AdInfo[],
  currentPage: number,
  onPageChange: (e: React.ChangeEvent<unknown>, page: number) => void
}

const ADS_PER_PAGE = 5

const ApproveBoardContainer = ({ ads, currentPage, onPageChange }: AdTableProps) => {
  const currentPageLast = currentPage * ADS_PER_PAGE
  const currentPageFirst = currentPageLast - ADS_PER_PAGE
  const [adListNumber, setAdListNumber] = React.useState(0)

  const formatDateString = (timeStamp: number) => {
    return dayjs.unix(timeStamp).format('YY.MM.DD')
  }

  const onShowInfo = (index: number) => {
    setAdListNumber(index + (currentPage - 1 ) * ADS_PER_PAGE)
  }
  const setPeriod = (isApproval: string, start: string, end: string) => {
    return formatDateString(Number(start)) + ' ~ ' + formatDateString(Number(end))
  }

  return (
    <BoardWrapper>
      <BoardHeader>
        <Item className='status'>{'processing_status'.localized()}</Item>
        <Item className='storeName'>{'title'.localized()}</Item>
        <Item className='author'>{'registrant'.localized()}</Item>
        <Item className='period'>{'boarding_period'.localized()}</Item>
      </BoardHeader>
      <BoardItemContainer>
        { ads.length !== 0 ?
          ads.slice(currentPageFirst, currentPageLast).map((ad,index) => (
            <BoardItem key={ad.adId} onClick={() => onShowInfo(index)}>
              <Item className='status'>
                <AdStatusBadge startDate={ad.startAt} finishDate={ad.finishAt} isApproval={ad.approvalYn === 'Y'}/>
              </Item>
              <Item className='storeName'>{ad.storeName}</Item>
              <GroupItem>
                <Item className='author'>{ad.author}</Item>
                <VerticalDivider/>
                <Item className='period'>
                  {getAdStatus(ad.startAt, ad.finishAt, ad.approvalYn === 'Y') === 'rejected' ? '' : setPeriod(ad.approvalYn!, ad.startAt!, ad.finishAt!)}
                </Item>
              </GroupItem>
            </BoardItem>
          )) :
          <div style={{marginTop: '8rem', fontSize: '1rem'}}> {'ad_not_exist'.localized()} </div>
        }
      </BoardItemContainer>
      <TablePagerWrapper>
        <StyledPagination
          sx={{ display: 'flex', justifyContent: 'center' }}
          count={Math.ceil(ads.length / ADS_PER_PAGE)}
          showFirstButton showLastButton boundaryCount={3}
          onChange={onPageChange}
          page={currentPage}
        />
      </TablePagerWrapper>
      {
        ads.slice(adListNumber, adListNumber+1).map((ad) => <ApproveContent key={ad.adId} ad={ad} />)
      }
    </BoardWrapper>
  )
}

const BoardWrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderTop: '1px solid var(--gray300)',
  order: '1',
  [`@media only screen and (max-width: 1023px)`]: {
    order: '2',
  },
}))

const BoardHeader = styled('div')(() => ({
  height: '3.375rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'var(--light-blue)',
  color: 'var(--gray900)',
  fontSize: '1rem',
  lineHeight: '100%',
  fontWeight: '500',
  borderBottom: '1px solid var(--gray300)',

  [`@media only screen and (max-width: 1023px)`]: {
    display: 'none',
  },
}))

const BoardItemContainer = styled('div')(() => ({
  minHeight: '17rem',
  backgroundColor: 'var(--white)',
}))

const BoardItem = styled('div')(() => ({
  height: '3.375rem',
  display: 'flex',
  alignItems: 'center',
  fontSize: '1rem',
  lineHeight: '150%',
  color: 'var(--gray700)',
  cursor: 'pointer',
  borderBottom: '1px solid var(--gray300)',
  backgroundColor: 'var(--white)',
  padding: '0.625rem 0',
  boxSizing: 'border-box',
  [`&:hover`]: {
    backgroundColor: '#7496cc4d',
  },

  [`@media only screen and (max-width: 1023px)`]: {
    height: 'auto',
    minHeight: '3.875rem',
    flexDirection: 'column',
    alignItems: 'start',
    padding: '1rem 0',
    fontSize: '0.6875rem',
    gap: '0.625rem',
  },
}))

const Item = styled('div')(() => ({
  padding: '0 0.625rem',

  [`&.status`]: {
    width: '11.25rem',
    flexShrink: '0',

    [`@media only screen and (max-width: 1023px)`]: {
      width: 'auto',
    }
  },
  [`&.storeName`]: {
    width: '41.25rem',
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    [`@media only screen and (max-width: 1023px)`]: {
      display: '-webkit-box',
      width: '100%',
      textAlign: 'start',
      fontSize: '0.8125rem',
      fontWeight: '500',
      whiteSpace: 'wrap',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      boxSizing: 'border-box',
    }
  },
  [`&.author`]: {
    width: '9.375rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flexShrink: '0',

    [`@media only screen and (max-width: 1023px)`]: {
      width: 'auto',
    }
  },
  [`&.period`]: {
    width: '13.75rem',
    flexShrink: '0',

    [`@media only screen and (max-width: 1023px)`]: {
      width: 'auto',
    }
  },
}))

const GroupItem = styled('div')(() => ({
  display: 'flex',
}))

const VerticalDivider = styled('div')(() => ({
  display: 'none',
  height: 'auto',
  width: '1px',
  background: 'var(--gray300)',

  [`@media only screen and (max-width: 1023px)`]: {
    display: 'block',
  }
}))

const TablePagerWrapper = styled('div')(() => ({
  paddingTop: '1.5rem',
}))

const StyledPagination = styled(Pagination)(() => ({
  [`&.${paginationClasses.root}`]: {
    [`.MuiPaginationItem-text`]: {
      fontSize: '1rem',
      fontFamily: 'Noto Sans KR',
    },
    [`.Mui-selected`]: {
      color: 'var(--lg-red)',
      fontWeight: '700',
      background: 'transparent',
    },
    [`@media only screen and (max-width: 1023px)`]: {
      [`.MuiPaginationItem-root`]: {
        margin: '0'
      },
    },
  },
}))

export default ApproveBoardContainer

import { createSlice } from '@reduxjs/toolkit'

export type DialogPayload = {
  templatedBody: 'multiple_access' | 'none',
  title: string,
  titleHighlight: boolean,
  body: string,
  detailText: string,
  confirm: {
    text: string,
    onClick: () => void,
  },
  cancel: {
    text: string,
    onClick: () => void,
  },
  bgClick?: boolean
}

const initialState = {
  show: false,
  title: '',
  titleHighlight: false,
  body: '',
  templatedBody: 'none',
  detailText: '',
  confirm: {
    text: '',
    onClick: () => {},
  },
  cancel: {
    text: '',
    onClick: () => {},
  },
  bgClick: true
}

const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    showDialog: (state, action) => {
      const payload: DialogPayload = action.payload
      return {
        ...initialState,
        show: true,
        ...payload
      }
    },
    hideDialog: (state) => {
      state.show = false
    },
  }
})

export const { showDialog, hideDialog } = dialogSlice.actions

export default dialogSlice.reducer

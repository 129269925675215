import React from 'react'
import styled from 'styled-components'

type GuideProps = {
  siteName: string,
  guideContent?: string
}

const RegisterGuideContainer = ({ siteName, guideContent }: GuideProps) => {
  return (
    <GuideWrapper>
      <GuideTitle>
        <span className="highlight">{`${siteName} `}</span>
        <span>{'ad_register_guide'.localized()}</span>
      </GuideTitle>
      <GuideContents>
        {guideContent ? guideContent.localizedWithBR() : 'terms_no_data'.localized()}
      </GuideContents>
    </GuideWrapper>
  )
}

const GuideWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const GuideTitle = styled.div`
  font-weight: 700;
  font-size: 1.25rem;
  text-align: start;
  
  > span {
    &.highlight {
      color: var(--lg-red);
    }
  }
`

const GuideContents = styled.div`
  text-align: start;
  padding: 1.875rem;
  margin: 1.5rem 0;
  white-space: normal;
  word-wrap: break-word;
  background: var(--white);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
`

export default RegisterGuideContainer

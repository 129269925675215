import React from 'react'

declare global {
  interface String {
    localized(...args: any[]): string

    localizedWithBR(...args: any[]): JSX.Element[]

    toAriaLabel(...args: any[]): string

    /**
     * 문자열 업데이트 from SiteConfig
     */
    update(newString: string): void

    /**
     * 소수점 두자리 표시 - 0.00
     */
    roundDotTwo(): string

    appendPostWord(): string
  }

  interface StringConstructor {
    localizedString: Map<String, string>
  }
}

String.localizedString = new Map([
  ['home_title', 'ThinQ Home 우리단지'],
  ['home_subject', '우리 상가 \n 광고 서비스'],
  ['home_desc', '우리 단지를 선택하여 \n 상가 광고를 간편하게 관리해보세요.'],
  ['home_text', '\u203B 단지를 선택해 주세요.'],
  ['home_banner1', '검암역 로열파크시티 푸르지오'],
  ['home_banner2', '안심 파라곤'],
  ['cancel', '취소'],
  ['confirm', '확인'],
  ['login', '로그인'],
  ['manage_office', '관리사무소'],
  ['ad_guide', '광고 등록가이드'],
  ['ad_guide_complete', '작성하신 등록가이드 내용이 등록되었습니다.'],
  ['ad_guide_failed', '가이드 등록이 실패하였습니다.'],
  ['ad_guide_empty', '등록가이드 내용이 없습니다.'],
  ['ad_guide_length', '입력 제한을 초과하였습니다.\n500자 이내로 작성해 주세요.'],
  ['ad_agree_reject', '광고 승인/거절'],
  ['ad_guide_example', '\n\n \u003c 예시 \u003e \n'+
    '\u203B 우리 아파트 광고 등록 가이드\n' +
    '1. 광고 등록 비용 기준은 아래와 같습니다.(건당)\n' +
    '- 1주일에 15만원, 2주일에 30만원\n' +
    '2. 요청 하신 광고는 관리사무소의 승인을 통해 ThinQ Home 우리단지 서비스 앱에 노출됩니다.\n' +
    '- ThinQ Home \u003e 우리단지 앱 \u003e 우리 상가 소식\n' +
    '3. 신청한 광고 내용의 적절성 및 등록 비용 완납 후 승인 처리 진행합니다.\n' +
    '4. 문의 전화 : 관리사무소 02-1234-1234'],
  ['ad_guide_notice', '해당 내용은 광고주가 광고 등록하는 페이지에 표시되는 내용입니다.\n' +
    '광고 등록 시 안내 사항이나 가이드 사항을 기입해주시기 바랍니다.'],
  ['register', '등록'],
  ['edit', '수정'],
  ['delete', '삭제'],
  ['renew', '초기화'],
  ['terms_no_data', '데이터를 가져올 수 없습니다.'],
  ['disclaimer', '\u2013 우리 단지 서비스에 게시되는 모든 광고는 아파트 관리사무소가 사전 승인한 광고로서, 게시된 광고의 관리 및 내용에 대한 책임은 관리사무소와 광고주에 있습니다.\n' +
  '\u2013 우리 단지 서비스는 게시된 광고의 게시 및 내용 등에 대한 어떠한 권한도 없으며, 광고로 인하여 발생한 어떠한 손해·문제에 대하여 어떠한 책임도 부담하지 않습니다. '],
  ['terms_title', '우리상가 소식 광고 등록 약관 동의'],
  ['terms_title_manager', '우리상가 소식 관리사무소 약관 동의'],
  ['terms_title_agree', '약관 동의'],
  ['terms_all_agree', '전체 동의'],
  ['terms_agree_desc', '서비스 이용 약관, 개인정보 수집 및 이용 동의에 모두 동의합니다.'],
  ['terms_object', '서비스 이용 약관'],
  ['terms_personal_info_management', '개인정보 처리방침'],
  ['terms_personal_info_agree', '개인정보 수집 및 이용 동의'],
  ['terms_complete_agreement', '약관 전체 동의'],
  ['terms_agree', '동의함'],
  ['terms_example', '이 약관은 회사(전자상거래 사업자)가 운영하는 사이버 몰(이하 “몰”이라 한다)에서 제공하는 인터넷 관련 서비스(이하 “서비스”라 한다)를 이용함에 있어 사이버 몰과 이용자의 권리․의무 및 책임사항을 규정함을 목적으로 합니다.\n' +
  '※ 「PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다.\n' +
  '① “몰”이란 OO 회사가 재화 또는 용역(이하 “재화 등”이라 함)을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이버몰을 운영하는 사업자의 의미로도 사용합니다.\n' +
  '② “이용자”란 “몰”에 접속하여 이 약관에 따라 “몰”이 제공하는 서비스를 받는 회원 및 비회원을 말합니다.\n' +
  '③ ‘회원’이라 함은 “몰”에 회원등록을 한 자로서, 계속적으로 “몰”이 제공하는 서비스를 이용할 수 있는 자를 말합니다.\n' +
  '④ ‘비회원’이라 함은 회원에 가입하지 않고 “몰”이 제공하는 서비스를 이용하는 자를 말합니다.'],
  // 로그인
  ['ad_register_login', '광고 등록자 로그인'],
  ['ad_register_login_detail', '서비스 이용 시 등록하실 이메일과 비밀번호를 입력해 주세요'],
  ['input_name', '이름을 입력해 주세요'],
  ['input_store_name', '상호명을 입력해 주세요'],
  ['input_address', '주소를 입력해 주세요.'],
  ['input_phone_number', '연락처를 입력해 주세요.'],
  ['input_desc', '설명을 입력해 주세요.'],
  ['input_homepage', '네이버/홈페이지 주소를 입력해 주세요.'],
  ['input_email', '이메일을 입력해 주세요'],
  ['input_password', '비밀번호를 입력해 주세요'],
  ['find_password', '비밀번호 찾기'],
  ['find_password_detail', '광고 등록 시 등록한 개인정보를 입력해 주세요.'],
  ['manage_login', '관리사무소 로그인'],
  ['manage_login_detail', '서비스 이용 시 ID와 비밀번호를 입력해 주세요.'],
  ['input_id', 'ID를 입력해 주세요'],
  ['save_id', '아이디 저장'],
  ['check_password', '비밀번호 확인'],
  ['check_password_detail', '로그인 인증에 %@회 실패하셨습니다.\n 설정했던 로그인 정보로 다시 입력해주세요. (5회 로그인 실패 시 계정이 잠금 처리됩니다.)'],
  ['account_lock', '5회 이상 로그인 인증에 실패하셨습니다. 계정이 잠금 처리됩니다. 계정 잠금 해제는 관리자에게 문의바랍니다.'],
  ['wrong_email', '이메일이 올바르지 않습니다.\n다시 입력해 주세요.'],
  ['access_error', '해당 계정은 이미 접속 중입니다. \n 접속중인 계정을 로그아웃 하시겠습니까?'],
  ['failed_signUp', '회원가입 실패'],
  ['failed_signUp_desc', '회원가입에 실패하였습니다.\n다시 시도해 주세요.'],
  ['failed_signUp_detail', '※ 만약 다른 단지에 동일한 이메일로 가입이 되어 있는 경우 새로운 이메일로 신규 가입바랍니다.'],
  //비밀번호 찾기
  ['send_password', '등록된 이메일로 임시 비밀번호가 전송되었습니다.'],
  ['not_exist_email', '해당 이메일로 가입된 사용자가 없습니다.'],
  // 비밀번호 변경
  ['change_password', '비밀번호 변경하기'],
  ['temp_password', '임시 비밀번호'],
  ['new_password', '신규 비밀번호'],
  ['check_password', '비밀번호 확인'],
  ['check_password_placeholder', '비밀번호를 다시 입력해 주세요'],
  ['password_not_matched', '비밀번호가 일치하지 않습니다.'],
  // Popup 메시지
  ['receive_fail', '리소스 %@ 실패했습니다.'],
  ['notice', '알 림'],
  ['Error', '에 러'],
  ['delete_user_title', '삭 제'],
  ['network_error', '지금은 네트워크 연결 오류입니다.\n나중에 다시 시도해 주세요.'],
  //광고 등록 페이지
  ['ad_register_title', '광고 등록'],
  ['ad_register_preview', '미리 보기'],
  ['company_name', '상호/매장명'],
  ['category', '카테고리'],
  ['pick_category', '카테고리 선택'],
  ['name', '이름'],
  ['phone_no', '상호/매장연락처'],
  ['address', '상호/매장주소'],
  ['desc', '상호/매장설명'],
  ['homepage', '홈페이지'],
  ['password', '비밀번호'],
  ['pick_file', '파일 선택'],
  ['set_default_img', '기본이미지로 설정 '],
  ['pick_file_detail', 'JPG, PNG 파일, 용량: 5MB 미만\n 권장사이즈: 300px(가로) x 160px(세로)'],
  ['file_limit_size', '선택된 이미지 파일의 용량이 큽니다.\n5MB 미만의 이미지를 선택해주시기 바랍니다.'],
  ['prugio_centver', '광명 푸르지오 센트베르'],
  ['ad_register_guide', '광고 등록 가이드'],
  ['ad_register_guide_input', '광고 등록 가이드를 입력해 주세요.'],
  ['input_placeholder', ' 입력해 주세요'],
  ['fail_register_detail', '등록된 광고 개수가 100개입니다.\n 광고 등록은 100개를 초과할 수 없습니다.\n 관리자에게 문의바랍니다'],
  ['delete_user', '로그인 및 광고 정보를 삭제하시겠습니까?\n 삭제 후 복구할 수 없습니다.'],
  ['period_invalid', '기간이 올바르지 않습니다.'],
  ['required_field', '필수 입력 항목입니다.'],
  //광고 승인/거절
  ['ad_approve_refuse', '광고 승인/거절'],
  ['period', '기간'],
  ['approve', '승인'],
  ['refuse', '거절'],
  ['re_approve', '재승인'],
  ['approve_same_period', '게시기간이 동일하게 설정되어 있습니다.\n 날짜를 변경해 주세요.'],

  //MainContainer
  ['processing_status', '처리상태'],
  ['title', '상호/매장명'],
  ['registrant', '등록자'],
  ['boarding_period', '게시기간'],
  ['publishing', '게시중'],
  ['pending', '요청중'],
  ['waiting', '대기중'],
  ['expired', '기간만료'],
  ['rejected', '승인거절'],
  ['register_ad', '광고 등록/수정'],
  ['move_home_bt', '홈화면'],
  ['main_personal_information', '개인정보 처리방침'],
  ['term_start_date_now', '현행 시행일자 '],
  ['term_start_date_past', '이전 시행일자 '],
  ['ad_not_exist', '등록된 우리 상가 소식이 없습니다.'],
  //광고 등록 확인
  ['agree_all_term', '약관을 모두 동의해 주세요.'],
  ['register_confirm', '광고 등록 확인'],
  ['register_confirm_detail', '광고 등록 작성/수정이 완료되셨습니까?'],
  // Admin Page Common
  ['admin_header_title1', '우리상가'],
  ['admin_header_title2', '관리자페이지'],
  ['site_name_tag', '단지 명'],
  ['admin', 'Admin'],
  ['user', 'User'],
  ['tester', 'Tester'],
  ['button_edit', '편집'],
  ['button_save', '저장'],
  ['button_modify', '변경'],
  ['button_img_upload', '이미지 업로드'],
  ['site_name', '단지명'],
  ['image', '이미지'],
  ['picking_site', '단지를 선택해 주세요'],
  ['no_permission_title', '접근 권한이 없습니다.'],
  ['no_permission_body', '관리자페이지 메인화면으로 이동합니다.'],
  // AdminLogin
  ['admin_login_title', '우리상가 관리자페이지'],
  ['admin_login_sub', 'Admin 서비스 이용 시 ID와 비밀번호를 입력해 주세요.'],
  // AdminHome
  ['admin_home_banner_title', '우리상가 어드민에 오신 것을 환영합니다.'],
  ['admin_home_banner_sub', '권한에 맞는 페이지만을 접속하실 수 있습니다.\n' + '필요로 하시는 기능이 표시되지 않을 때는 아래 메일로 요청해 주세요.'],
  ['admin_home_feature_title', '권한 별 주요 기능'],
  ['admin_home_feature_admin', '모든 기능(계정 관리, 권한 포함)'],
  ['admin_home_feature_user', '광고 정보, 이용 약관, 광고 등록 기능'],
  ['admin_home_feature_tester', '단지 검증 도구 사용'],
  // AdminAccount
  ['admin_account_management', '계정 관리'],
  ['admin_account_edit', '계정 편집'],
  ['admin_account_add', '계정 등록'],
  ['admin_account_id', 'ID'],
  ['admin_account_pw', 'Password'],
  ['admin_account_name', '관리자 이름'],
  ['admin_account_contact', '관리자 연락처'],
  ['admin_account_email', '관리자 이메일'],
  ['admin_account_empty', '등록된 계정이 없습니다.'],
  ['admin_account_activated', '계정이 활성화되었습니다.'],
  ['admin_account_retry', '다시 시도해주세요.'],
  ['admin_account_select_site', '단지를 선택해 주세요.'],
  ['admin_account_unlock', '잠금 해제'],
  ['admin_account_add_msg', '입력된 계정으로 등록이 완료되었습니다.'],
  ['admin_account_add_failed', '관리사무소 계정 등록에 실패하였습니다.'],
  ['admin_account_add_empty', '관리사무소 계정을 입력해 주세요.'],
  ['admin_account_add_title', '관리사무소 계정 등록'],
  ['admin_account_add_description', '%@ 관리사무소 계정을 입력해 주세요.'],
  // AdminAD
  ['admin_ad_info', '광고 정보'],
  ['admin_ad_selector_hint', '단지선택'],
  ['admin_ad_edit', '광고 편집'],
  ['admin_ad_apply', '광고 등록'],
  ['admin_ad_image', '이미지 정보'],
  ['admin_ad_empty', '등록된 광고가 없습니다.'],
  ['admin_ad_delete', '광고 정보를 삭제하시겠습니까?\n삭제 후 복구할 수 없습니다.'],
  // AdminTerms
  ['admin_terms', '이용 약관'],
  ['admin_ad_terms', '광고 등록 약관'],
  ['admin_terms_disclaimer', '디스클레이머'],
  ['admin_terms_common', '광고 공통 약관'],
  ['admin_terms_manager', '관리사무소 이용 약관'],
  ['admin_terms_user', '광고주 이용 약관'],
  ['admin_terms_add_msg', '이용 약관이 등록되었습니다.'],
  ['admin_terms_add_failed', '이용 약관 등록 요청이 실패하였습니다.'],
  ['admin_terms_privacy_policy', '개인정보 처리방침'],
  ['admin_terms_consent', '개인정보 수집 및 이용 동의서'],
  ['admin_terms_effective_date', '시행 일자'],
  // AdminResources
  ['admin_resources', '리소스'],
  ['admin_resources_empty', '등록된 리소스가 없습니다.'],
  // AdminTools
  ['admin_tools', '검증도구'],
  // AdminAuth
  ['admin_auth', '권한'],
  ['admin_auth_empty', '등록된 계정이 없습니다.'],
  ['admin_auth_add', '추가'],
  ['admin_auth_add_title', 'User 추가'],
  ['admin_auth_add_failed', 'User 등록에 실패하였습니다.'],
])

String.prototype.localized = function (...args: any[]): string {
  const key: String = (typeof this === 'string') ? String(this) : args[0]
  let rt = String.localizedString.get(key) ?? key
  if (rt.indexOf('%@') > -1) {
    const ar = rt.split('%@')
    const len = ar.length
    rt = ''
    for (let i = 0; i < len; i++) {
      rt += ar[i]
      if (i < args.length) {
        rt += String(args[i])
      }
    }
  }
  return rt.valueOf()
}

String.prototype.localizedWithBR = function (...args: any[]) {
  return this.localized(...args).split('\n').map((line, index) => (<span key={index}>{line}<br/></span>))
}

String.prototype.toAriaLabel = function (...args: any[]) {
  return this.localized(...args).replaceAll('\n', ' ')
}

/**
 * 문자열 업데이트 from SiteConfig
 */
String.prototype.update = function (newString) {
  String.localizedString.set(this, newString)
}

/**
 * 소수점 두자리 표시 - 0.00
 */
String.prototype.roundDotTwo = function () {
  const num = Number(this) || 0 // if NaN to 0
  const n = Math.round((num + Number.EPSILON) * 100) / 100
  return n.toFixed(2)
}

/**
 * 을/를 구분
 */
String.prototype.appendPostWord = function () {
  const lastChar = this.charCodeAt(this.length - 1)
  const isThereLastChar = (lastChar - 0xac00) % 28
  if (isThereLastChar) {
    return this + '을'
  }
  return this + '를'
}

export const LocalizeString = String.prototype.localized

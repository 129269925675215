import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Button, StyledEngineProvider } from '@mui/material'
import styled, { css } from 'styled-components'
import { routePath } from '../../../route'
import { useHistory, useLocation } from 'react-router-dom'
import { MenuIcon } from '../../../assets/images'
import { useAppDispatch } from '../../../store/hooks'
import { hideDialog, showDialog } from '../../../controllers/slices/dialogSlice'
import { useGetMemberMutation } from '../../../controllers/services/api/member'

const AdminNavBar = () => {
  const [menuState, setMenuState] = useState(false)
  const history = useHistory()
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()
  const [getMe, { data, isSuccess, isError }] = useGetMemberMutation()
  const [account, setAccount] = useState<User>()

  const ADMIN_MENUS = [
    {
      'title': 'admin_account_management'.localized(),
      'path': routePath.account,
      'access': 1,
    },
    {
      'title': 'admin_ad_info'.localized(),
      'path': routePath.adInfo,
      'access': 1,
    },
    {
      'title': 'admin_terms'.localized(),
      'path': routePath.adminTerms,
      'access': 1,
    },
    {
      'title': 'admin_auth'.localized(),
      'path': routePath.authority,
      'access': 0,
    },
    {
      'title': 'admin_ad_apply'.localized(),
      'path': routePath.adminAdRegister,
      'access': 1,
    },
  ]

  useLayoutEffect(() => {
    if (sessionStorage.getItem("admin_auth")) {
      const userId = JSON.parse(sessionStorage.getItem('admin_auth')!).id
      getMe({ userId })
    } else {
      redirectToLogin()
    }
  }, [])


  useLayoutEffect(() => {
    if (isSuccess) {
      setAccount(data)
    }

    if (isError) {
      redirectToLogin()
    }
  }, [isSuccess, isError])

  useEffect(() => {
    const currentMenu = ADMIN_MENUS.find((menu) => pathname === menu.path)
    if (currentMenu && account) {
      if (currentMenu.access < getAccessLevel(account.accessLevel)) {
        dispatch(showDialog({
          title: 'no_permission_title',
          body: 'no_permission_body',
          confirm: {
            text: 'confirm',
            onClick: () => {
              history.push(routePath.admin, { replace: true })
              dispatch(hideDialog())
            }
          },
          bgClick: false
        }))
      }
    }
  }, [account])

  const getAccessLevel = (level: string) => {
    return Number(level.slice(-1))
  }

  const navigateToMain = () => {
    history.push(routePath.admin, { replace: true })
  }

  const redirectToLogin = () => {
    history.push(routePath.adminLogin, { replace: true })
  }

  return (
    <NavBarWrapper>
      <HeaderTitle onClick={navigateToMain}>
        <span className='highlight'>{'admin_header_title1'.localized()}</span>
        <span> {'admin_header_title2'.localized()}</span>
      </HeaderTitle>
      <Spacer/>
      <MobileMenuButton src={MenuIcon} onClick={() => setMenuState(!menuState)}/>
      <MobileMenuWrapper $isActive={menuState}>
        <StyledEngineProvider injectFirst>
          { account?.accessLevel &&
            ADMIN_MENUS.filter((menu) => menu.access >= getAccessLevel(account.accessLevel)).map((menu) => (
              <NavButton key={menu.title} href={menu.path} $current={pathname === menu.path}>
                {menu.title}
              </NavButton>
            ))
          }
        </StyledEngineProvider>
      </MobileMenuWrapper>
    </NavBarWrapper>
  );
};

const Spacer = styled.div`
  flex-grow: 1;
`

const NavBarWrapper = styled.div`
  position: static;
  height: 4.5rem;
  background: var(--white);
  color: var(--gray700);
  display: flex;
  align-items: center;
  padding: 0 2.5rem;
`

const MobileMenuWrapper = styled.div<{ $isActive: boolean }>`
  @media only screen and (max-width: 767px) {
    display: ${(props) => (
            props.$isActive ? 'flex' : 'none')};
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 0.5rem;
    z-index: 99;
    position: absolute;
    top: 4.5rem;
    left: 0;

    background: var(--white);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.08);
  }

  display: flex;
  align-items: center;
  gap: 2.75rem;
`

const HeaderTitle = styled.div`
  font-family: inherit;
  font-style: normal;
  font-weight: 700;
  font-size: 1.375rem;
  letter-spacing: -0.01em;
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  
  & > span.highlight {
    color: var(--lg-red);
  }
`

const MobileMenuButton = styled.img`
  display: none;
  height: 1.5rem;
  width: 1.5rem;
  margin: 0.5rem;
  cursor: pointer;

  @media only screen and (max-width: 767px) {
    display: block;
  }
`

const NavButton = styled(Button)<{ $current: boolean }>`
  height: 100%;
  
  font-family: inherit;
  font-weight: 400;
  font-size: 1rem;
  color: inherit;
  letter-spacing: -0.01em;
  
  ${(props) => (
    props.$current && css`
      font-weight: 700;
      color: var(--gray900);
  `)}
`

export default AdminNavBar

import styled from 'styled-components'

const getOptionListStyle = ({ active = true, zIndex = 1 }) => {
  return `
  display: ${active ? 'block' : 'none'};
  max-height: ${active ? '16rem' : '0'};
  z-index: ${zIndex};
  `
}

const getLabelStyle = ({ active = true, isValid = true }) => {
  return `
    border: ${isValid ? active ? '1px solid var(--focus-border)' : '1px solid var(--gray300)' : '1px solid var(--warning)'};
  `
}

const getDropdownIconStyled = ({ active = true }) => {
  return `
    transform: ${active ? 'rotate( 180deg )' : 'rotate( 0deg )'};
  `
}

const getPlaceholderStyle = ({ isPlaceholder = false }) => {
  return `
    color: ${isPlaceholder ? 'var(--hint)' : 'var(--black)'};
  `
}

const Styled = {
  selectbox: styled.div`
    flex-grow: 1;
    min-width: 0;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--white);
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
    line-height: 100%;
    box-sizing: border-box;
    ${getLabelStyle}
  `,
  selectedLabel: styled.button`
    box-sizing: border-box;
    display: inline-block;
    align-items: center;
    width: inherit;
    height: 40px;
    border: none;
    border-radius: 4px;
    padding: 0.75rem 0 0.75rem 1rem;
    font-weight: inherit;
    font-size: inherit;
    text-align: start;
    line-height: inherit;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: var(--white);
    ${getPlaceholderStyle}
  `,
  optionItem: styled.li`
    box-sizing: border-box;
    padding: 0.8rem 1rem 0.8rem 1rem;
    transition: 0.3s;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      background: var(--gray100);
    }
  `,
  selectOptionList: styled.ul`
    box-sizing: border-box;
    position: absolute;
    list-style-type: none;
    padding: 0.5rem 0;
    margin: 0;
    top: 2.6rem;
    width: 100%;
    border-radius: 4px;
    border: 1px solid var(--gray300);
    background: var(--white);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    ${getOptionListStyle};
    overflow-y: scroll;
    
    font-size: inherit;
    line-height: inherit;
    
    &::-webkit-scrollbar {
      width: 0.375rem;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 1rem;
      background: var(--gray400);
    }
    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
    }
  `,
  moreIcon: styled.img`
    height: 1.5rem;
    width: 1.5rem;
    margin: 0.5rem;
    transition: all ease 0.3s;
    ${getDropdownIconStyled}
  `
};

export default Styled;

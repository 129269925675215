import React, { useState } from 'react'
import styled from 'styled-components'
import { HomeIcon, HomeHoverIcon, MoveBlack, MoveRight } from '../../assets/images'

type HomeButtonProps = {
  marginTop?: string
  logOut: () => void,
}

const HomeButton = ({ marginTop, logOut }: HomeButtonProps) => {
  const [mouseState, setMouseState] = useState(false)
  const navigateToMain = () => {
    logOut()
  }

  const mouseOn = () => {
    setMouseState(true)
  }

  const mouseOff = () => {
    setMouseState(false)
  }
  return (
    <HomeButtonWrapper onClick={navigateToMain} onMouseOver={mouseOn} onMouseLeave={mouseOff} style={{ marginTop: marginTop ?? '0'}}>
      <HomeButtonIcon src={mouseState? HomeHoverIcon : HomeIcon} />
      {'move_home_bt'.localized()}
      <HomeButtonMoveIcon src={mouseState? MoveBlack : MoveRight} />
    </HomeButtonWrapper>
  )
}

const HomeButtonWrapper = styled.div`
  align-self: start;
  max-width: 5.3125rem;
  width: 100%;
  height: 1.125rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  display: flex;
  color: var(--coolgray);
  cursor: pointer;
  &:hover {
    color: var(--black);
    text-decoration: underline;
  }
`

const HomeButtonIcon = styled.img`
  width: 1rem;
  height: 0.875rem;
  padding-right: 0.25rem;
  margin-top: 0.25rem;
`

const HomeButtonMoveIcon = styled.img`
  width: 1rem;
  height: 0.875rem;
  margin-left: 0.25rem;
  margin-top: 0.25rem;
`

export default HomeButton

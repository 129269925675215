import React, { HTMLInputTypeAttribute, KeyboardEvent, useEffect, useState } from 'react'
import styled from 'styled-components'
import ButtonGroup from './ButtonGroup'
import ActionButton from './ActionButton'
import { Move } from '../../assets/images/index'
import { useHistory } from 'react-router-dom'
import { routePath } from '../../route'
import Checkbox from './Checkbox'
import { useAppDispatch } from '../../store/hooks'
import { hideDialog, showDialog } from '../../controllers/slices/dialogSlice'
import { useParamSiteSno } from '../../hooks/useParamSiteSno'
import { getInputPattern, isEmailPattern, patternType } from '../../utils/utils'
import LoginCardWrapper from './LoginCardWrapper'
import useForm from '../../hooks/useForm'
import { useLoginMutation, useLogoutMutation } from '../../controllers/services/api/auth'
import { hideLoading, showLoading } from '../../controllers/slices/loadingSlice'
import { isFetchBaseQueryError, ResultStatus } from '../../controllers/services/errorHandler'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { setAccount } from '../../controllers/slices/authSlice'
import { useGetMemberMutation } from '../../controllers/services/api/member'

type LoginTarget = 'user' | 'manager' | 'admin'
type LoginProps = {
  target: LoginTarget,
  title: string,
  subTitle: string,
  submitText: string,
  styleOptions?: {
    hasRememberBtn?: boolean,
    hasFindBtn?: boolean,
  },
  onCancel?: () => void,
  onSucceed: (user: User) => void,
  onFailed: (error: FetchBaseQueryError) => void,
}

const LoginContainer = ({
  target,
  title,
  subTitle,
  submitText,
  styleOptions = {
    hasRememberBtn: false,
    hasFindBtn: false,
  },
  onCancel,
  onSucceed,
  onFailed,
}: LoginProps) => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { hasRememberBtn, hasFindBtn } = styleOptions
  const { siteSno } = useParamSiteSno()
  const form = useForm(target)
  const [login, { data, error, isSuccess, isError, isLoading }] = useLoginMutation()
  const [logout, logoutState] = useLogoutMutation()
  const [getMember, getMemberState] = useGetMemberMutation()
  const [invalidError, setError] = useState<{
    id: string | null,
    password: string | null,
  }>({
    id: null,
    password: null,
  })

  useEffect(() => {
    isLoading ? dispatch(showLoading()) : dispatch(hideLoading())

    if (isSuccess && data) {
      getMember({ userId: data.userId })
      sessionStorage.setItem(
        "session_auth",
        JSON.stringify({
          time: data.accessTokenExpiration
        })
      )
    } else if (isError) {
      if (isFetchBaseQueryError(error)) {
        if (error.status === ResultStatus.BadRequest) {
          if(((error.data as Error).error.split(' ')[0] === "해당")){
            const userId = (error.data as Error).error.split(/'/)[1]
            dispatch(
              showDialog({
                title: 'notice',
                body: 'access_error'.localized(),
                cancel:{
                  text: 'cancel',
                  onClick: () =>  dispatch(hideDialog()),
                },
                confirm: {
                  text: 'confirm',
                  onClick: () => logout({ userId }),
                },
              })
            )
          } else {
            onFailed(error)
          }
        } else {
          onFailed(error)
        }
      } else {
        dispatch(
          showDialog({
            title: '로그인 실패',
            body: '로그인 실패',
            confirm: {
              text: 'confirm',
              onClick: () => dispatch(hideDialog()),
            },
          })
        )
      }
    }
  }, [isSuccess, isLoading, isError])

  useEffect(() => {
    logoutState.isLoading ? dispatch(showLoading()) : dispatch(hideLoading())

    if (logoutState.isSuccess) {
      dispatch(hideDialog())
    }

    if (logoutState.isError) {
      dispatch(
        showDialog({
          title: '로그아웃 실패',
          body: '로그아웃 실패',
          confirm: {
            text: 'confirm',
            onClick: () => dispatch(hideDialog()),
          },
        })
      )
    }
  }, [logoutState.isSuccess, logoutState.isLoading, logoutState.isError])

  useEffect(() => {
    getMemberState.isLoading ? dispatch(showLoading()) : dispatch(hideLoading())

    if (getMemberState.isSuccess) {
      onSucceed(getMemberState.data)
    }
  }, [getMemberState.isSuccess, getMemberState.isLoading, getMemberState.isError])

  const handleClickCancel = () => {
    if (onCancel) {
      onCancel()
    } else {
      history.goBack()
    }
  }

  const handleClickFind = () => {
    history.push(routePath.searchPass(siteSno), { replace: true })
  }

  const onPressEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur()
      handleClickSubmit()
    }
  }

  const isEmailID = (type: LoginTarget) => {
    return type === 'user'
  }

  const handleClickSubmit = () => {
    if (!isEmailID(target) && !form.id) {
      showEmptyInputDialog('input_id')
      return
    }

    if (isEmailID(target)) {
      if (!form.id) {
        showEmptyInputDialog('input_email')
        return
      }
      if (!isEmailPattern(form.id)) {
        showEmptyInputDialog('wrong_email')
        return
      }
    }

    if (!form.password) {
      showEmptyInputDialog('input_password')
      return
    }

    if (form.id === form.password) {
      setErrorMessage('password', 'ID와 비밀번호는 다르게 입력이 되어야 합니다.')
      return
    }

    if (Object.values(invalidError).every((x) => x === null)) {
      onSubmit()
    }
  }

  const onSubmit = () => {
    dispatch(setAccount({ id: form.id, pw: form.password }))
    switch (target) {
      case 'user':
        login({
          email: form.id,
          password: form.password,
          siteSno,
        })
        break
      case 'manager':
        login({
          username: form.id,
          password: form.password,
          siteSno,
        })
        break
      case 'admin':
        login({
          username: form.id,
          password: form.password,
        })
        break
    }
  }

  const showEmptyInputDialog = (notice: string) => {
    dispatch(showDialog({
      title: 'login',
      body: notice,
      confirm: {
        text: 'confirm',
        onClick: () => dispatch(hideDialog()),
      },
    }))
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, pattern?: patternType, maxLength?: number) => {
    if (form.onChangeInput) {
      form.onChangeInput(e)
    }
    const inputPattern = getInputPattern(pattern ?? 'any', maxLength)
    if (!inputPattern.value.test(e.target.value)) {
      setErrorMessage(e.target.name, inputPattern.message)
    } else {
      setErrorMessage(e.target.name, null)
    }
  }

  const setErrorMessage = (name: string, msg: string | null) => {
    setError({
      ...invalidError,
      [name]: msg,
    })
  }

  return (
    <LoginCardWrapper>
      <LoginTitle dangerouslySetInnerHTML={{ __html: title }}/>
      <LoginSubTitle>{subTitle}</LoginSubTitle>
      <LoginBody>
        <LoginForm>
          <InputContainer
            name='id'
            placeholder={isEmailID(target) ? 'input_email'.localized() : 'input_id'.localized()}
            value={form.id}
            onChange={(e) =>
              onChange(e,
                isEmailID(target) ? ['alpha', 'numeric', 'specialChar'] : ['alpha', 'numeric'],
                isEmailID(target) ? 50 :20
              )}
            errorMessage={invalidError.id}
          />
          <InputContainer
            name='password'
            placeholder={'input_password'.localized()}
            type='password'
            value={form.password}
            onChange={(e) => onChange(e, target === 'user' ? 'password' : ['alpha', 'numeric', 'specialChar'], 20)}
            onKeyUp={onPressEnter}
            errorMessage={invalidError.password}
          />
        </LoginForm>
        <LoginOptionsWrapper>
          { hasRememberBtn &&
            <RememberWrapper>
              <Checkbox checked={form.checkBox} onChangeCheckBox={form.onChangeCheckBox}/>
              <RememberText>{'save_id'.localized()}</RememberText>
            </RememberWrapper>
          }
          { hasFindBtn &&
            <FindWrapper onClick={handleClickFind}>
              {'find_password'.localized()}
              <img alt='pw_find' src={Move}/>
            </FindWrapper>
          }
        </LoginOptionsWrapper>
        <ButtonGroup padding='1.375rem 0 0' justifyContent='center'>
          <ActionButton onClick={handleClickCancel} value={'cancel'.localized()} padding='1rem' bgColor='white' width='100%'/>
          <ActionButton onClick={handleClickSubmit} value={submitText} padding='1rem' bgColor='white' width='100%'/>
        </ButtonGroup>
      </LoginBody>
    </LoginCardWrapper>
  )
}

type InputWrapperProps = {
  name: string,
  placeholder?: string,
  type?: HTMLInputTypeAttribute,
  value?: string,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void,
  errorMessage?: string | null,
}

const InputContainer = (props: InputWrapperProps) => {
  return (
    <InputWrapper>
      <LoginInput
        name={props.name}
        placeholder={props.placeholder}
        type={props.type}
        value={props.value}
        onChange={props.onChange}
        onKeyUp={props.onKeyUp}
      />
      {props.errorMessage && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
    </InputWrapper>
  )
}

const LoginTitle = styled.div`
  font-weight: 700;
  font-size: 1.875rem;
  padding-bottom: 1.25rem;
`

const LoginSubTitle = styled.div`
  font-size: 1rem;
  color: var(--gray700);
  padding-bottom: 3.5rem;
`

const LoginBody = styled.div`
  max-width: 25rem;
  width: 100%;
  
  display: flex;
  flex-direction: column;
`

const LoginForm = styled.form`
  width: 100%;
  
  display: flex;
  flex-direction: column;
`

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 1rem;
`

const ErrorMessage = styled.div`
  font-size: 0.75rem;
  position: absolute;
  bottom: 0;
  color: var(--warning);
`

const LoginInput = styled.input`
  width: inherit;
  min-width: 0;
  height: 3.125rem;
  box-sizing: border-box;
  font-size: 1rem;
  text-overflow: ellipsis;
  color: var(--gray700);

  padding: 0 0.875rem;
  border: 1px solid var(--gray300);
  border-radius: 4px;
  
  &::placeholder {
    color: var(--hint);
  }

  &:focus {
    outline: none;
    border: 1px solid var(--focus-border);
  }
`

const LoginOptionsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0 1.25rem;

  color: var(--gray800);
  
  &:empty {
    display: none;
  }
`

const RememberWrapper = styled.label`
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: var(--gray800);
  line-height: 1rem;
  cursor: pointer;
  
  input {
    width: 1rem;
    height: 1rem;
    margin: 0 0.625rem 0 0;
    cursor: pointer;
  }
`

const RememberText = styled.span`
  padding-left: 0.625rem;
  user-select: none;
`

const FindWrapper = styled.div`
  font-size: 0.938rem;
  color: #191919;
  cursor: pointer;

  img {
    padding: 0 0.375rem 0 0.625rem;    
  }
  
  &:only-child {
    margin-left: auto;
  }
`

export default React.memo(LoginContainer)

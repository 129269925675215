import React, { useEffect, useState } from 'react'
import { BackgroundContainer } from '../../../components/BackgroundContainer'
import { ContentsWrapper } from '../../../components/ContentsWrapper'
import BodyTitleContainer from '../../../components/BodyTitleContainer'
import { ContentsBox } from '../../../components/ContentsBox'
import styled from 'styled-components'
import ActionButton from '../../../components/ActionButton'
import { useHistory } from 'react-router-dom'
import { routePath } from '../../../../route'
import BoxContentsWrapper from '../../../components/BoxContentsWrapper'
import ButtonGroup from '../../../components/ButtonGroup'
import {
  useGetCurrentTermQuery,
  usePostTermMutation,
} from '../../../../controllers/services/api/term'
import { useAppDispatch } from '../../../../store/hooks'
import { hideLoading, showLoading } from '../../../../controllers/slices/loadingSlice'
import { hideDialog, showDialog } from '../../../../controllers/slices/dialogSlice'
import { SubmitHandler, useForm, UseFormRegisterReturn } from 'react-hook-form'
import { isFetchBaseQueryError } from '../../../../controllers/services/errorHandler'
import Dropdown from '../../../components/dropdown/Dropdown'
import DatePicker from '../../../components/datePicker/DatePicker'
import dayjs, { Dayjs } from 'dayjs'

interface TermForm {
  termsOfUse: string,
  termsOfUsePrivacy: string,
  disclaimer: string,
  termsOfConsent: string,
  termsOfManagerConsent: string,
  termsOfManagerPrivacyPolicy: string,
  termsOfPrivacyPolicy: string,
}

type TermsFormProps = {
  label: string,
  content?: string,
  register: UseFormRegisterReturn,
  effectiveDate?: Dayjs,
  onChangeDate?: (name: string, newDate: Dayjs) => void,
}

const TermValues = {
  'common': '광고 공통 약관',
  'forManager': '관리사무소 이용 약관',
  'forUser': '광고주 이용 약관',
} as const
type TermType = typeof TermValues[keyof typeof TermValues]

type TermDate = {
  termsOfUse: Dayjs,
  termsOfConsent: Dayjs,
  termsOfManagerConsent: Dayjs,
  termsOfManagerPrivacyPolicy: Dayjs,
  termsOfPrivacyPolicy: Dayjs,
}

const AdminTermsContainer = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const getNotice = useGetCurrentTermQuery()
  const [postNotice, postNoticeState] = usePostTermMutation()
  const { register, handleSubmit, setValue } = useForm<TermForm>()
  const [selectedTerm, setSelectedTerm] = useState<TermType>(TermValues.common)
  const [effectiveDate, setEffectiveDate] = useState<TermDate>({
    termsOfUse: dayjs(),
    termsOfConsent: dayjs(),
    termsOfManagerConsent: dayjs(),
    termsOfManagerPrivacyPolicy: dayjs(),
    termsOfPrivacyPolicy: dayjs(),
  })

  useEffect(() => {
    postNoticeState.isLoading ? dispatch(showLoading()) : dispatch(hideLoading())

    if (postNoticeState.isSuccess) {
      showDialogSuccess()
    }

    if (postNoticeState.isError) {
      if (isFetchBaseQueryError(postNoticeState.error)) {
        showNoticeError()
      }
    }
  }, [postNoticeState.isLoading, postNoticeState.isError, postNoticeState.isSuccess])

  useEffect(() => {
    getNotice.isLoading ? dispatch(showLoading()) : dispatch(hideLoading())

    if (getNotice.isSuccess) {
      setLoadedTermsValue()
    }

    if (getNotice.isError) {
      if (isFetchBaseQueryError(getNotice.error)) {
        setLoadedTermsValue('terms_no_data'.localized())
        showNoticeError()
      }
    }
  }, [getNotice.isLoading, getNotice.isSuccess, getNotice.isError])

  const setLoadedTermsValue = (defaultValue: string = '') => {
    setValue('termsOfUse', getNotice.data?.termsOfUse ?? defaultValue)
    setValue('disclaimer', getNotice.data?.disclaimer ?? defaultValue)
    setValue('termsOfPrivacyPolicy', getNotice.data?.termsOfPrivacyPolicy ?? defaultValue)
    setValue('termsOfConsent', getNotice.data?.termsOfConsent ?? defaultValue)
    setValue('termsOfManagerPrivacyPolicy', getNotice.data?.termsOfManagerPrivacyPolicy ?? defaultValue)
    setValue('termsOfManagerConsent', getNotice.data?.termsOfManagerConsent ?? defaultValue)
    setEffectiveDate({
      termsOfUse: dayjs.unix(Number(getNotice.data?.effectiveDate)),
      termsOfConsent: dayjs.unix(Number(getNotice.data?.termsOfConsentEffectiveDate)),
      termsOfManagerConsent: dayjs.unix(Number(getNotice.data?.termsOfManagerConsentEffectiveDate)),
      termsOfManagerPrivacyPolicy: dayjs.unix(Number(getNotice.data?.termsOfManagerPrivacyPolicyEffectiveDate)),
      termsOfPrivacyPolicy: dayjs.unix(Number(getNotice.data?.termsOfPrivacyPolicyEffectiveDate)),
    })
  }

  const handleClickCancel = () => {
    history.push(routePath.admin)
  }

  const handleClickSave: SubmitHandler<TermForm> = (fieldData) => {
    postNotice({
      ...fieldData,
      effectiveDate: effectiveDate.termsOfUse.toISOString(),
      termsOfConsentEffectiveDate: effectiveDate.termsOfConsent.toISOString(),
      termsOfPrivacyPolicyEffectiveDate: effectiveDate.termsOfPrivacyPolicy.toISOString(),
      termsOfManagerConsentEffectiveDate: effectiveDate.termsOfManagerConsent.toISOString(),
      termsOfManagerPrivacyPolicyEffectiveDate: effectiveDate.termsOfManagerPrivacyPolicy.toISOString(),
    })
      .then(() => {
        getNotice.refetch()
      })
  }

  const showDialogSuccess = () => {
    dispatch(
      showDialog({
        title: 'notice',
        body: 'admin_terms_add_msg',
        confirm: {
          text: 'confirm',
          onClick: () => dispatch(hideDialog())
        }
      })
    )
  }

  const showNoticeError = () => {
    dispatch(
      showDialog({
        title: 'Error',
        titleHighlight: true,
        body: 'admin_terms_add_failed',
        confirm: {
          text: 'confirm',
          onClick: () => dispatch(hideDialog())
        }
      })
    )
  }

  const onChangeTerm = (value: string) => {
    setSelectedTerm(value as TermType)
    setLoadedTermsValue()
  }

  const onChangeDate = (name: string, newDate: Dayjs) => {
    setEffectiveDate({
      ...effectiveDate,
      [name]: newDate,
    })
  }

  return (
    <BackgroundContainer>
      <ContentsWrapper>
        <BodyTitleContainer title={selectedTerm} />
        <Dropdown
          onClick={onChangeTerm}
          value={selectedTerm}
          list={Object.values(TermValues)}
        />
        { selectedTerm === TermValues.common && (
          <>
            <TermsForm
              label={'terms_object'.localized()}
              register={register('termsOfUse')}
              effectiveDate={effectiveDate.termsOfUse}
              onChangeDate={onChangeDate}
            />
            <TermsForm
              label={'admin_terms_disclaimer'.localized()}
              register={register('disclaimer')}
            />
          </>
        )}
        { selectedTerm === TermValues.forUser && (
          <>
            <TermsForm
              label={'admin_terms_privacy_policy'.localized()}
              register={register('termsOfPrivacyPolicy')}
              effectiveDate={effectiveDate.termsOfPrivacyPolicy}
              onChangeDate={onChangeDate}
            />
            <TermsForm
              label={'admin_terms_consent'.localized()}
              register={register('termsOfConsent')}
              effectiveDate={effectiveDate.termsOfConsent}
              onChangeDate={onChangeDate}
            />
          </>
        )}
        { selectedTerm === TermValues.forManager && (
          <>
            <TermsForm
              label={'admin_terms_privacy_policy'.localized()}
              register={register('termsOfManagerPrivacyPolicy')}
              effectiveDate={effectiveDate.termsOfManagerPrivacyPolicy}
              onChangeDate={onChangeDate}
            />
            <TermsForm
              label={'admin_terms_consent'.localized()}
              register={register('termsOfManagerConsent')}
              effectiveDate={effectiveDate.termsOfManagerConsent}
              onChangeDate={onChangeDate}
            />
          </>
        )}
        <ButtonGroup padding="1rem 0">
          <ActionButton
            onClick={handleClickCancel}
            value={'cancel'.localized()}
            bgColor="white"
          />
          <ActionButton
            onClick={handleSubmit(handleClickSave)}
            value={'register'.localized()}
            bgColor="white"
          />
        </ButtonGroup>
      </ContentsWrapper>
    </BackgroundContainer>
  )
}

const TermsForm = ({ label, content, register, effectiveDate, onChangeDate }: TermsFormProps) => {
  return (
    <ContentsBox padding='2rem' style={{ gap: '1.5rem' }}>
      <BoxContentsWrapper>
        <TermsLabel>
          {label}
        </TermsLabel>
        <ContentField {...register} placeholder={label.appendPostWord() + 'input_placeholder'.localized()} defaultValue={content}/>
      </BoxContentsWrapper>
      { (effectiveDate && onChangeDate) && (
        <BoxContentsWrapper>
          <TermsLabel>{'admin_terms_effective_date'.localized()}</TermsLabel>
          <DatePicker value={effectiveDate} onChangeDate={(newDate) => onChangeDate(register.name, newDate)}/>
        </BoxContentsWrapper>
      )}
    </ContentsBox>
  )
}

const TermsLabel = styled.div`
  padding: 0.5rem 0;
  width: 8.875rem;
  flex-shrink: 0;
  text-align: start;
  color: var(--gray900);
  font-weight: 700;
  font-size: 1rem;
  line-height: 100%;
  letter-spacing: -0.01em;
`

const ContentField = styled.textarea`
  flex-grow: 1;
  min-width: 0;
  min-height: 12.5rem;
  box-sizing: border-box;
  font-size: 1rem;
  color: var(--gray700);
  padding: 0.75rem 1.5rem;
  border: 1px solid var(--gray300);
  border-radius: 4px;
  resize: vertical;
`

export default AdminTermsContainer

import React from 'react'
import { Dialog as MuiDialog, StyledEngineProvider } from '@mui/material'
import styled from 'styled-components'
import dayjs from 'dayjs'
import ActionButton from './ActionButton'
import Checkbox from './Checkbox'

type NoticeProps = {
  changeLog?: {
    previousTermsOfUse?: string,
    newTermsOfUse?: string,
    effectiveDate?: string,
  },
  isShow: boolean,
  isChecked: boolean,
  onClose: () => void,
  onCheckBox: () => void,
}

const TermsNoticeDialog = ({
  changeLog = {
    previousTermsOfUse: '-',
    newTermsOfUse: '-',
    effectiveDate: '-',
  },
  isShow,
  isChecked,
  onClose,
  onCheckBox,
}: NoticeProps) => {

  const convertDateToStr = (timestamp?: string) => {
    return dayjs
      .unix(Number(timestamp))
      .format('YYYY년 MM월 DD일 hh시')
  }

  return (
    <StyledEngineProvider injectFirst>
      <DialogContainer open={isShow}>
        <DialogBodyWrapper>
          <Title>우리상가 서비스 이용약관 개정 안내</Title>
          <Body>
            안녕하세요. 우리상가 서비스 입니다.<br/>
            우리상가 서비스를 이용해 주시는 고객 여러분께 진심으로 감사 드립니다.<br/>
            <br/>
            ‘우리상가 서비스 이용약관’의 내용이 아래와 같이 변경되어 시행될 예정임을 안내 드립니다.<br/>
            본 약관 변경 안내 후 변경에 동의하지 않는 경우 계약을 해지할 수 있으며, 별도 계약해지 의사표시를 하지 않는 경우 변경에 동의한 것으로 봅니다.<br/>
          </Body>

          <EffectiveDate>
            1. 시행 일자 : {convertDateToStr(changeLog.effectiveDate)}
            <div id='notice'>* 상기일정은 내부사정에 따라 변경될 수 있습니다</div>
          </EffectiveDate>

          <Changes>
            2. 변경 내용
          </Changes>
          <ChangesTable>
            <colgroup>
              <col width="20%"/>
              <col width="40%"/>
              <col width="40%"/>
            </colgroup>
            <tr>
              <th>구분</th>
              <th>현행</th>
              <th>개정(안)</th>
            </tr>
            <tr>
              <td>서비스 이용약관 변경</td>
              <td>{changeLog.previousTermsOfUse}</td>
              <td>{changeLog.newTermsOfUse}</td>
            </tr>
            <tr>
              <td>개인정보보호책임자 변경</td>
              <td>LG전자㈜ 정보보호FD담당</td>
              <td>LG전자㈜ 정보보호 담당</td>
            </tr>
          </ChangesTable>

          <Body>
            관련하여 궁금한 점이나 의견이 있으신 경우, 우리상가 서비스 고객센터 (jk.baek@lge.com)로 접수해 주시면 신속하게 안내해 드리겠습니다.<br/>
            감사합니다.<br/>
          </Body>
        </DialogBodyWrapper>
        <BottomActions>
          <CheckBoxWrapper>
            <Checkbox checked={isChecked} onChangeCheckBox={onCheckBox}/>
            다시 보지 않기
          </CheckBoxWrapper>
          <ActionButton onClick={onClose} value={'확인'}/>
        </BottomActions>
      </DialogContainer>
    </StyledEngineProvider>
  )
}

export default TermsNoticeDialog

const DialogContainer = styled(MuiDialog)`
  color: var(--gray700);
  
  .MuiPaper-root {
    width: 100%;
    max-width: 100rem;
    padding: 28px 26px;
    align-items: center;
    box-sizing: border-box;

    font-family: inherit;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
  }
`

const DialogBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 1rem;
`

const Title = styled.div`
  border: var(--black) solid 1px;
  padding: 0.25rem;
  font-weight: bold;
`

const Body = styled.div`
  align-self: start;
`

const EffectiveDate = styled.div`
  align-self: start;
  font-weight: bold;
  padding-left: 1rem;
  
  #notice {
    font-weight: normal;
  }
`

const Changes = styled.div`
  align-self: start;
  font-weight: bold;
  padding-left: 1rem;
`

const ChangesTable = styled.table`
  width: 100%;
  border: var(--black) solid 1px;
  border-collapse: collapse;
  
  th {
    border: var(--black) solid 1px;
  }

  td {
    border: var(--black) solid 1px;
  }
`

const BottomActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  padding-top: 2rem;
  gap: 2rem;
`

const CheckBoxWrapper = styled.label`
  display: flex;
  align-items: center;
  font-size: 1rem;
  gap: 0.5rem;
  color: var(--gray800);
  line-height: 1rem;
  cursor: pointer;
  
  input {
    width: 1rem;
    height: 1rem;
    margin: 0 0.625rem 0 0;
    cursor: pointer;
  }
`

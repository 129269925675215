import React from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'

type AdStatusBadgeProps = {
  startDate: string | undefined,
  finishDate: string | undefined,
  isApproval: boolean
}

type processStatus = 'pending' | 'ready' | 'posting' | 'expired' | 'rejected'

export const getAdStatus = (startDate: string | undefined, finishDate: string | undefined, isApproval: boolean): processStatus => {
  const today = dayjs().unix()
  const start = Number(startDate)
  const end = Number(finishDate)

  if (start && end) {
    if (isApproval) {
      if (start > today) { // 대기중
        return 'ready'
      } else if (end < today) { // 기간만료
        return 'expired'
      } else {
        return 'posting' // 게시중
      }
    }

    if (start === end) { // 승인거절
      return 'rejected'
    }
  }

  return 'pending' // 요청중
}

const AdStatusBadge = ({ startDate, finishDate, isApproval }: AdStatusBadgeProps) => {
  switch (getAdStatus(startDate, finishDate, isApproval)) {
    case 'pending':
      return <AdStatus className="pending">{'pending'.localized()}</AdStatus>
    case 'ready':
      return <AdStatus className="ready">{'waiting'.localized()}</AdStatus>
    case 'posting':
      return <AdStatus className="posting">{'publishing'.localized()}</AdStatus>
    case 'expired':
      return <AdStatus className="expired">{'expired'.localized()}</AdStatus>
    case 'rejected':
      return <AdStatus className="rejected">{'rejected'.localized()} </AdStatus>
  }
}

const AdStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5.625rem;
  height: 2.25rem;
  border-radius: 2.5rem;
  margin: 0 auto;
  box-sizing: border-box;
  font-weight: 500;
  line-height: 100%;
  flex-shrink: 0;
  padding: 0.625rem;
  
  &.posting {
    color: var(--white);
    background: var(--bg-blue);
  }
  &.ready {
    color: #707681;
    background: var(--white);
    border: 1px solid #c9ced6;
  }
  &.pending {
    color: #5c7bab;
    background: var(--white);
    border: 1px solid var(--bg-blue);
  }
  &.expired {
    color: #767c85;
    background: #e5eaef;
  }
  &.rejected {
    color: #ff0000;
    background: #ffe5e5;
  }

  @media only screen and (max-width: 1023px) {
    width: 3rem;
    height: 1.125rem;
    font-size: 0.625rem;
    padding: 0.25rem 0.375rem;
  }
`

export default AdStatusBadge

import React from 'react'
import { ContentsBox } from '../../../components/ContentsBox'
import ContentsBoxTitle from '../../../components/ContentsBoxTitle'
import { Divider } from '../../../components/Divider'
import styled from 'styled-components'
import Account from './Account'
import ActionButton from '../../../components/ActionButton'
import { Authorities, AccessLevel } from '../../../../resource/constants'

type EachAuthContainerProps = {
  role: string,
  accounts: Array<User>,
  onChangeAuth: (id: string, value: AccessLevel) => void,
  handleOpenDialog: () => void
}

const AccountsContainer = ({ role, accounts, onChangeAuth, handleOpenDialog }: EachAuthContainerProps) => {

  return (
    <ContentsBox padding='1.5rem 2rem' style={{ position: 'relative' }}>
      <ContentsBoxTitle title={role}>
        { role === 'user' &&
            <ActionButton onClick={handleOpenDialog} value={'admin_auth_add'.localized()} margin='-0.25rem 0' padding='0.75rem 1.875rem' bgColor='white'/>
        }
      </ContentsBoxTitle>
      <Divider margin='1.5rem 0 1.25rem'/>
      <RowsBox>
        { accounts.length > 0 ?
          accounts.map((account, index) =>
            <Account
              key={account.username ?? '' + index}
              username={account.username ?? ''}
              email={account.email ?? ''}
              authority={Authorities[account.accessLevel]}
              onChangeAuth={onChangeAuth}
            />) :
          <div>{'admin_auth_empty'.localized()}</div>
        }
      </RowsBox>
    </ContentsBox>
  )
}

const RowsBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export default AccountsContainer

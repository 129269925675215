import React from 'react'
import { Checkbox, FormControlLabel } from '@mui/material'
import styled from 'styled-components'

type HeaderProps = {
  title: string,
  description?: string,
  isChecked: boolean,
  onChangeCheck: () => void,
  checkBoxLabel?: string
}

const TermHeader = ({ title, description, isChecked, onChangeCheck, checkBoxLabel }: HeaderProps) => {
  return (
    <TitleWrapper>
      <ItemTitleText>{title}</ItemTitleText>
      {
        description && <div className='description'>{description}</div>
      }
      <FormControlLabel
        control={<Checkbox
          checked={isChecked}
          onChange={onChangeCheck}
          sx={{
            '&.Mui-checked': { color: 'var(--lg-red)' },
            '&.MuiCheckbox-root': { padding: '0 0.5rem' },
          }}
          disableRipple />}
        label={checkBoxLabel}
        sx={{ '& > .MuiTypography-root': { fontSize: '1rem', color: 'var(--gray800)', lineHeight: '100%', fontFamily: 'Noto Sans KR' } }}
      />
    </TitleWrapper>
  )
}

const ItemTitleText = styled.div`
  font-weight: 700;
  font-size: 1.25rem;
  color: var(--gray900);
  letter-spacing: -0.0125rem;
`

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  
  & > .description {
    flex-grow: 1;
    font-size: 1rem;
    text-align: start;
    color: var(--gray700);
  }

  @media only screen and (max-width: 1023px) {
    flex-wrap: wrap;
  }
`

export default TermHeader

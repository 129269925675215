import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import { logger } from 'redux-logger'
import dialogSlice from "../controllers/slices/dialogSlice";
import adminAccountSlice from '../controllers/slices/adminAccountSlice'
import loadingSlice from '../controllers/slices/loadingSlice'
import {
  adApi,
  authApi, categoryApi,
  memberApi,
  siteApi
} from '../controllers/services'
import { globalErrorHandler } from '../controllers/services/errorHandler'
import authSlice from '../controllers/slices/authSlice'
import { fileApi } from '../controllers/services/api/file'
import { termApi } from '../controllers/services/api/term'

const store = configureStore({
  reducer: {
    adminAccount: adminAccountSlice,
    dialog: dialogSlice,
    loading: loadingSlice,
    auth: authSlice,
    [adApi.reducerPath]: adApi.reducer,
    [siteApi.reducerPath]: siteApi.reducer,
    [fileApi.reducerPath]: fileApi.reducer,
    [memberApi.reducerPath]: memberApi.reducer,
    [termApi.reducerPath]: termApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  })
    .concat(logger)
    .concat(adApi.middleware)
    .concat(siteApi.middleware)
    .concat(fileApi.middleware)
    .concat(memberApi.middleware)
    .concat(termApi.middleware)
    .concat(authApi.middleware)
    .concat(categoryApi.middleware)
    .concat(globalErrorHandler)
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

export default store

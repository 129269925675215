import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Checkbox, FormControlLabel } from '@mui/material'
import { UploadEmpty } from '../../../../../assets/images'
import styled from 'styled-components'
import ActionButton from '../../../../components/ActionButton'
import { useAppDispatch } from '../../../../../store/hooks'
import { hideDialog, showDialog } from '../../../../../controllers/slices/dialogSlice'

type ImageUploadProps = {
  imgPath: string | null,
  onChangeImage: (path: string | null, file?: File) => void,
  isValid: boolean
}

const S3_BASE_URL = process.env.REACT_APP_S3_BASE_URL!
const DEFAULT_IMG_PATH = S3_BASE_URL + process.env.REACT_APP_DEFAULT_BANNER_PATH!

const ImageUploadContainer = ({ imgPath, onChangeImage, isValid }: ImageUploadProps) => {
  const [isChecked, setIsChecked] = useState(false)
  const imgRef = useRef<HTMLInputElement | null>(null)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (imgPath === DEFAULT_IMG_PATH) {
      setIsChecked(true)
    } else {
      setIsChecked(false)
    }
  }, [imgPath])

  const handleChooseFile = () => {
    if (!imgRef.current) {
      return
    } else {
      imgRef.current.click()
    }
  }

  const onInputClick = (e: any) => {
    e.target.value = ''
  }

  const handleUploadImg = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0]
      if (!file.type.includes('jpg') && !file.type.includes('jpeg') && !file.type.includes('png')) {
        dispatch(
          showDialog({
            title: 'Error',
            titleHighlight: true,
            body: '이미지 파일 형식 확인 후 다시 시도해 주세요.',
            confirm: {
              text: 'confirm',
              onClick: () => dispatch(hideDialog())
            }
          })
        )
      } else {
        const canvas = document.createElement('canvas');
        const ctx : any= canvas.getContext('2d');
        const image = new Image();

        const reader = new FileReader();
        reader.onload = function(event) {
          image.onload = () => {
            canvas.width = 300;
            canvas.height = 160;
            ctx.drawImage(image, 0, 0, 300, 160);

            canvas.toBlob((blob: any) => {
              const resizedFile = new File([blob], file.name, { type: file.type.split('/')[1] });
              onChangeImage(URL.createObjectURL(resizedFile), resizedFile)
            }, file.type.split('/')[1]);
          };
          image.src = URL.createObjectURL(file)
        };
        reader.readAsDataURL(file);
      }
    }
  }

  const onChangeCheckBox = () => {
    if (isChecked) {
      onChangeImage(null)
    } else {
      onChangeImage(DEFAULT_IMG_PATH)
    }
    setIsChecked(!isChecked)
  }

  return (
    <ImageUploadWrapper>
      <ImageUpload src={imgPath ?? UploadEmpty} />
      <ActionButton
        onClick={handleChooseFile}
        value={'pick_file'.localized()}
        bgColor="white"
        width="100%"
      />
      {!isValid && <InvalidMessage>필수 입력 항목입니다.</InvalidMessage>}
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            onChange={onChangeCheckBox}
            sx={{ '&.Mui-checked': { color: 'var(--lg-red)' } }}
            size="small"
          />
        }
        label={'set_default_img'.localized()}
        sx={{
          '& > .MuiTypography-root': {
            fontSize: '0.875rem',
            fontFamily: 'Noto Sans KR'
          }
        }}
      />
      <input
        type="file"
        accept=".jpg, .jpeg, .png"
        style={{ display: 'none' }}
        ref={imgRef}
        onClick={onInputClick}
        onChange={handleUploadImg}
        multiple={false}
      />
      <UploadDsc>{'pick_file_detail'.localizedWithBR()}</UploadDsc>
    </ImageUploadWrapper>
  )
}

const ImageUploadWrapper = styled.div`
  max-width: 18.75rem;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ImageUpload = styled.img`
  width: 100%;
  max-width: 18.5rem;
  height: 10rem;
  margin-bottom: 0.75rem;
`

const UploadDsc = styled.div`
  text-align: start;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 140%;
  color: var(--gray700);
`

const InvalidMessage = styled.div`
  text-align: start;
  font-size: 0.75rem;
  color: var(--warning);
`

export default ImageUploadContainer

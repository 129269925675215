import { useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { setTimeToZero } from '../utils/utils'

type RangeType = {
  start: Dayjs,
  end: Dayjs,
}

const initData = {
  start: setTimeToZero(dayjs()),
  end: setTimeToZero(dayjs()),
}

const useRangeCalendar = (initValue: RangeType = initData) => {
  const [startDate, setStartDate] = useState<Dayjs>(initValue.start)
  const [endDate, setEndDate] = useState<Dayjs>(initValue.end)

  const onChangeStartDate = (value: Dayjs) => {
    setStartDate(setTimeToZero(value))
  }

  const onChangeEndDate = (value: Dayjs) => {
    setEndDate(setTimeToZero(value).hour(23).minute(59).second(59).millisecond(59))
  }

  return { startDate, endDate, onChangeStartDate, onChangeEndDate };
}

export default useRangeCalendar

import React, { useEffect, useState } from 'react'
import { BackgroundContainer } from '../../components/BackgroundContainer'
import { ContentsWrapper } from '../../components/ContentsWrapper'
import BodyTitleContainer from '../../components/BodyTitleContainer'
import { ContentsBox } from '../../components/ContentsBox'
import ContainerInfoBox from '../../components/ContainerInfoBox'
import ActionButton from '../../components/ActionButton'
import { useHistory } from 'react-router-dom'
import { routePath } from '../../../route'
import BoxContentsWrapper from '../../components/BoxContentsWrapper'
import ButtonGroup from '../../components/ButtonGroup'
import useRangeCalendar from '../../../hooks/useRangeCalendar'
import { usePostNewAdMutation } from '../../../controllers/services/api/ad'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { hideLoading, showLoading } from '../../../controllers/slices/loadingSlice'
import { SubmitHandler, useForm } from 'react-hook-form'
import { formOptions, validateDateRange } from '../../../utils/utils'
import { useResourceState } from '../../../hooks/useResourceState'
import { useGetAllSitesQuery } from '../../../controllers/services/api/site'
import ImageUploadContainer from '../main/register/ad/ImageUploadContainer'
import styled from 'styled-components'

interface FormInput {
  store: string,
  name: string,
  contact: string,
  img: string,
  addr: string,
  dsc: string,
  web: string
}

const AdminAdRegisterContainer = () => {
  console.log('AdminAdRegister')
  const history = useHistory()
  const [site, setSite] = useState<string>()
  const [sites, setSites] = useState<Site[]>([])
  const [isSiteValid, setIsSiteValid] = useState(true)
  const { startDate, endDate, onChangeStartDate, onChangeEndDate } = useRangeCalendar()
  const { register, handleSubmit, formState: { errors } } = useForm<FormInput>()
  const { postRsc } = useResourceState()

  const sitesQueryState = useGetAllSitesQuery()
  const [postNewAd, newAdState] = usePostNewAdMutation()
  const adminAccount = useAppSelector((state) => state.adminAccount)
  const dispatch = useAppDispatch()

  const [imgFile, setImgFile] = useState<File | null>(null)
  const [imagePath, setImagePath] = useState<string | null>(null)
  const [imgValid, setImgValid] = useState(true)

  useEffect(() => {
    newAdState.isLoading ? dispatch(showLoading()) : dispatch(hideLoading())

    if (sitesQueryState.isSuccess) {
      setSites(sitesQueryState.data)
    }

    if (newAdState.isSuccess) {
      history.push(routePath.admin)
    }
  }, [sitesQueryState.isSuccess, newAdState.isLoading, newAdState.isSuccess])

  useEffect(() => {
    setIsSiteValid(true)
  }, [site])

  const onChangeSite = (value: string) => {
    setSite(value)
  }

  const getSiteSno = (sites: Site[], siteName: string) => {
    const currentSite = sites.find((site) => site.name === siteName)
    return currentSite!.siteSno ?? 'unknown'
  }

  const handleClickCancel = () => {
    history.push(routePath.admin)
  }

  const handleClickSave: SubmitHandler<FormInput> = async (fieldData) => {
    if (!site) {
      setIsSiteValid(false)
      return
    }

    if (!imagePath) {
      setImgValid(false)
      return
    }

    if (!validateDateRange(startDate, endDate)) {
      return
    }

    const uploadedImage: string | null = await uploadImageFile(imgFile)

    if (!uploadedImage) {
      return
    }

    postNewAd({
      type: 'LG',
      approvalYn: 'Y',
      author: fieldData.name,
      bannerImageUrl: uploadedImage,
      category: 'LG 전용',
      description: fieldData.dsc,
      userId: adminAccount.userId,
      siteSno: getSiteSno(sites, site),
      storeAddress: fieldData.addr,
      storeContactNum: fieldData.contact,
      storeName: fieldData.store,
      storeWebsiteUrl: fieldData.web,
      startAt: startDate.toISOString(),
      finishAt: endDate.toISOString(),
    })
  }

  const getSiteNames = (sites: Site[]): string[] => {
    if (sites) {
      return sites.map((site) => site.name)
    } else {
      return ['']
    }
  }

  const uploadImageFile = async (file: File | null) => {
    if (!file) return imagePath
    return await postRsc(file)
      .then((response: any) => {
        if (response.data?.path) {
          return response.data.path
        }
      })
  }

  const onChangeImage = (path: string | null, file?: File) => {
    if (file) {
      setImgFile(file)
    } else {
      setImgFile(null)
    }

    setImagePath(path)
    setImgValid(true)
  }

  return (
    <BackgroundContainer>
      <ContentsWrapper gap="0">
        <BodyTitleContainer title={'admin_ad_apply'.localized()} />
        <AdWrapper padding="2rem">
          <BoxContentsWrapper>
            <ContainerInfoBox
              label={'site_name'.localized()}
              type="dropdown"
              dropdown={{
                value: site ?? 'picking_site'.localized(),
                options: getSiteNames(sites),
                onClick: onChangeSite
              }}
              maxColumns={2}
              errorMessage={isSiteValid ? undefined : 'required_field'.localized()}
              required={true}
            />
            <ContainerInfoBox
              label={'company_name'.localized()}
              type="edit"
              maxColumns={2}
              register={register('store', formOptions({ required: true }))}
              errorMessage={errors.store?.message}
              required={true}
            />
            <ContainerInfoBox
              label={'name'.localized()}
              type="edit"
              maxColumns={2}
              register={register('name', formOptions({ required: true }))}
              errorMessage={errors.name?.message}
              required={true}
            />
            <ContainerInfoBox
              label={'phone_no'.localized()}
              type="edit"
              maxColumns={2}
              register={register('contact', formOptions({ required: true }))}
              errorMessage={errors.contact?.message}
              required={true}
            />
            <ContainerInfoBox
              label={'address'.localized()}
              type="edit"
              register={register('addr', formOptions({ required: true, maxLength: 50 }))}
              errorMessage={errors.addr?.message}
              required={true}
            />
            <ContainerInfoBox
              label={'desc'.localized()}
              type="edit"
              register={register('dsc', formOptions({ required: true, maxLength: 50 }))}
              errorMessage={errors.dsc?.message}
              required={true}
            />
            <ContainerInfoBox
              label={'homepage'.localized()}
              type="edit"
              register={register('web', formOptions({ required: false }))}
              errorMessage={errors.web?.message}
            />
            <ContainerInfoBox
              label={'boarding_period'.localized()}
              type="rangePicker"
              maxColumns={2}
              required={true}
              rangePicker={{
                start: {
                  value: startDate,
                  onChangeDate: onChangeStartDate
                },
                end: {
                  value: endDate,
                  onChangeDate: onChangeEndDate
                }
              }}
            />
          </BoxContentsWrapper>
          <ImageUploadContainer imgPath={imagePath} onChangeImage={onChangeImage} isValid={imgValid}/>
        </AdWrapper>
        <ButtonGroup padding="2rem 0">
          <ActionButton
            onClick={handleClickCancel}
            value={'cancel'.localized()}
            bgColor="white"
          />
          <ActionButton
            onClick={handleSubmit(handleClickSave)}
            value={'button_save'.localized()}
            bgColor='white'
          />
        </ButtonGroup>
      </ContentsWrapper>
    </BackgroundContainer>
  )
}

const AdWrapper = styled(ContentsBox)`
  display: flex;
  flex-direction: row;
  gap: 3rem;
  
  @media only screen and (max-width: 1023px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`

export default AdminAdRegisterContainer
